import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "../ThemeContext";

const Chat = () => {
  const location = useLocation();
  const { theme } = useTheme();
  const [bubbleColor, setBubbleColor] = useState("#008FFB");
  const [iconColor, setIconColor] = useState("#FFFFFF");

  // Color variables based on theme
  const isDarkMode = theme === "dark";
  const botBackgroundColor = isDarkMode ? "#12082B" : "#FEFEFE";
  const botMessageColor = isDarkMode ? "#27184B" : "#D5EDFC";
  const userMessageColor = isDarkMode ? "#333333" : "#F1F1F1";
  const botTitleColor = isDarkMode ? "#27184B" : "#D5EDFC";
  const botTextColor = isDarkMode ? "#ffffff" : "#333333";
  const titleText = isDarkMode ? "#ffffff" : "#333333";
  const sentIcon = isDarkMode ? "#ffffff" : "#333333";

  useEffect(() => {
    if (location.pathname === "/") {
      setBubbleColor("#FFFFFF");
      setIconColor("#000000");
    } else {
      setBubbleColor("#28ACE2");
      setIconColor("#FFFFFF");
    }
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      const dfMessenger = document.querySelector("df-messenger");
      if (dfMessenger) {
        const shadowRoot = dfMessenger.shadowRoot;

        // Target the close button
        const closeButton = shadowRoot?.querySelector(
          "mwc-icon-button[icon='close']"
        );
        if (closeButton) {
          closeButton.style.color = "white";
          closeButton.style.filter = "invert(1)";
        }

        const collapseButton = shadowRoot?.querySelector(
          "mwc-icon-button[icon='minimize']"
        );
        if (collapseButton) {
          collapseButton.style.color = "white";
          collapseButton.style.filter = "invert(1)";
        }
        const sendButton = shadowRoot?.querySelector(
          'mwc-icon-button[icon="send"]'
        );
        if (sendButton) {
          sendButton.style.color = "white";
          sendButton.style.filter = "invert(1)";
        }

        const minimizeButton = shadowRoot?.querySelector(
          'mwc-icon-button[icon="minimize"]'
        );
        if (minimizeButton) {
          minimizeButton.style.color = "white";
          minimizeButton.style.filter = "invert(1)";
        }

        if (closeButton && collapseButton) {
          clearInterval(interval);
        }
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (document.querySelector('script[src*="df-messenger"]')) {
      return;
    }

    if (customElements.get("df-messenger")) {
      return;
    }

    const script = document.createElement("script");
    script.src =
      "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js";
    script.async = true;

    script.onerror = (error) => {
      console.error("Error loading Dialogflow Messenger script:", error);
    };

    document.body.appendChild(script);

    return () => {
      const dfScript = document.querySelector('script[src*="df-messenger"]');
      if (dfScript && script === dfScript) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      {/* Load Dialogflow Messenger Stylesheet */}
      <link
        rel="stylesheet"
        href="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css"
      />

      {/* Chatbot Widget */}
      <df-messenger
        project-id="vvdn-act"
        agent-id="d1c7156d-e5f8-4889-8f35-6844fcb36d38"
        language-code="en"
        max-query-length="-1"
        storage-option="none"
      >
        <df-messenger-chat-bubble chat-title="VVDN INC Virtual AI"></df-messenger-chat-bubble>
      </df-messenger>

      {/* Styling for chatbot */}
      <style>
        {`
          df-messenger {
            z-index: 999;
            position: fixed;
            --df-messenger-font-color: ${botTextColor};
            --df-messenger-font-family: 'Montserrat';
            --df-messenger-chat-background: #f3f6fc;
            --df-messenger-titlebar-background: ${botTitleColor};
            --df-messenger-message-user-background: #85bdef85;
            --df-messenger-message-bot-background: #28ace2;
            --df-messenger-message-bot-actor-background: #d00b49;
            --df-messenger-chat-bubble-size: 50px;
            --df-messenger-chat-bubble-icon-size: 28px;
            --df-messenger-chat-bubble-icon-color: ${iconColor} !important;
            --df-messenger-titlebar-font-color: ${titleText};
            --df-messenger-titlebar-icon-color: white;
            --df-messenger-chat-bubble-background: ${bubbleColor} !important;
            --df-messenger-chat-bubble-close-icon-size: 30px;
            --df-messenger-chat-window-height: 670px;
            --df-messenger-chat-window-width: 350px;
            --df-messenger-titlebar-padding: 	0 30px;
            --df-messenger-chat-background: ${botBackgroundColor}; 
            --df-messenger-message-user-background: ${userMessageColor}; 
            --df-messenger-message-bot-background: ${botMessageColor}; 
            --df-messenger-send-icon-color: #ffffff;
            --df-messenger-input-background: ${botTitleColor};
            --df-messenger-send-icon-color: ${sentIcon};
              bottom: 4px;
              right: 4px;
            /* Fixed CSS Variables */
            --bot-writing-image: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.8) 75%);
      
            /* Inline CSS for chat-close-icon */
            --chat-collapse-icon: white;
            @media (max-width:1300px){scale: 0.8}
          }
             df-messenger::part(chat-bubble) {
             background-color: ${bubbleColor} !important;
          }
           /* Chatbot Height Responsive */
             @media (min-width: 1920px) {
             df-messenger { --df-messenger-chat-window-height: 800px; }
    }
            @media (max-width: 1919px) and (min-width: 1600px) {
            df-messenger { --df-messenger-chat-window-height: 750px; }
    }
            @media (max-width: 1599px) and (min-width: 1440px) {
            df-messenger { --df-messenger-chat-window-height: 700px; }
    }
            @media (max-width: 1439px) and (min-width: 1366px) {
            df-messenger { --df-messenger-chat-window-height: 650px; }
    }
            @media (max-width: 1365px) and (min-width: 1280px) {
            df-messenger { --df-messenger-chat-window-height: 600px; }
    }
           @media (max-width: 1279px) {
           df-messenger { --df-messenger-chat-window-height: 550px; }
    }

         /* Optional scale for very small screens */
          @media (max-width: 1300px) {
          df-messenger { transform: scale(0.9); transform-origin: bottom right; }
    }
  `}
      </style>
    </>
  );
};

export default Chat;
