import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import errorIcon from "../../../../assets/images/error-2.png";

function VlanNetworkSetup() {
  const [vlanList, setVlanList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vlanId, setVlanId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [vlanPerPage] = useState(10);

  useEffect(() => {
    fetchVlanList();
  }, []);

  const fetchVlanList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/vlan/list");
      const sortedVlanList = response.sort((a, b) => {
        const dateA = new Date(a.lastUpdated || a.createdAt || 0);
        const dateB = new Date(b.lastUpdated || b.createdAt || 0);
        return dateB - dateA; // Descending order
      });
      setVlanList(sortedVlanList);
    } catch (error) {
      console.error("Failed to fetch VLAN list:", error);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastVlan = currentPage * vlanPerPage;
  const indexOfFirstVlan= indexOfLastVlan - vlanPerPage;
  const totalFilteredVlan = vlanList.length;
  const currentVlan= vlanList.slice(
    indexOfFirstVlan,
    indexOfLastVlan
  );

  const totalPages = Math.ceil(totalFilteredVlan / vlanPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleFirstPage = () =>{
    setCurrentPage(1);
  }
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };


  const deleteVlan = async (vlan_id) => {
    try {
      setLoading(true);
      const api = new allApi();
      await api.deleteRequest(`api/vlan/del/vlan/${vlan_id}`);
      await fetchVlanList();
    } catch (error) {
      console.error("Failed to delete VLAN", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="inc-card-table">
        <table className="m-head snmp-table">
          <thead>
            <tr>
              <th>VLAN Name</th>
              <th>VLAN ID</th>
              <th>Description</th>
              <th>Network Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentVlan.map((vlan) => (
              <tr key={vlan.vlan_id || "N/A"}>
                <td>{vlan.vlan_name || "N/A"}</td>
                <td>{vlan.vlan_id || "N/A"}</td>
                <td>{vlan.description !== "N/A" ? vlan.description : "-"}</td>
                <td>{vlan.network_type || "N/A"}</td>
                <td>
                  <div className="button-group">
                    <button className="icon-btn" title="Edit">
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="icon-btn"
                      title="Delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteSsid"
                      onClick={() => setVlanId(vlan.vlan_id)}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
      {totalFilteredVlan > 0 ?(
        <>
          <div className="pagination-info">
            
            <p>
              Showing {indexOfFirstVlan+ 1}
              {"-"}
              {Math.min(
                currentPage * vlanPerPage,
                totalFilteredVlan
              )} of {totalFilteredVlan} records
            </p>
            
          </div>
          <div className="pagination">
          <button
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              First Page
            </button>
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                className={currentPage === page ? "active" : ""}
              >
                {page}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              Last Page
            </button>
          </div>
          </>
          ):(
            <div>
            <p>No Records Available</p>
            </div>
          )}
        </div>
      <div
        className={`modal fade`}
        id="deleteSsid"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure to delete {vlanId} VLAN?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => deleteVlan(vlanId)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VlanNetworkSetup;
