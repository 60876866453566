import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import allApi from "../../../api/allApi";
import lanCable from "../../../assets/images/lan-cable.png";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import Chart from "react-apexcharts";
import noSignal from "../../../assets/images/no-signal.svg";
import dotSignal from "../../../assets/images/dot-signal.svg";
import oneSignal from "../../../assets/images/one-signal.svg";
import twoSignal from "../../../assets/images/two-signal.svg";
import fullSignal from "../../../assets/images/full-signal.svg";
import Chat from "../common/chat";

function HTTPSDevice() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parameters, setParameters] = useState([]);
  const location = useLocation();
  const [deviceTopology,setDeviceTopology] = useState("https");
  const initialDeviceData = location.state?.deviceInfo || {};
  const initialGatewayId = location.state?.gateway_id || null;
  const [alarms, setAlarms] = useState([]);
  const [counts, setCounts] = useState({});
  const [gatewayDetails, setGatewayDetails] = useState(null);
  const gatewayId = location?.state?.gateway_id;
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const [deviceId, setDeviceId] = useState(initialDeviceData.deviceId || null);
  const [protocolType, setProtocolType] = useState(
    initialDeviceData.protocolType || null
  );
  const [managedByGateway, setManagedByGateway] = useState(
    location.state?.managed_by_gateway || null
  );
  const [connectedClients, setConnectedClients] = useState([]);
  const [channelUtilizationDuration, setChannelUtilizationDuration] =
    useState("24h");
  const [selectedInterval, setSelectedInterval] = useState("1 Week");
  const [vlanInUse, setVlanInUse] = useState();
  const [clinetsBand, setClientsBand] = useState();
  const [deviceType, setDeviceType] = useState(
    initialDeviceData.deviceType || "N/A"
  );
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [ChannelSeries, setChannelSeries] = useState([]);
  const navigate = useNavigate();
  const topologyType = deviceData.topologyType;
  const [portData, setPortData] = useState({
    serialNumber: "",
    numOfPorts: 0,
    numOfActivePortsUsingPower: 0,
    numOfActivePortsNotUsingPower: 0,
    numOfErrorPorts: 0,
    numOfDisabledPorts: 0,
    numOfFreePorts: 0,
  });

  useEffect(() => {
    if (location.state?.protocolType) {
      handleDeviceInfo();
    }
  }, [location?.state?.protocolType]);

  useEffect(() => {
    if (deviceId && protocolType) {
      fetchAlarms(protocolType);
      fetchVlanInUse(deviceId);
      fetchClientsBandInfo(deviceId);
    }
  }, [deviceId, protocolType]);

  useEffect(() => {
    const centerImage = document.querySelector(".apexcharts-datalabels-group");
    if (centerImage) {
    }
  }, [loading]);

  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);

  const handleDeviceInfo = async () => {
    try {
      const api = new allApi();
      const payload = {
        protocolType: location.state.protocolType,
        deviceName: location.state.deviceName,
      };
      const response = await api.getRequest(`api/device`, payload);
      if (response) {
        setDeviceData(response);
        setDeviceId(response.deviceId);
        setProtocolType(response.protocolType);
        setDeviceType(response.deviceType);
      } else {
      }
    } catch (error) {}
  };

  const fetchAlarms = async (protocolType) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/alarms/device`, {
        deviceId,
        protocolType,
      });
      if (response) {
        setAlarms(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const counts = {
      Critical: alarms.filter((alarm) => alarm.severityLevel === "CRITICAL")
        .length,
      Major: alarms.filter((alarm) => alarm.severityLevel === "MAJOR").length,
      Minor: alarms.filter((alarm) => alarm.severityLevel === "MINOR").length,
      Info: alarms.filter((alarm) => alarm.severityLevel === "INFO").length,
    };
    setCounts(counts);
  }, [alarms]);

  const fetchAlarmsbySeverity = async (severityLevelText) => {
    try {
      setLoading(true);
      const severityMapping = {
        Critical: 1,
        Major: 2,
        Minor: 3,
        Info: 4,
      };

      const formattedSeverityLevelText =
        severityLevelText.charAt(0).toUpperCase() +
        severityLevelText.slice(1).toLowerCase();

      const severityLevel = severityMapping[formattedSeverityLevelText];
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms?deviceId=${deviceId}&protocolType=${deviceData.deviceType}&severity_level=${severityLevel}`
      );
      if (response) {
        const severityAlerts = response;
        navigate("/alarms", { state: { severityAlerts } });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleViewAll = async (severityLevel) => {
    await fetchAlarmsbySeverity(severityLevel);
  };

  useEffect(() => {
    if (location.state && location.state.protocolType) {
      handleDeviceInfo();
    }
  }, []);

  useEffect(() => {
    clientListData();
  }, []);

  useEffect(() => {
    if (location.state && location.state.managed_by_gateway !== null) {
      setManagedByGateway(location.state.managed_by_gateway);
    }
  }, [location?.state?.managed_by_gateway]);

  const troubleshootDevice = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/http/device/${deviceId}`);
      if (response) {
        const deviceTroubleshoot = response;
        navigate(`/troubleshoot`, { state: { deviceTroubleshoot } });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleTopologyButton = () =>{
    navigate(`/topology`,{state: {deviceTopology}}
      );
  };


  const getChannelUtilizationData = async (channelUtilizationDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/channelUtilOfDevice?deviceId=${deviceId}&duration=${channelUtilizationDuration}&networkId=6001`
      );

      if (response.status === "OK" && response.response) {
        const data = response.response[0]?.data || [];
        const serialNumber =
          response.response[0]?.serialNumber || "Unknown Device";
        const categories = [];
        const totalUtilizationSeries = [];

        // Prepare the data for the graph
        data.forEach((entry, index) => {
          if (channelUtilizationDuration === "30d" && index % 3 !== 0) {
            return; // Skip entries that are not every 3rd day
          }
          categories.push(entry.interval); // x-axis labels (dates)
          totalUtilizationSeries.push(entry.totalUtilization); // y-axis values (utilization)
        });

        // Check if there's data to display
        if (data.length === 0) {
          setChartData({
            categories: ["No data available"], // Placeholder x-axis
            series: [
              {
                name: serialNumber, // Show serial number
                data: [], // Empty data
              },
            ],
          });
        } else {
          setChartData({
            categories: categories,
            series: [
              {
                name: serialNumber, // Include serial number in the legend
                data: totalUtilizationSeries,
              },
            ],
          });
        }
      } else {
        // Handle no response case
        setChartData({
          categories: ["No data available"],
          series: [
            {
              name: "No Data", // Default legend name
              data: [], // Empty series
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error fetching channel utilization data:", error);
      setChartData({
        categories: ["Error fetching data"],
        series: [
          {
            name: "Error", // Default error legend name
            data: [], // Empty series
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };


  const [chartData, setChartData] = useState({
    categories: [],
    series: [
      {
        name: "Total Channel Utilization",
        data: [],
      },
    ],
  });

  const handleChannelUtilizationDurationChange = (
    channelUtilizationDuration
  ) => {
    setChannelUtilizationDuration(channelUtilizationDuration);
  };
  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const api = new allApi();
        const response = await api.getRequest(
          `api/wired/dashboard/portUtilization/${deviceId}`
        );
        if (response) {
          setPortData(response);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const clientListData = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const connectedPromise = api.getRequest(
        `api/client/connected/${deviceId}`
      );
      const [connectedResponse] = await Promise.all([connectedPromise]);

      if (Array.isArray(connectedResponse)) {
        setConnectedClients(connectedResponse);
      } else {
        setConnectedClients([]);
      }
    } catch (error) {
      setConnectedClients([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [gatewayDetails]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  const fetchVlanInUse = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/vlan/usedVlan?deviceId=${deviceId}`
      );
      if (response && response.vlanId) {
        setVlanInUse(response.vlanId);
      } else {
        setVlanInUse(null);
      }
    } catch (error) {
      setVlanInUse(null);
    }
  };

  const fetchClientsBandInfo = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/clientsInfo?deviceId=${deviceId}`
      );
      if (response) {
        setClientsBand(response);
      } else {
        setClientsBand(null);
      }
    } catch (error) {
      setClientsBand(null);
    }
  };

  function formatLastSeen(lastSeenTimestamp) {
    if (!lastSeenTimestamp) return "N/A";
    const date = new Date(lastSeenTimestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const ChannelOptions = {
    chart: {
      id: "channel-utilization-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: ChannelCategories,
      labels: {
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      min: 0,
      title: {
        text: "Total Utilization (%)",
      },
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ["#00aaff"],
    zoom: {
      enabled: false,
    },
  };

  const getDonutChartData = () => {
    return [
      {
        name: "Active Using Power",
        value: portData.numOfActivePortsUsingPower,
      },
      {
        name: "Active Not Using Power",
        value: portData.numOfActivePortsNotUsingPower,
      },
      {
        name: "Error Ports",
        value: portData.numOfErrorPorts,
      },
      {
        name: "Disabled Ports",
        value: portData.numOfDisabledPorts,
      },
      {
        name: "Free Ports",
        value: portData.numOfFreePorts,
      },
    ];
  };

  return (
    <div>
      <div className="device-info-wrap mb-4">
        <div className="inc-card info-card-4">
          <div className="inc-card-title">
            <h3>{deviceData?.deviceName}</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Troubleshoot"
                onClick={() => {
                  troubleshootDevice(deviceId);
                }}
              >
                <i className="troubleshoot icon" aria-hidden="true"></i>
              </button>
              <button
                className="icon-btn"
                title="Topology"
                onClick={() => {
                  handleTopologyButton("https")
                }}
              >
                <i className="domain icon" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="device-info">
              <div>
                <strong>Serial Number</strong>
                <span>{deviceData?.serialNumber}</span>
              </div>
              <div>
                <strong>Device Type</strong>
                <span>{deviceData?.deviceType || "N/A"}</span>
              </div>
              <div>
                <strong>Device Model</strong>
                <span>{deviceData?.model || "N/A"}</span>
              </div>
              <div>
                <strong>Firmware Version</strong>
                <span>{deviceData?.firmwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>IP Address</strong>
                <span>{deviceData?.ipAddress || "N/A"}</span>
              </div>
              <div>
                <strong>MAC Address</strong>
                <span>{deviceData?.macAddress || "N/A"}</span>
              </div>
              <div>
                <strong>UP Time</strong>
                <span>{deviceData?.deviceUptime || "N/A"}</span>
              </div>
              <div>
                <strong>Health</strong>
                <span
                  style={{
                    color:
                      deviceData.health === "Online"
                        ? "green"
                        : deviceData.health === "Awaiting"
                        ? "orange"
                        : "red",
                  }}
                >
                  {deviceData.health === "Online"
                    ? "Online"
                    : deviceData.health === "Awaiting"
                    ? "Awaiting"
                    : "Offline"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*Channel Utilization for AP */}
        {deviceType === "AP" && (
          <div className="inc-card info-card-8">
            <div className="inc-card-title">
              <h3>Channel Utilization</h3>
              <span
                className="dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {channelUtilizationDuration === "24h"
                    ? "Last 24 Hrs"
                    : channelUtilizationDuration}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleChannelUtilizationDurationChange("8h")
                      }
                    >
                      Last 8 Hrs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleChannelUtilizationDurationChange("24h")
                      }
                    >
                      Last 24 Hrs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleChannelUtilizationDurationChange("7d")
                      }
                    >
                      Last 7 Days
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleChannelUtilizationDurationChange("30d")
                      }
                    >
                      Last 30 Days
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : chartData.series[0].data.length === 0 ? (
                <div className="no-data-box">
                  <div className="no-data-text">No Data Available</div>
                </div>
              ) : (
                <Chart
                  options={{
                    chart: {
                      toolbar: { show: false },
                      zoom: { enabled: false },
                    },
                    xaxis: {
                      categories: chartData.categories, // x-axis data
                    },
                    yaxis: {
                      title: { text: "Total Utilization (%)" },
                    },
                    stroke: { width: 1 },
                    legend: {
                      position: "bottom",
                      horizontalAlign: "center",
                      showForSingleSeries: true, // Show serial number even for single series
                    },
                  }}
                  series={chartData.series} // Utilization data
                  type="line"
                  height={350}
                />
              )}
            </div>
          </div>
        )}

        {/* Port Graph for Switch */}
        {(deviceType === "SW" || deviceType === "Switch") && (
          <div className="inc-card info-card-8">
            <div className="inc-card-title">
              <h3>Port Utilization</h3>
              <span className="card-count">
                Total Port ({portData?.numOfPorts}){" "}
              </span>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : !portData || portData.numOfPorts === 0 ? (
                <div className="no-data-available">
                  <h4>No Data Available</h4>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-6 position-relative">
                    <img src={lanCable} alt="" className="port-cable" />
                    <Chart
                      options={{
                        labels: getDonutChartData().map((data) => data.name),
                        colors: [
                          "#33A02C",
                          "#1F78B4",
                          "#FB9A99",
                          "#8884d8",
                          "#FFAD01",
                        ],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "70%",
                            },
                          },
                        },
                        legend: false,
                      }}
                      series={getDonutChartData().map((data) => data.value)}
                      type="donut"
                      width="90%"
                      height="275px"
                    />
                  </div>
                  <div className="col-lg-6 port-legend">
                    <ul>
                      <li className="ap">
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        Active Using Power
                      </li>
                      <li className="anp">
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        Active Not Using Power
                      </li>
                      <li className="ep">
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        Error Ports
                      </li>
                      <li className="dp">
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        Disabled Ports
                      </li>
                      <li className="fp">
                        <i className="fa fa-circle" aria-hidden="true"></i> Free
                        Ports
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row mb-4">
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon pie">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Critical</p>
                  <div className="task-count">
                    <h2>{counts?.Critical}</h2>
                    <Link onClick={() => handleViewAll("CRITICAL")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon fire">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Major</p>
                  <div className="task-count">
                    <h2>{counts?.Major}</h2>
                    <Link onClick={() => handleViewAll("MAJOR")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon flag">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Minor</p>
                  <div className="task-count">
                    <h2>{counts?.Minor}</h2>
                    <Link onClick={() => handleViewAll("MINOR")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon line">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Info</p>
                  <div className="task-count">
                    <h2>{counts?.Info}</h2>
                    <Link onClick={() => handleViewAll("INFO")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>All Parameters</h3>
            </div>
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceData.temperature || "N/A"}</strong>
                      <span>Temperature</span>
                    </div>
                    <div>
                      <strong>{deviceData.txPower || "N/A"}</strong>
                      <span>TX Bytes</span>
                    </div>
                    <div>
                      <strong>{deviceData.txPower || "N/A"}</strong>
                      <span>RX Bytes</span>
                    </div>
                    <div>
                      <strong>{deviceData.createdAt || "N/A"}</strong>
                      <span>Device Added</span>
                    </div>
                    <div>
                      <strong>{deviceData.deviceUptime}</strong>
                      <span>Up Time</span>
                    </div>
                    <div>
                      <strong>{formatLastSeen(deviceData.lastSeen)}</strong>
                      <span>Last Seen</span>
                    </div>

                    {/*for AP*/}
                    {deviceType === "AP" && (
                      <>
                        <div>
                          <strong>{connectedClients?.length || "0"}</strong>
                          <span>Clients</span>
                        </div>
                        <div>
                          <strong>
                            {Math.floor((connectedClients?.length || 0) / 2)}
                          </strong>
                          <span>2.4 GHz Clients</span>
                        </div>
                        <div>
                          <strong>
                            {Math.ceil((connectedClients?.length || 0) / 2)}
                          </strong>
                          <span>5 GHz Clients</span>
                        </div>
                      </>
                    )}

                    {/*for SWITCH*/}
                    {(deviceType === "SW" || deviceType === "Switch") && (
                      <div>
                        <strong>
                          {vlanInUse !== null ? vlanInUse : "N/A"}
                        </strong>
                        <span>VLAN In Use</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Clients ({connectedClients?.length})</h3>
              <div className="inc-card-button">
                <button
                  className="icon-btn"
                  title="Clients Details"
                  onClick={() => {
                    navigate("/client");
                  }}
                >
                  <i className="fa fa-external-link" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              {managedByGateway !== null ? (
                <div className="inc-card-table">
                  <table className="m-head snmp-table">
                    <thead>
                      <tr>
                        <th>Clients</th>
                        <th>SSID</th>
                        <th>OS</th>
                        <th>MAC Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {connectedClients.map((client, index) => (
                        <tr key={index}>
                          <td>{client.deviceName || "N/A"}</td>
                          <td>{client.ssid || "N/A"}</td>
                          <td>{client.platform || "N/A"}</td>
                          <td>{client.macAddress || "N/A"}</td>
                          <td>{client.ipAddress || "N/A"}</td>
                          <td className="rssi-icon">
                              {/* {client.rssi || "N/A"} */}
                              { 
                                (client.rssi>=80) ? <img src={fullSignal}/> : 
                                (client.rssi>=60) ? <img src={twoSignal}/> : 
                                (client.rssi>=40) ? <img src={oneSignal}/> : 
                                (client.rssi>=20) ? <img src={dotSignal}/> : 
                                <img src={noSignal}/>
                              }
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Client Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Chat/>
    </div>
  );
}

export default HTTPSDevice;
