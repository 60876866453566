import React from "react";
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ClientExpandation() {
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [clientsDuration, setClientsDuration] = useState("24h");
  const [duration, setDuration] = useState("24h");
  const [latestTimestamp, setLatestTimestamp] = useState(null);

  useEffect(() => {
    getClientDetail(clientsDuration);
  }, [clientsDuration]);

  useEffect(() => {
    if (series.length > 0) {
      const latestTimestamps = series
        .flatMap((item) => item.data.map((point) => point.x))
        .filter(Boolean);

      setLatestTimestamp(
        latestTimestamps.length ? Math.max(...latestTimestamps) : undefined
      );
    }
  }, [series]); // Dependency on series

  const getClientDetail = async (clientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalClients?commandType=5&duration=${clientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.response; // Adjust this based on actual response structure
        const seriesData = {};

        // Aggregation logic for 7d or 30d
        const isAggregatedByDay = clientsDuration === '7d' || clientsDuration === '30d';

        data.forEach((item) => {
          const serialNo = item.serialNumber;

          item.data.forEach((client) => {
            let timestamp = new Date(client.interval1).getTime();
            const totalClients = client.totalClients || 0;

            // If aggregating by day, round timestamp to the start of the day (00:00)
            if (isAggregatedByDay) {
              const date = new Date(timestamp);
              date.setHours(0, 0, 0, 0);
              timestamp = date.getTime();
            }

            // Initialize series data for this serial number if not present
            if (!seriesData[serialNo]) {
              seriesData[serialNo] = { name: serialNo, data: [] };
            }

            // Push aggregated data or individual points
            const existingPoint = seriesData[serialNo].data.find(point => point.x === timestamp);
            if (existingPoint) {
              existingPoint.y += totalClients; // Aggregate client count for the same day
            } else {
              seriesData[serialNo].data.push({ x: timestamp, y: totalClients });
            }
          });
        });

        const series = Object.values(seriesData);
        setSeries(series); // Make sure this updates your chart's series
        const categories = series[0]?.data.map(point => point.x); // Example to extract categories
        setCategories(categories);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client detail data:", error);
    } finally {
      setLoading(false);
    }
  };


  const clientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime", // X-axis will represent date and time
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          // Format based on the duration
          const options = clientsDuration === '7d' || clientsDuration === '30d'
            ? { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' }
            : { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };

          return date.toLocaleString('en-IN', options); // Format date based on duration
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6', // Color for the axis border
      },
      axisTicks: {
        show: false,
        color: '#d6d6d6', // Color for the axis ticks
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);
          const options = clientsDuration === '7d' || clientsDuration === '30d'
            ? { day: '2-digit', month: 'short' }
            : { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };

          return date.toLocaleString('en-IN', options); // Format tooltip date based on duration
        },
      },
    },
    yaxis: {
      min: 0, // Minimum value for y-axis
      labels: {
        formatter: (val) => (val !== undefined ? val.toFixed(0) : "N/A"), // Display integers or "N/A"
      },
      title: {
        text: "Number of Clients", // Y-axis title
      },
    },
    stroke: {
      width: 1, // Increased width for better visibility
    },
    dataLabels: {
      enabled: false, // Disable data labels for cleaner look
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      }, // Display legend for each series
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    tooltip: {
      shared: true, // Share tooltip across series for better context
      intersect: false, // Allows tooltips to be shown for all series at the point
    },
    grid: {
      borderColor: '#e7e7e7', // Color of grid lines
    },
  };


  const handleClientsDurationChange = (newDuration) => {
    if (clientsDuration !== newDuration) {
      setClientsDuration(newDuration);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Clients</h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {clientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : clientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              {/* <input className="list-search" type="text" /> */}
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className="row">
              <div className="col-lg-8">
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {series.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={clientsOptions}
                        series={series}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="round-border">
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>No of Clients</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series &&
                          series.length > 0 &&
                          series.flatMap((seriesItem) => {
                            // Create a map to aggregate client counts by date
                            const dateToClientCountMap = seriesItem.data.reduce(
                              (acc, dataItem) => {
                                const dateStr = new Date(
                                  dataItem.x
                                ).toLocaleDateString(); // Format date as needed
                                if (!acc[dateStr]) {
                                  acc[dateStr] = 0;
                                }
                                acc[dateStr] += dataItem.y; // Aggregate client counts
                                return acc;
                              },
                              {}
                            );

                            // Map to table rows
                            return Object.entries(dateToClientCountMap).map(
                              ([date, count], index) => (
                                <tr key={`${seriesItem.name}-${index}`}>
                                  <td>{date}</td> {/* Date */}
                                  <td>{count}</td>{" "}
                                  {/* Total number of clients */}
                                </tr>
                              )
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientExpandation;
