import React, { useEffect, useState } from "react";
import lanPort from "../../../assets/images/lan-port.png";
import noSignal from "../../../assets/images/no-signal.svg";
import dotSignal from "../../../assets/images/dot-signal.svg";
import oneSignal from "../../../assets/images/one-signal.svg";
import twoSignal from "../../../assets/images/two-signal.svg";
import fullSignal from "../../../assets/images/full-signal.svg";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import Chat from "../common/chat";

function Client() {
  const [wiredClients, setWiredClients] = useState([]);
  const [wirelessClients, setWirelessClients] = useState([]);
  const [disconnectedClients, setDisconnectedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [refreshing, setRefreshing] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    deviceName: "",
    connDeviceName: "",
    connDeviceModel: "",
    ssid: "",
    port: "",
    platform: "",
    macAddress: "",
    ipAddress: "",
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [connectedPage, setConnectedPage] = useState(1);
  const [disconnectedPage, setDisconnectedPage] = useState(1);
  const devicesPerPage = 10;
  const [allPage, setAllPage] = useState(1);
  const [wiredPage, setWiredPage] = useState(1);
  const [wirelessPage, setWirelessPage] = useState(1);
  const license = localStorage.getItem("license");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setAllPage(1);
  }, [searchCriteria, filter]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const connectedPromise = api.getRequest("api/client/connected");
      const disconnectedPromise = api.getRequest("api/client/disconnected");
      const [connectedResponse, disconnectedResponse] = await Promise.all([
        connectedPromise,
        disconnectedPromise,
      ]);
      const { wiredClients, wirelessClients } = connectedResponse;
      setWiredClients(wiredClients);
      setWirelessClients(wirelessClients);
      setDisconnectedClients(disconnectedResponse);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const getFilteredClients = () => {
    let clients = [];
    switch (filter) {
      case "wired":
        clients = wiredClients;
        break;
      case "wireless":
        clients = wirelessClients;
        break;
      case "all":
      default:
        clients = [...wiredClients, ...wirelessClients];
        break;
    }

    const filteredClients = clients.filter((client) =>
      Object.keys(searchCriteria).every((key) =>
        searchCriteria[key]
          ? (client[key]?.toString() || "")
              .toLowerCase()
              .includes(searchCriteria[key].toLowerCase())
          : true
      )
    );

    console.log("Filtered Clients:", filteredClients);

    // Apply search filter
    return clients.filter((client) =>
      Object.keys(searchCriteria).every((key) =>
        searchCriteria[key]
          ? (client[key]?.toString() || "")
              .toLowerCase()
              .includes(searchCriteria[key].toLowerCase())
          : true
      )
    );
  };

  const totalFilteredDevices = getFilteredClients().length;

  const getFilteredDisconnectedClients = () => {
    return disconnectedClients.filter((client) =>
      Object.keys(searchCriteria).every((key) =>
        searchCriteria[key]
          ? (client[key]?.toString()?.toLowerCase() || "").includes(
              searchCriteria[key].toLowerCase()
            )
          : true
      )
    );
  };

  const totalFilteredDisconnectedDevices =
    getFilteredDisconnectedClients().length;

  const getPaginatedClients = () => {
    const filteredClients = getFilteredClients();
    const start = (allPage - 1) * devicesPerPage;
    const end = start + devicesPerPage;
    return filteredClients.slice(start, end);
  };

  const totalPagesForCurrentFilter = () => {
    const totalClients =
      filter === "wired"
        ? wiredClients.length
        : filter === "wireless"
        ? wirelessClients.length
        : [...wiredClients, ...wirelessClients].length;

    return Math.ceil(totalClients / devicesPerPage);
  };

  const getPaginatedDisconnectedClients = () => {
    const filteredDisconnectedClients = getFilteredDisconnectedClients();
    const start = (disconnectedPage - 1) * devicesPerPage;
    const end = start + devicesPerPage;
    return filteredDisconnectedClients.slice(start, end);
  };

  const totalPages = (totalFilteredDevices) =>
    Math.ceil(totalFilteredDevices / devicesPerPage);
  const totalPagesDisconnected = (totalFilteredDisconnectedDevices) =>
    Math.ceil(totalFilteredDisconnectedDevices / devicesPerPage);
  const indexOfFirstDevice = (currentPage) =>
    (currentPage - 1) * devicesPerPage;

  const handleFirstPage = () => {
    setDisconnectedPage(1);
  };

  const handlePrevPage = (type) => {
    if (type === "connected") {
      if (filter === "wired") {
        setWiredPage((prev) => Math.max(prev - 1, 1));
      } else if (filter === "wireless") {
        setWirelessPage((prev) => Math.max(prev - 1, 1));
      } else {
        setAllPage((prev) => Math.max(prev - 1, 1));
      }
    } else if (type === "disconnected") {
      setDisconnectedPage((prev) => Math.max(prev - 1, 1));
    }
  };

  const handleNextPage = (type) => {
    if (type === "connected") {
      if (filter === "wired") {
        setWiredPage((prev) =>
          Math.min(prev + 1, totalPagesForCurrentFilter())
        );
      } else if (filter === "wireless") {
        setWirelessPage((prev) =>
          Math.min(prev + 1, totalPagesForCurrentFilter())
        );
      } else {
        setAllPage((prev) => Math.min(prev + 1, totalPagesForCurrentFilter()));
      }
    } else if (type === "disconnected") {
      setDisconnectedPage((prev) =>
        Math.min(
          prev + 1,
          totalPagesDisconnected(totalFilteredDisconnectedDevices)
        )
      );
    }
  };

  const handlePageClick = (page, type) => {
    if (type === "connected") {
      setConnectedPage(page);
    } else if (type === "disconnected") {
      setDisconnectedPage(page);
    }
  };

  const handleLastPage = (type) => {
    if (type === "connected") {
      setConnectedPage(totalPages(totalFilteredDevices));
    } else if (type === "disconnected") {
      setDisconnectedPage(
        totalPagesDisconnected(totalFilteredDisconnectedDevices)
      );
    }
  };

  const getPaginationInfo = () => {
    const totalClients = getFilteredClients().length;
    const start = (allPage - 1) * devicesPerPage + 1;
    const end = Math.min(start + devicesPerPage - 1, totalClients);
    return `Showing ${start} - ${end} of ${totalClients} records`;
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleSort = (header) => {
    const key = columnMapping[header];
    if (!key) return;
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const columnMapping = {
    "Client Name": "deviceName",
    Connection: "clientType",
    "Associated Device": "connDeviceName",
    "Device Model": "connDeviceModel",
    SSID: "ssid",
    Port: "port",
    OS: "platform",
    "MAC Address": "macAddress",
    "IP Address": "ipAddress",
    RSSI: "rssi",
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link um active"
                  id="connected-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#connected-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="connected-tab-pane"
                  aria-selected="true"
                >
                  Connected
                </button>
              </li>
              {license !== "Standard" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link rm"
                    id="disconnected-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#disconnected-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="disconnected-tab-pane"
                    aria-selected="false"
                  >
                    Disconnected
                  </button>
                </li>
              )}
            </ul>
            <div className="inc-card-button user-button">
              {/* <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Search">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button> */}
              <button className="icon-btn" title="Refresh" onClick={fetchData}>
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="connected-tab-pane"
                role="tabpanel"
                aria-labelledby="connected-tab"
                tabIndex="0"
              >
                <div className="tab-pagination">
                  <div className="client-con-type">
                    <span>Connection Type:</span>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "all"}
                        onChange={() => setFilter("all")}
                      />
                      ALL ({wiredClients.length + wirelessClients.length}){" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "wired"}
                        onChange={() => setFilter("wired")}
                      />
                      Wired ({wiredClients.length}){" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "wireless"}
                        onChange={() => setFilter("wireless")}
                      />
                      Wireless ({wirelessClients.length}){" "}
                    </label>
                  </div>
                  <div className="inc-card-table client-connection">
                    <table className="m-head">
                      <thead>
                        <tr>
                          {[
                            "Client Name",
                            "Connection",
                            "Associated Device",
                            "Device Model",
                            "SSID",
                            "Port",
                            "OS",
                            "MAC Address",
                            "IP Address",
                            "RSSI",
                          ].map((header, index) => (
                            <th key={index} onClick={() => handleSort(header)}>
                              {header}{" "}
                              {sortConfig.key === header &&
                                (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                          ))}
                        </tr>
                        <tr className="search-border">
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              value={searchCriteria.deviceName}
                              onChange={handleSearchChange}
                              placeholder="Search Device Name"
                            />
                          </td>
                          <td></td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceName"
                              value={searchCriteria.connDeviceName}
                              onChange={handleSearchChange}
                              placeholder="Search connected device name"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceModel"
                              value={searchCriteria.connDeviceModel}
                              onChange={handleSearchChange}
                              placeholder="Search Device Model"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ssid"
                              value={searchCriteria.ssid}
                              onChange={handleSearchChange}
                              placeholder="Search SSID"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="port"
                              value={searchCriteria.port}
                              onChange={handleSearchChange}
                              placeholder="Search Port"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="platform"
                              value={searchCriteria.platform}
                              onChange={handleSearchChange}
                              placeholder="Search OS"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="macAddress"
                              value={searchCriteria.macAddress}
                              onChange={handleSearchChange}
                              placeholder="Search MAC Address"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ipAddress"
                              value={searchCriteria.ipAddress}
                              onChange={handleSearchChange}
                              placeholder="Search IP address"
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="6">
                              <Loader />
                            </td>
                          </tr>
                        ) : getPaginatedClients().length > 0 ? (
                          getPaginatedClients().map((client) => (
                            <tr key={client.deviceName}>
                              <td>{client.deviceName}</td>
                              <td>
                                {client.clientType === "wired" ? (
                                  <img src={lanPort} />
                                ) : (
                                  <img
                                    style={{ height: "25px" }}
                                    src={fullSignal}
                                  />
                                )}
                              </td>
                              <td>{client.connDeviceName}</td>
                              <td>{client.connDeviceModel}</td>
                              <td>{client.ssid || "N/A"}</td>
                              <td>{client.port || "N/A"}</td>
                              <td>{client.platform || "N/A"}</td>
                              <td>{client.macAddress}</td>
                              <td>{client.ipAddress}</td>
                              <td className="rssi-icon">
                                {client.rssi >= 80 ? (
                                  <img src={fullSignal} />
                                ) : client.rssi >= 60 ? (
                                  <img src={twoSignal} />
                                ) : client.rssi >= 40 ? (
                                  <img src={oneSignal} />
                                ) : client.rssi >= 20 ? (
                                  <img src={dotSignal} />
                                ) : (
                                  <img src={noSignal} />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Results Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="pagination-container">
                    {totalFilteredDisconnectedDevices > 0 ? (
                      <>
                        <div className="pagination-info">
                          <p>{getPaginationInfo()}</p>
                        </div>

                        <div className="pagination">
                          <button
                            className="prev-btn"
                            onClick={handlePrevPage}
                            disabled={
                              (filter === "wired" && wiredPage === 1) ||
                              (filter === "wireless" && wirelessPage === 1) ||
                              (filter === "all" && allPage === 1)
                            }
                          >
                            Previous
                          </button>

                          {[...Array(totalPagesForCurrentFilter())].map(
                            (_, index) => (
                              <button
                                key={index}
                                className={`page-btn ${
                                  (filter === "wired" &&
                                    wiredPage === index + 1) ||
                                  (filter === "wireless" &&
                                    wirelessPage === index + 1) ||
                                  (filter === "all" && allPage === index + 1)
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  filter === "wired"
                                    ? setWiredPage(index + 1)
                                    : filter === "wireless"
                                    ? setWirelessPage(index + 1)
                                    : setAllPage(index + 1)
                                }
                              >
                                {index + 1}
                              </button>
                            )
                          )}

                          <button
                            className="next-btn"
                            onClick={handleNextPage}
                            disabled={
                              (filter === "wired" &&
                                wiredPage === totalPagesForCurrentFilter()) ||
                              (filter === "wireless" &&
                                wirelessPage ===
                                  totalPagesForCurrentFilter()) ||
                              (filter === "all" &&
                                allPage === totalPagesForCurrentFilter())
                            }
                          >
                            Next
                          </button>
                        </div>

                        {/* <div className="pagination">
                          <button
                            className="prev-btn"
                            onClick={() => handlePrevPage("disconnected")}
                            disabled={disconnectedPage === 1}
                          >
                            Previous
                          </button>
                          {[
                            ...Array(
                              totalPagesDisconnected(
                                totalFilteredDisconnectedDevices
                              )
                            ),
                          ].map((_, index) => (
                            <button
                              key={index}
                              className={`page-btn ${
                                disconnectedPage === index + 1 ? "active" : ""
                              }`}
                              onClick={() =>
                                handlePageClick(index + 1, "disconnected")
                              }
                            >
                              {index + 1}
                            </button>
                          ))}
                          <button
                            className="next-btn"
                            onClick={() => handleNextPage("disconnected")}
                            disabled={
                              disconnectedPage ===
                              totalPagesDisconnected(
                                totalFilteredDisconnectedDevices
                              )
                            }
                          >
                            Next
                          </button>
                          <button
                            className="last-btn"
                            onClick={() => handleLastPage("disconnected")}
                            disabled={
                              disconnectedPage ===
                              totalPagesDisconnected(
                                totalFilteredDisconnectedDevices
                              )
                            }
                          >
                            Last Page
                          </button>
                        </div> */}
                      </>
                    ) : (
                      <p>No Records Available</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="disconnected-tab-pane"
                role="tabpanel"
                aria-labelledby="disconnected-tab"
                tabIndex="0"
              >
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Connection</th>
                          <th>Associated Device</th>
                          <th>Device Model</th>
                          <th>SSID</th>
                          <th>Port</th>
                          <th>OS</th>
                          <th>MAC Address</th>
                          <th>IP Address</th>
                          <th>RSSI</th>
                        </tr>
                        <tr className="search-border">
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              value={searchCriteria.deviceName}
                              onChange={handleSearchChange}
                              placeholder="Search Device Name"
                            />
                          </td>
                          <td></td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceName"
                              value={searchCriteria.connDeviceName}
                              onChange={handleSearchChange}
                              placeholder="Search connected device name"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceModel"
                              value={searchCriteria.connDeviceModel}
                              onChange={handleSearchChange}
                              placeholder="Search Device Model"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ssid"
                              value={searchCriteria.ssid}
                              onChange={handleSearchChange}
                              placeholder="Search SSID"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="port"
                              value={searchCriteria.port}
                              onChange={handleSearchChange}
                              placeholder="Search Port"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="platform"
                              value={searchCriteria.platform}
                              onChange={handleSearchChange}
                              placeholder="Search OS"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="macAddress"
                              value={searchCriteria.macAddress}
                              onChange={handleSearchChange}
                              placeholder="Search MAC Address"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ipAddress"
                              value={searchCriteria.ipAddress}
                              onChange={handleSearchChange}
                              placeholder="Search IP address"
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="10">
                              <Loader />
                            </td>
                          </tr>
                        ) : getFilteredDisconnectedClients().length > 0 ? (
                          getFilteredDisconnectedClients().map((client) => (
                            <tr key={client.clientId}>
                              <td>{client.deviceName}</td>
                              <td>
                                {client.clientType === "wired" ? (
                                  <img src={lanPort} />
                                ) : (
                                  <img
                                    style={{ height: "25px" }}
                                    src={fullSignal}
                                  />
                                )}
                              </td>
                              <td>{client.connDeviceName || "N/A"}</td>
                              <td>{client.connDeviceModel || "N/A"}</td>
                              <td>{client.ssid || "N/A"}</td>
                              <td>{client.port || "N/A"}</td>
                              <td>{client.platform || "N/A"}</td>
                              <td>{client.macAddress || "N/A"}</td>
                              <td>{client.ipAddress || "N/A"}</td>
                              <td className="rssi-icon">
                                {client.rssi >= 80 ? (
                                  <img src={fullSignal} />
                                ) : client.rssi >= 60 ? (
                                  <img src={twoSignal} />
                                ) : client.rssi >= 40 ? (
                                  <img src={oneSignal} />
                                ) : client.rssi >= 20 ? (
                                  <img src={dotSignal} />
                                ) : (
                                  <img src={noSignal} />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Results Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="pagination-container">
                    {totalFilteredDisconnectedDevices > 0 ? (
                      <>
                        <div className="pagination-info">
                          <p>
                            Showing {indexOfFirstDevice(disconnectedPage) + 1} -{" "}
                            {Math.min(
                              disconnectedPage * devicesPerPage,
                              totalFilteredDisconnectedDevices
                            )} of {totalFilteredDisconnectedDevices} records
                          </p>
                        </div>

                        <div className="pagination">
                          <button
                            className="prev-btn"
                            onClick={() => handleFirstPage()}
                            disabled={disconnectedPage === 1}
                          >
                            First Page
                          </button>
                          <button
                            className="prev-btn"
                            onClick={() => handlePrevPage("disconnected")}
                            disabled={disconnectedPage === 1}
                          >
                            Previous
                          </button>
                          {[
                            ...Array(
                              totalPages(totalFilteredDisconnectedDevices)
                            ),
                          ].map((_, index) => (
                            <button
                              key={index}
                              className={`page-btn ${
                                disconnectedPage === index + 1 ? "active" : ""
                              }`}
                              onClick={() =>
                                handlePageClick(index + 1, "disconnected")
                              }
                            >
                              {index + 1}
                            </button>
                          ))}
                          <button
                            className="next-btn"
                            onClick={() => handleNextPage("disconnected")}
                            disabled={
                              disconnectedPage ===
                              totalPages(totalFilteredDisconnectedDevices)
                            }
                          >
                            Next
                          </button>
                          <button
                            className="last-btn"
                            onClick={() => handleLastPage("disconnected")}
                            disabled={
                              disconnectedPage ===
                              totalPages(totalFilteredDisconnectedDevices)
                            }
                          >
                            Last Page
                          </button>
                        </div>
                      </>
                    ) : (
                      <p>No Records Available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Chat />
      </div>
    </div>
  );
}

export default Client;
