import React from "react";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";

function WirelessDataExpandation() {
  const [wirelessDataSeries, setWirelessDataSeries] = useState([]);
  const [wirelessDataCategories, setWirelessDataCategories] = useState([]);
  const [wirelessDataDuration, setWirelessDataDuration] = useState("24h");
  const [loading, setLoading] = useState(true);
  const [networkId, setNetworkId] = useState("all");

  useEffect(() => {
    getWirelessDataConsumption(networkId, wirelessDataDuration);
  }, [networkId, wirelessDataDuration]);

  const getWirelessDataConsumption = async (networkId, wirelessDataDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/wirelessDataConsumption?commandType=5&duration=${wirelessDataDuration}&networkId=6001`
      );
  
      if (response.status === "OK") {
        const data = response.response; // Adjusted to match your data structure

        // Gather unique timestamps (for the x-axis categories)
        const allTimestamps = new Set();
        data.forEach((device) => {
          device.data.forEach((entry) => {
            allTimestamps.add(new Date(entry.interval1).getTime());
          });
        });
        const uniqueTimestamps = Array.from(allTimestamps).sort(
          (a, b) => a - b
        );

        // Prepare series data for each serial number
        const seriesData = data.map((device) => {
          const deviceData = uniqueTimestamps.map((timestamp) => {
            // Find the corresponding data entry for the current timestamp
            const entry = device.data.find(
              (e) => new Date(e.interval1).getTime() === timestamp
            );
            return {
              x: timestamp, // Use the timestamp for x-axis
              y: entry ? entry.totalUtilization : 0, // Use the total utilization or 0 if not found
            };
          });

          return {
            name: device.serialNumber, // Serial number as the legend
            data: deviceData, // Align series data with all timestamps
          };
        });

        // Set categories (x-axis timestamps) and series data for rendering
        setWirelessDataCategories(uniqueTimestamps); // Use timestamps as x-axis categories
        setWirelessDataSeries(seriesData); // Use the prepared series data
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching wireless data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleNetworkTypeChange = (type) => {
    setNetworkId(type);
  };
  const handleWirelessDataDurationChange = (wirelessDataDuration) => {
    setWirelessDataDuration(wirelessDataDuration);
  };

  const WirelessDataOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
          show: false,
      },
      zoom: {
          enabled: true, // Enables zooming on the chart
      },
  },
    xaxis: {
      type: "datetime", // Time-based x-axis
      categories: wirelessDataCategories, // Timestamps for time series
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          if (wirelessDataDuration === '7d' || wirelessDataDuration === '30d') {
            return date.toLocaleDateString('en-IN', { day: '2-digit', month: 'short' });
          } else {
            return date.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', hour12: false });
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisTicks:{
        show: false,
      },
    },
    yaxis: {
      min: 0, // Set minimum value to 0
      labels: {
        formatter: function (val) {
          return val.toFixed(2); // Show 2 decimal places
        },
      },
      title: {
        text: "Total Utilization",
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels on the chart
    },
    plotOptions: {
      bar: {
        horizontal: false, // Keep bars vertical (if using bar chart)
      },
    },
    stroke: {
      curve: "smooth", // Smoother lines (if line chart)
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy", // Format for time display in tooltip
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true, 
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      },// Display legend for each series
    },
  };
  

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Wireless Data Consumption</h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {networkId === "all" ? "All" : networkId}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="clientDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("2.4GHz")}
                      >
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("5GHz")}
                      >
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("6GHz")}
                      >
                        6 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("all")}
                      >
                        All
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {wirelessDataDuration === "24h"
                      ? "Last 24 Hrs"
                      : wirelessDataDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              {/* <input className="list-search" type="text" /> */}
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className="row">
              <div className="col-lg-8">
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {wirelessDataSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={WirelessDataOptions}
                        series={wirelessDataSeries}
                        type="bar"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="round-border">
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th> Total Data Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wirelessDataSeries &&
                          wirelessDataSeries.length > 0 &&
                          wirelessDataSeries.map((item, index) => {
                            const totalData = item.data.reduce(
                              (total, entry) => total + entry.y,
                              0
                            );
                            return (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{totalData}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WirelessDataExpandation;
