import React, { useState, useCallback, useEffect } from "react";
import ReportProfile from "./reportProfile";
import ReportPickWidgets from "./reportPickWidgets";
import ReportReview from "./reportReview";
import allApi from "../../../../api/allApi";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import errorIcon from "../../../../assets/images/error-2.png";

function ReportList() {
  const [currentStep, setCurrentStep] = useState(1);
  const [reportData, setReportData] = useState({
    reportName: "",
    description: "",
    emailRecipients: [],
    timePeriod: "",
    reportType: "Custom Report",
    executionPeriod: "Run Once",
    dataType: [],
    reportList: [],
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(Date.now());
  const [existingReportNames, setExistingReportNames] = useState([]);
  const [showReportFailurePopup,setShowReportFailurePopup] = useState(false);
  const [showReportFailPopup,setShowReportFailPopup] = useState(false);

  useEffect(() => {
    const fetchExistingReports = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest("api/report/list");
        if (response && Array.isArray(response.reports)) {
          setExistingReportNames(response.reports.map((report) => report.name));
        }
      } catch (error) {
        console.error("Error fetching existing reports:", error);
      }
    };

    fetchExistingReports();
  }, []);

  const handleStepUpdate = useCallback((stepData) => {
    setReportData((prevData) => ({
      ...prevData,
      ...stepData,
      dataType: stepData.dataType || prevData.dataType,
    }));
  }, []);

  const handleCancel = () => {
    // navigate("/allReports");
    // setCurrentStep(1);
    setError("");
    setReportData({
      reportName: "",
      description: "",
      emailRecipients: [],
      timePeriod: "",
      reportType: "Custom Report",
      executionPeriod: "Run Once",
      dataType: [],
    });

    // Reset Form Key to Re-render Form
    setFormKey(Date.now());
  };

  const handleStepClick = (step) => {
    // Prevent direct skipping to a future step
    if (step <= currentStep) {
      setCurrentStep(step);
    }
  };

  const isDuplicate = (name) => {
    console.log("Checking for duplicate:", name);
    console.log("Existing report names:", existingReportNames);

    return existingReportNames.some(
      (existingName) => existingName.toLowerCase() === name.trim().toLowerCase()
    );
  };

  const handleNext = () => {
    if (!reportData.reportName.trim()) {
      setError("Report name is required.");
      return;
    }
    if (
      reportData.emailRecipients.length === 1 &&
      reportData.emailRecipients[0] === ""
    ) {
      setError("Email is required.");
      return;
    } else {
      setError("");
    }
    if (
      currentStep === 2 &&
      (!reportData.dataType || reportData.dataType.length === 0)
    ) {
      setError("Please select at least one widget.");
      return;
    } else {
      setError("");
    }
    if (isDuplicate(reportData.reportName)) {
      alert("Use another report name, this report name already exists.");
      return;
    }

    if (existingReportNames.includes(reportData.reportName.trim())) {
      setError("Use another report name, this report name already exists.");
      return;
    }

    if (currentStep < 3) {
      setCurrentStep((prev) => prev + 1);
      setError("");
    } else {
      reportGenerate();
    }
  };

  const reportGenerate = async () => {
    try {
      const api = new allApi();
      const formattedDataType = [
        {
          widgets: reportData.dataType.map((widget, index) => ({
            widgetId: widget.widgetId,
            position: widget.position || index + 1,
          })),
        },
      ];
      const payload = {
        name: reportData.reportName,
        description: reportData.description,
        emailRecipients: reportData.emailRecipients,
        templateType: "2",
        timePeriod: reportData.timePeriod,
        executionPeriod: reportData.executionPeriod,
        reportType: reportData.reportType,
        dataType: formattedDataType,
      };
  
      const response = await api.postRequest(
        "api/report/generate",
        "",
        payload
      );
  
      if (response && response.response) {
        console.log("status", response);
        setShowSuccessModal(true);
        sessionStorage.removeItem("reportProfile");
  
        setReportData({
          reportName: "",
          description: "",
          emailRecipients: [],
          timePeriod: "",
          reportType: "Custom Report",
          executionPeriod: "Run Once",
          dataType: [],
          reportList: [],
        });
        setFormKey(Date.now());
      } else {
        setShowReportFailurePopup(true);
      }
    } catch (error) {
      console.error("Error generating the report:", error);
  
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred. Please try again.";
  
        if (error.response.status === 400 && errorMessage === "Report name already exists") {
          setShowReportFailurePopup(true);
        } else {
          setShowReportFailPopup(true);
        }
      } else {
        setShowReportFailPopup(true);
      }
  
      
    }
  };
  

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ReportProfile
            key={formKey}
            onUpdate={handleStepUpdate}
            reportData={reportData}
            existingReportNames={existingReportNames}
          />
        );
      case 2:
        return (
          <ReportPickWidgets
            onUpdate={(widgets) => handleStepUpdate({ dataType: widgets })}
          />
        );
      case 3:
        return <ReportReview reportData={reportData} />;
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (currentStep === 1) {
      navigate("/allReports");
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Reports</h3>
          </div>
          <div className="inc-card-body">
            <div className="inc-report">
              <div className="report-tab">
                {[1, 2, 3].map((step) => (
                  <div
                    className={`step ${
                      currentStep === step
                        ? "selected"
                        : currentStep > step
                        ? "completed"
                        : ""
                    }`}
                    key={step}
                    onClick={() => handleStepClick(step)}
                  >
                    <div
                      className="s-text"
                      style={{ cursor: "pointer", color: "purple" }}
                    >
                      <span>STEP {step}</span>
                      <strong>
                        {step === 1 && "Report Profile"}
                        {step === 2 && "Pick Widgets"}
                        {step === 3 && "Review"}
                      </strong>
                    </div>
                    <div className="s-dot">
                      <i
                        className={`fa ${
                          currentStep >= step ? "fa-check" : "fa-circle"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    {step < 3 && (
                      <i className="line">
                        <span></span>
                      </i>
                    )}
                  </div>
                ))}
              </div>

              <div className="report-section">
                <div className="report-container">{renderStep()}</div>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="report-button">
                  {currentStep === 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
                  {currentStep === 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  )}
                  {currentStep > 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-fill"
                    onClick={handleNext}
                  >
                    {currentStep === 3 ? "Submit" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {showReportFailurePopup && (
                    <div className="customModal confirm-modal">
                      <div className="modal-dialog modal-dialog-centered small">
                        <div className="modal-content inc-modal">
                          <div className="modal-body">
                            <img src={errorIcon} alt="error" />
                            <h3>Report name already exists.</h3>
                            <div className="modal-footer">
                              <button className="text-btn primary-btn" onClick={() =>{ 
                                setShowReportFailurePopup(false);
                                setCurrentStep(1);
                                }}>Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showReportFailPopup && (
                    <div className="customModal confirm-modal">
                      <div className="modal-dialog modal-dialog-centered small">
                        <div className="modal-content inc-modal">
                          <div className="modal-body">
                            <img src={errorIcon} alt="error" />
                            <h3>Report creation failed.</h3>
                            <div className="modal-footer">
                              <button className="text-btn primary-btn" onClick={() => setShowReportFailPopup(false)}>Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Report generated successfully</p>
        <Button
          onClick={() => {
            setShowSuccessModal(false);
            navigate("/allReports");
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default ReportList;
