import React, { useState, useEffect } from "react";

function ReportProfile({
  onUpdate,
  reportData = {},
  existingReportNames = [],
}) {
  const [reportName, setReportName] = useState(reportData.reportName || "");
  const [description, setDescription] = useState(reportData.description || "");
  const [emailRecipients, setEmailRecipients] = useState(
    Array.isArray(reportData.emailRecipients)
      ? reportData.emailRecipients.join(", ")
      : reportData.emailRecipients || ""
  );
  const [timePeriod, setTimePeriod] = useState(reportData.timePeriod || "30d");
  const [reportType, setReportType] = useState(
    reportData.reportType || "Custom Report"
  );
  const [executionPeriod, setExecutionPeriod] = useState(
    reportData.executionPeriod || "Run Once"
  );
  const [organizationLogo, setOrganizationLogo] = useState(
    reportData.organizationLogo || "Use VAR Logo"
  );
  const [emailError, setEmailError] = useState("");
  const [reportNameError, setReportNameError] = useState("");

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const allowedDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "email.com",
    "vvdn.com",
    "vvdntech.in",
  ];

  const validateEmails = (emails) => {
    const emailList = emails.split(",").map((email) => email.trim());

    for (let email of emailList) {
      if (!emailRegex.test(email)) {
        setEmailError(`"${email}" has an incorrect format.`);
        return false;
      }

      const domain = email.split("@")[1];
      if (!allowedDomains.includes(domain)) {
        setEmailError(`"${email}" is not from an allowed domain.`);
        return false;
      }
    }

    setEmailError("");
    return true;
  };

  const validateReportName = (name) => {
    const trimmedName = name.trim().toLowerCase();
    const nameExists = existingReportNames.some(
      (existingName) => existingName.toLowerCase() === trimmedName
    );

    if (nameExists) {
      setReportNameError("This report name already exists. Use another name.");
      return false;
    }
    setReportNameError("");
    return true;
  };

  useEffect(() => {
    if (validateReportName(reportName, existingReportNames)) {
      const data = {
        reportName,
        description,
        emailRecipients: emailRecipients
          .split(",")
          .map((email) => email.trim()),
        timePeriod,
        reportType,
        executionPeriod,
      };
      onUpdate(data);
    }
  }, [
    reportName,
    description,
    emailRecipients,
    timePeriod,
    reportType,
    executionPeriod,
    onUpdate,
    existingReportNames,
  ]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailRecipients(value);
    validateEmails(value);
  };

  const handleReportNameChange = (e) => {
    const value = e.target.value;
    setReportName(value);
    validateReportName(value);
  };

  return (
    <div>
      <h4>Report Profile</h4>
      <p>
        Provide report name, notes, and define the profile for report
        generation.
      </p>

      <div className="row mb-35">
        <div className="col-lg-4">
          <label className="form-label">Report Name*</label>
          <input
            type="text"
            className="form-control"
            value={reportName}
            onChange={handleReportNameChange}
            onBlur={() => validateReportName(reportName)}
          />
          {reportNameError && <p style={{ color: "red" }}>{reportNameError}</p>}
        </div>
        <div className="col-lg-4">
          <label className="form-label">Description</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      <h4 className="mb-3">Report Type</h4>
      <div className="report-radio mb-3">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype1"
            checked={reportType === "Custom Report"}
            onChange={() => setReportType("Custom Report")}
          />
          <label className="form-check-label" htmlFor="reporttype1">
            Custom Report
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype2"
            checked={reportType === "Troubleshooting Report"}
            onChange={() => setReportType("Troubleshooting Report")}
          />
          <label className="form-check-label" htmlFor="reporttype2">
            Troubleshooting Report
          </label>
        </div>
      </div>

      <h4>Execution Period</h4>
      <div className="report-radio mb-35">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime1"
            checked={executionPeriod === "Run Once"}
            onChange={() => setExecutionPeriod("Run Once")}
          />
          <label className="form-check-label" htmlFor="reporttime1">
            Run Once
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime2"
            checked={executionPeriod === "Scheduled"}
            onChange={() => setExecutionPeriod("Scheduled")}
          />
          <label className="form-check-label" htmlFor="reporttime2">
            Scheduled
          </label>
        </div>
      </div>

      <h4>Time Period</h4>
      <div className="row mb-35">
        <div className="col-lg-4">
          <select
            className="form-select"
            id="time"
            name="time"
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
          >
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last Month</option>
          </select>
        </div>
      </div>

      <h4>
        Email Address<span className="text-danger">*</span>
      </h4>
      <p>Please enter comma-separated email addresses for all recipients.</p>
      <div className="row mb-35">
        <div className="col-lg-7">
          <input
            type="text"
            className="form-control"
            value={emailRecipients}
            onChange={handleEmailChange}
          />
          {emailError && <small className="text-danger">{emailError}</small>}
        </div>
      </div>

      <h4 className="mb-2">Branding Logo</h4>
      <div className="report-radio mb-4">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo1"
            value="Use VAR Logo"
            checked={organizationLogo === "Use VAR Logo"}
            onChange={() => setOrganizationLogo("Use VAR Logo")}
          />
          <label className="form-check-label" htmlFor="logo1">
            Use VAR Logo
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo2"
            value="Use Organization Logo"
            checked={organizationLogo === "Use Organization Logo"}
            onChange={() => setOrganizationLogo("Use Organization Logo")}
          />
          <label className="form-check-label" htmlFor="logo2">
            Use Organization Logo
          </label>
        </div>
      </div>
    </div>
  );
}

export default ReportProfile;
