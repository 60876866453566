import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import errorIcon from "../../../assets/images/error-2.png";
import Chat from "../common/chat";

function SnmpDevice() {
  const [loading, setLoading] = useState(true);
  const [snmpTopology, setSnmpTopology] = useState("snmp");
  const [error, setError] = useState(null);
  const [parameters, setParameters] = useState([]);
  const location = useLocation();
  const initialDeviceData = location.state?.deviceInfo || {};
  const initialGatewayId = location.state?.gateway_id || null;
  const [alarms, setAlarms] = useState([]);
  const [counts, setCounts] = useState({});
  const [gatewayDetails, setGatewayDetails] = useState(null);
  const gatewayId = location?.state?.gateway_id;
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const navigate = useNavigate();
  const [deviceId, setDeviceId] = useState(initialDeviceData.deviceId || null);
  const [protocolType, setProtocolType] = useState(
    initialDeviceData.protocolType || null
  );
  const [managedByGateway, setManagedByGateway] = useState(
    location?.state?.managed_by_gateway || null
  );
  const [devices, setDevices] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [refreshing, setRefreshing] = useState(true);
  const [devicesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceLinks, setDeviceLinks] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteCandidates, setDeleteCandidates] = useState([]);
  const [searchQueries, setSearchQueries] = useState({
    link_name: "",
    link_status: "",
    device_count: "",
    link_uptime: "",
    created_at: "",
  });
  const [editIndex, setEditIndex] = useState(null);
  const [editedLinkName, setEditedLinkName] = useState("");

  useEffect(() => {
    if (deviceId && location.state?.protocolType) {
      fetchAlarms(location.state.protocolType);
    }
  }, [deviceId, location.state?.protocolType]);

  // useEffect(() => {
  //   if (location.state && location.state.deviceInfo) {
  //     setDeviceData(location.state.deviceInfo);  // Make sure this is updated when returning
  //     setDeviceId(location.state.deviceInfo.deviceId);
  //   }
  // }, [location.state]);  // Depend on location.state to ensure it updates when coming back

  useEffect(() => {
    if (deviceId) {
      fetctDeviceLink();
    }
  }, [deviceId]);

  useEffect(() => {
    if (initialDeviceData && Object.keys(initialDeviceData).length > 0) {
      handleDeviceInfo();
    }
  }, [initialDeviceData]);

  useEffect(() => {
    if (initialGatewayId) {
      fetchGatewayDetails(initialGatewayId);
    }
  }, [initialGatewayId]);

  useEffect(() => {
    if (managedByGateway !== null) {
      setManagedByGateway(location?.state?.managed_by_gateway);
    }
  }, [location.state?.managed_by_gateway]);

  const handleDeviceInfo = async () => {
    try {
      const api = new allApi();
      const payload = {
        protocolType: location.state.protocolType,
        deviceName: location.state.deviceName,
      };
      const response = await api.getRequest(`api/device`, payload);
      if (response) {
        setDeviceData(response);
        setDeviceId(response.deviceId);
      } else {
        console.error("Error fetching device data.");
      }
    } catch (error) {
      console.error("Error handling device:", error);
    }
  };

  const fetchAlarms = async (protocolType) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/alarms/device`, {
        deviceId,
        protocolType,
      });
      if (response) {
        setAlarms(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const counts = {
      Critical: alarms.filter(
        (alarm) => alarm.severityLevel.toUpperCase() === "CRITICAL"
      ).length,
      Major: alarms.filter(
        (alarm) => alarm.severityLevel.toUpperCase() === "MAJOR"
      ).length,
      Minor: alarms.filter(
        (alarm) => alarm.severityLevel.toUpperCase() === "MINOR"
      ).length,
      Info: alarms.filter(
        (alarm) => alarm.severityLevel.toUpperCase() === "INFO"
      ).length,
    };
    setCounts(counts);
  }, [alarms]);

  const fetchAlarmsbySeverity = async (severityLevelText) => {
    try {
      setLoading(true);
      const severityMapping = {
        Critical: 1,
        Major: 2,
        Minor: 3,
        Info: 4,
      };

      const formattedSeverityLevelText =
        severityLevelText.charAt(0).toUpperCase() +
        severityLevelText.slice(1).toLowerCase();

      console.log("Formatted severity level text:", formattedSeverityLevelText);

      const severityLevel = severityMapping[formattedSeverityLevelText];
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms?deviceId=${deviceId}&protocolType=${location.state.protocolType}&severity_level=${severityLevel}`
      );

      if (response) {
        const severityAlerts = response;
        navigate("/alarms", { state: { severityAlerts } });
        console.log("severityalerts", severityAlerts);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  const handleViewAll = async (severityLevel) => {
    await fetchAlarmsbySeverity(severityLevel);
  };

  useEffect(() => {}, [gatewayDetails]);

  useEffect(() => {
    if (location.state && location.state.protocolType) {
      console.log("Location State:", location.state);
      handleDeviceInfo();
    }
  }, []);

  useEffect(() => {
    if (gatewayId) {
      fetchGatewayDetails(gatewayId);
    }
  }, [gatewayId]);

  useEffect(() => {
    if (location.state && location?.state?.managed_by_gateway !== null) {
      setManagedByGateway(location?.state?.managed_by_gateway);
    }
  }, [location?.state?.managed_by_gateway]);

  const fetchGatewayDetails = async (gateway_id) => {
    try {
      setLoading(true);
      const api = new allApi();
      console.log("Fetching gateway details");
      const response = await api.getRequest(`api/gateway/${gateway_id}`);
      if (response) {
        setGatewayDetails(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching gateway details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {}, [gatewayDetails]);

  const fetctDeviceLink = async () => {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      if (deviceId) {
        const response = await api.getRequest(
          `api/snmp/links/device/${deviceId}`
        );
        if (response) {
          setDevices(response);
        } else {
          console.error("No devices found for this ID.");
        }
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handlePerticularLink = (link_id) => {
    navigate(`/link-details`, {
      state: { link_id },
    });
  };

  const handleDelete = async (link_id) => {
    try {
      const api = new allApi();
      const response = await api.deleteRequest(`api/snmp/links/${link_id}`);
      if (response) {
        fetctDeviceLink();
      }
    } catch (error) {
      console.error("Error deleting SNMP templates:", error);
    }
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prev) => !prev);
    const newIndividualCheckboxChecked = {};
    filteredDevices.forEach((device) => {
      newIndividualCheckboxChecked[device.link_id] = !selectAllChecked;
    });
    setIndividualCheckboxChecked(newIndividualCheckboxChecked);
  };

  const handleIndividualCheckboxChange = (link_id) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [link_id]: !prev[link_id],
    }));
  };

  const handleDeleteButtonClick = () => {
    const selectedLinks = Object.keys(individualCheckboxChecked).filter(
      (key) => individualCheckboxChecked[key]
    );
    if (selectedLinks.length > 0) {
      handleDelete(selectedLinks);
      setShowDeleteConfirmation(true);
    }
  };
  const confirmDelete = () => {
    handleDelete(deleteCandidates);
    setShowDeleteConfirmation(false);
    setDeleteCandidates([]);
  };

  const filteredDevices = devices.filter((device) => {
    const formattedDevice = {
      ...device,
      created_at: new Date(device.created_at).toLocaleString(),
      link_uptime: device.link_uptime.toString(),
    };

    return Object.keys(searchQueries).every((key) =>
      formattedDevice[key]
        ?.toString()
        .toLowerCase()
        .includes(searchQueries[key].toLowerCase())
    );
  });

  const handleSearchChange = (e, field) => {
    setSearchQueries({ ...searchQueries, [field]: e.target.value });
  };

  // const handleSearchBox = (header) => {
  //   console.log("Clicked on header:", header);
  // };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );
  const totalFilteredDevices = filteredDevices.length;
  const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const handleTopologyButton = () => {
    navigate(`/topology`, { state: { snmpTopology } });
  };

  const handleEditLinkName = (index, currentName) => {
    setEditIndex(index);
    setEditedLinkName(currentName || "");
  };

  const updateLinkName = async (linkId, newName) => {
    const api = new allApi();
    const payload = { link_name: newName };

    try {
      const response = await api.putRequest(
        `api/snmp/links/update/${linkId}`,
        payload
      );
      if (response) {
        await fetctDeviceLink();
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditedLinkName("");
  };

  const handleEditClick = (index, currentName) => {
    setEditIndex(index);
    setEditedLinkName(currentName);
  };

  const handleSaveLinkName = async (linkId) => {
    if (!editedLinkName.trim()) {
      return;
    }

    try {
      await updateLinkName(linkId, editedLinkName);
      setEditIndex(null);
      setEditedLinkName("");
    } catch (err) {
      console.error("Error while saving the link name:", err);
    }
  };

  return (
    <div>
      <div className="device-info-wrap mb-4">
        <div className="inc-card info-card-4">
          <div className="inc-card-title">
            <h3>{deviceData?.deviceName}</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Topology"
                onClick={() => {
                  handleTopologyButton("snmp");
                }}
              >
                <i className="domain icon" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="device-info">
              <div>
                <strong>Serial Number</strong>
                <span>{deviceData.serialNumber}</span>
              </div>
              <div>
                <strong>Protocol Type</strong>
                <span>{deviceData.protocolType || "SNMP"}</span>
              </div>
              <div>
                <strong>Product Class</strong>
                <span>{deviceData.productClass || "N/A"}</span>
              </div>
              <div>
                <strong>Software Version</strong>
                <span>{deviceData.softwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>Hardware Version</strong>
                <span>{deviceData.hardwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>IP Address</strong>
                <span>{deviceData.ipv4 || "N/A"}</span>
              </div>
              <div>
                <strong>MAC Address</strong>
                <span>{deviceData.macAddress || "N/A"}</span>
              </div>
              <div>
                <strong>Last Inform</strong>
                <span>{deviceData.lastInform || "N/A"}</span>
              </div>
              <div>
                <strong>Health</strong>
                <span
                  style={{
                    color:
                      deviceData.health === "Online"
                        ? "green"
                        : deviceData.health === "Awaiting"
                        ? "orange"
                        : "red",
                  }}
                >
                  {deviceData.health === "Online"
                    ? "Online"
                    : deviceData.health === "Awaiting"
                    ? "Awaiting"
                    : "Offline"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="inc-card info-card-8">
            <div className="inc-card-title">
              <h3>Device Link</h3>
              <div className="inc-card-button">
                <button
                  className="icon-btn"
                  title="Refresh"
                  onClick={() => fetctDeviceLink()}
                >
                  {refreshing ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  )}
                </button>
                <button
                  className="icon-btn"
                  title="Delete"
                  disabled={totalFilteredDevices === 0}
                  onClick={handleDeleteButtonClick}
                  data-bs-toggle="modal"
                  data-bs-target="#deleteConfirmationModal"
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>Link Name</th>
                      <th>No. Of Device</th>
                      <th>Link Uptime</th>
                      <th>Created Time</th>
                      <th>Link Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devices && devices.length > 0 ? (
                      devices.map((device, index) => (
                        <tr key={device.link_id}>
                          <td>
                            {editIndex === index ? (
                              <div className="double-click-box">
                                <input
                                  type="text"
                                  value={editedLinkName}
                                  onChange={(e) =>
                                    setEditedLinkName(e.target.value)
                                  }
                                  autoFocus
                                />
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  onClick={handleCancelEdit}
                                ></i>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleSaveLinkName(device.link_id)
                                  }
                                ></i>
                              </div>
                            ) : (
                              <span
                                className="device-info0 underline"
                                onClick={() =>
                                  handlePerticularLink(device.link_id)
                                }
                                onDoubleClick={() =>
                                  handleEditClick(
                                    index,
                                    device.link_name || "N/A"
                                  )
                                }
                              >
                                {device.link_name || "N/A"}
                              </span>
                            )}
                          </td>
                          <td>{device.device_count || "N/A"}</td>
                          <td>{device.link_uptime || "N/A"}</td>
                          <td>{device.created_at || "N/A"}</td>
                          <td>
                            <span
                              className={
                                "d-status " +
                                (device?.link_status === 1
                                  ? "offline"
                                  : "online")
                              }
                            >
                              {device?.link_status === 1
                                ? "Inactive"
                                : "Active"}
                            </span>
                          </td>
                          <td>
                            <div className="button-group">
                              <button
                                title="Delete"
                                data-bs-toggle="modal"
                                data-bs-target="#noLinkeSelectedPopup"
                                onClick={() => {
                                  setDeleteCandidates([device.link_id]);
                                  setShowDeleteConfirmation(true);
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" style={{ textAlign: "center" }}>
                          No Links found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination-container">
              {totalFilteredDevices > 0 ? (
                <>
                  <div className="pagination-info">
                    <p>
                      Showing{" "}
                      {Math.min(
                        currentPage * devicesPerPage,
                        totalFilteredDevices
                      )}{" "}
                      of {totalFilteredDevices} records
                    </p>
                  </div>
                  <div className="pagination">
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                    >
                      Last Page
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <p>No Records Available</p>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`modal fade ${showDeleteConfirmation ? "show" : ""}`}
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: showDeleteConfirmation ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content inc-modal">
              <div className="modal-body success-msg text-center">
                <img src={errorIcon} alt="error" />
                <p>Are you sure you want to delete?</p>
                <div className="d-flex justify-content-center">
                  <button
                    className="text-btn primary-btn mx-2"
                    data-bs-dismiss="modal"
                    onClick={confirmDelete}
                  >
                    Yes
                  </button>
                  <button
                    className="text-btn mx-2"
                    data-bs-dismiss="modal"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {managedByGateway !== null ? (
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Edge</h3>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head snmp-table">
                    <thead>
                      <tr>
                        <th>Gateway Name</th>
                        <th>Organisation Name</th>
                        <th>Site Name</th>
                        <th>Publisher Topic</th>
                        <th>Subscriber Topic</th>
                        <th>Username</th>
                        <th>Agent Mail ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{gatewayDetails?.gateway_name}</td>
                        <td>{gatewayDetails?.organisation_name}</td>
                        <td>{gatewayDetails?.site_name}</td>
                        <td>{gatewayDetails?.publisher_topic}</td>
                        <td>{gatewayDetails?.subscriber_topic}</td>
                        <td>{gatewayDetails?.username}</td>
                        <td>{gatewayDetails?.agent_mail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>No Edge Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mb-4">
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon pie">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Critical</p>
                  <div className="task-count">
                    <h2>{counts?.Critical}</h2>
                    <Link onClick={() => handleViewAll("CRITICAL")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon fire">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Major</p>
                  <div className="task-count">
                    <h2>{counts?.Major}</h2>
                    <Link onClick={() => handleViewAll("MAJOR")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon flag">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Minor</p>
                  <div className="task-count">
                    <h2>{counts?.Minor}</h2>
                    <Link onClick={() => handleViewAll("Minor")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon line">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Info</p>
                  <div className="task-count">
                    <h2>{counts?.Info}</h2>
                    <Link onClick={() => handleViewAll("INFO")}>
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>All Parameters</h3>
              <div className="inc-card-button">
                <button className="icon-btn">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceData.managed_by_gateway || "N/A"}</strong>
                      <span>Managed By Gateway</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_mode || "N/A"}</strong>
                      <span>Device Mode</span>
                    </div>
                    <div>
                      <strong>{deviceData.temperature || "N/A"}</strong>
                      <span>Temperature</span>
                    </div>
                    <div>
                      <strong>{deviceData.port || "N/A"}</strong>
                      <span>Port</span>
                    </div>
                    <div>
                      <strong>{deviceData.tx_power || "N/A"}</strong>
                      <span>TX Power</span>
                    </div>
                    <div>
                      <strong>{deviceData.currentmcs_index || "N/A"}</strong>
                      <span>Current MCS Index</span>
                    </div>
                    <div>
                      <strong>{deviceData.snr || "N/A"}</strong>
                      <span>SNR</span>
                    </div>
                    <div>
                      <strong>{deviceData.cinr || "N/A"}</strong>
                      <span>CINR</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_added || "N/A"}</strong>
                      <span>Device Added</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_type || "N/A"}</strong>
                      <span>Device Type</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_datetime || "N/A"}</strong>
                      <span>Device DateTime</span>
                    </div>
                    <div>
                      <strong>{deviceData.client_mac || "N/A"}</strong>
                      <span>Client MAC</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_up_time || "N/A"}</strong>
                      <span>Device Up Time</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_down_time || "N/A"}</strong>
                      <span>Device Down Time</span>
                    </div>
                    <div>
                      <strong>{deviceData.tx_power || "N/A"}</strong>
                      <span>TX Power</span>
                    </div>
                    <div>
                      <strong>{deviceData.ssid5_name || "N/A"}</strong>
                      <span>SSID Name</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chat />
    </div>
  );
}

export default SnmpDevice;
