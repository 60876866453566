import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import allApi from "../../../api/allApi";
import D3Chart from "./D3Chart";
import towerIconSvg from "../../../assets/images/inc-cloud.svg";
import edgeIcon from "../../../assets/images/edge.svg";
import switchIcon from "../../../assets/images/switch.svg";
import apIcon from "../../../assets/images/access-point.svg";
import { useLocation } from "react-router-dom";
import Chat from "../common/chat";

// Custom function to handle Leaflet icon generation
const icon = (iconUrl) =>
  L.icon({
    iconUrl: iconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

const renderTooltipContent = (marker) => {
  return (
    <div className="map-tooltip">
      {marker.markerType === "SNMP" ? (
        <div>
          <strong>Main Server:</strong>
          <label>{marker.label || "N/A"}</label>
        </div>
      ) : marker.markerType === "HTTP" ? (
        <div>
          <strong>Main Server:</strong>
          <label>{marker.label || "N/A"}</label>
        </div>
      ) : marker.markerType === "EDGE" ? (
        <div>
          <strong>Edge:</strong>
          <label>{marker.label || "N/A"}</label>
        </div>
      ) : marker.markerType === "DEVICE" ? (
        <div>
          <strong>Device:</strong>
          <label>{marker.label || "N/A"}</label>
        </div>
      ) : (
        <div>
          <strong>Device:</strong>
          <label>{marker.label || "N/A"}</label>
        </div>
      )}
      <div className="map-info">
        <div>
          <strong>Status:</strong>
          <label>{marker.status === 0 ? "Offline" : "Online"}</label>
        </div>
        <div>
          {marker.markerType === "SNMP" ? (
            <div>
              <strong>Managed Edges:</strong>
              <label>{marker.edgeManaged || "0"}</label>
            </div>
          ) : marker.markerType === "HTTP" ? (
            <div>
              <strong>Managed Devices:</strong>
              <label>{marker.httpDeviceManaged || "0"}</label>
            </div>
          ) : marker.markerType === "EDGE" ? (
            <div>
              <strong>Managed Devices:</strong>
              <label>{marker.connected_clients || "0"}</label>
            </div>
          ) : marker.markerType === "DEVICE" ? (
            <div>
              <strong>Managed Client:</strong>
              <label>{marker.connected_clients || "0"}</label>
            </div>
          ) : (
            <div>
              <strong>Managed Devices:</strong>
              <label>{marker.managedDevices || "N/A"}</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function Topology() {
  const [activeTab, setActiveTab] = useState("snmp");
  const [locations, setLocations] = useState({});
  const [markers, setMarkers] = useState([]);
  const [httpMarkers, setHttpMarkers] = useState([]);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [snmpPopup, setSnmpPopup] = useState(true);
  const [httpPopup, setHttpPopup] = useState(true);
  const [isBackDisabled, setIsBackDisabled] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.deviceTopology) {
      setActiveTab("https");
      getLocations();
    } else if (location.state && location.state.snmpTopology) {
      setActiveTab("snmp");
      getLocations();
    } else {
      setActiveTab("snmp");
      getLocations();
    }
  }, [location.state]);

  useEffect(() => {
    setSelectedDevice(null);
    setSelectedEdge(null);

    // Reset to show the map when switching tabs
    setSnmpPopup(true);
    setHttpPopup(true);
  }, [activeTab]);

  const handleBackDisabled = () => {
    setIsBackDisabled(!(selectedEdge || selectedDevice));
  };

  useEffect(() => {
    handleBackDisabled();
  }, [selectedEdge, selectedDevice]);

  // Fetch all topology data
  const getLocations = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/topology/all");
      if (response) {
        setLocations(response);
        extractMarkers(response);
      }
    } catch (error) {
      console.error("Failed to fetch locations", error);
    }
  };

  const extractMarkers = (data) => {
    const deviceMarkers = [];
    const htMarkers = [];

    // Main Location (e.g., INC)
    if (data.INC?.latitude && data.INC?.longitude) {
      deviceMarkers.push({
        position: [data.INC.latitude, data.INC.longitude],
        label: `INC ${data.INC.Location}` || "Main Location",
        icon: towerIconSvg,
        status: data.INC.status,
        edgeManaged: data.INC.edges_managed,
        markerType: "SNMP",
      });
      htMarkers.push({
        position: [data.INC.latitude, data.INC.longitude],
        label: `INC ${data.INC.Location}` || "Main Location",
        icon: towerIconSvg,
        status: data.INC.status,
        httpDeviceManaged: data.INC.connected_http_devices,
        markerType: "HTTP",
      });
    }

    // Edge Gateways (SNMP)
    if (data.edgeGateways) {
      data.edgeGateways.forEach((edge) => {
        if (edge.latitude && edge.longitude) {
          deviceMarkers.push({
            position: [edge.latitude, edge.longitude],
            label: edge.edge_name,
            icon: edgeIcon,
            status: edge.status,
            markerType: "EDGE",
            connected_clients: edge.total_devices,
            onClick: () => handleEdgeSelect(edge),
          });
        }
      });
    }

    // Wired Devices (HTTP)
    if (data.wired) {
      data.wired.forEach((device) => {
        if (device.latitude && device.longitude) {
          htMarkers.push({
            position: [device.latitude, device.longitude],
            label: device.device_name,
            icon: switchIcon,
            status: device.status,
            markerType: "DEVICE",
            connected_clients: device.no_of_connected_clients,
            onClick: () => handleHttpDeviceSelect(device),
          });
        }
      });
    }

    // Wireless Devices (HTTP)
    if (data.wireless) {
      data.wireless.forEach((device) => {
        if (device.latitude && device.longitude) {
          htMarkers.push({
            position: [device.latitude, device.longitude],
            label: device.device_name,
            icon: apIcon,
            status: device.status,
            markerType: "DEVICE",
            connected_clients: device.no_of_connected_clients,
            onClick: () => handleHttpDeviceSelect(device),
          });
        }
      });
    }

    setMarkers(deviceMarkers); // For SNMP
    setHttpMarkers(htMarkers); // For HTTP
  };

  // Handle edge selection
  const handleEdgeSelect = (edge) => {
    setSnmpPopup(false);
    setSelectedEdge(edge);
    setSelectedDevice(null); // Reset selected device when an edge is selected
    setIsBackDisabled(false);
  };

  // Handle device selection
  const handleHttpDeviceSelect = (device) => {
    setHttpPopup(false);
    setSelectedDevice(device);
    setSelectedEdge(null);
    setIsBackDisabled(false);
  };

  const handleBackClick = () => {
    if (activeTab === "snmp") {
      setSnmpPopup(true); // Show the map in SNMP tab
    } else if (activeTab === "https") {
      setHttpPopup(true); // Show the map in HTTPS tab
    }
    setSelectedEdge(null);
    setSelectedDevice(null); // Clear selected edge or device
    setIsBackDisabled(true);
  };

  // const handleBackDisabled = () =>{
  //   if(selectedEdge || selectedDevice){
  //     setIsBackDisabled(false)
  //   }else{
  //     setIsBackDisabled(true);
  //   }
  // }

  const generatePolylines = (markers, parentPosition) => {
    return markers.map((marker, index) => {
      const lineColor = marker.status === 0 ? "#9F0000" : "green";
      return (
        <Polyline
          key={`line-${index}`}
          positions={[parentPosition, marker.position]}
          color={lineColor}
          pathOptions={{ dashArray: "5, 10", weight: 2 }}
        />
      );
    });
  };

  const incPosition =
    locations.INC && locations.INC.latitude && locations.INC.longitude
      ? [locations.INC.latitude, locations.INC.longitude]
      : null;

  const mapKey = activeTab === "snmp" ? "snmp-map" : "https-map";

  // Render the component
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="discoverTab" role="tablist">
              <li className="nav-item" role="discover">
                <button
                  className={`nav-link ${activeTab === "snmp" ? "active" : ""}`}
                  onClick={() => setActiveTab("snmp")}
                >
                  SNMP
                </button>
              </li>
              <li className="nav-item" role="discover">
                <button
                  className={`nav-link ${
                    activeTab === "https" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("https")}
                >
                  HTTPS
                </button>
              </li>
            </ul>
            <div>
              <button
                className="text-btn primary-btn"
                role="button"
                disabled={isBackDisabled}
                style={{ cursor: isBackDisabled ? "not-allowed" : "pointer" }}
                onClick={handleBackClick}
              >
                Back
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="discoverTabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === "snmp" ? "active show" : ""
                }`}
              >
                {activeTab === "snmp" && (
                  <div className="topology-draft">
                    {snmpPopup && incPosition && (
                      <div style={{ height: "78vh" }}>
                        <MapContainer
                          key={mapKey}
                          center={[28.3579, 76.936]}
                          zoom={9}
                          style={{ height: "100%", width: "100%" }}
                        >
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                          {markers.map((marker, index) => (
                            <Marker
                              key={index}
                              position={marker.position}
                              icon={icon(marker.icon)}
                              eventHandlers={{ click: marker.onClick }} // Use the onClick handler
                            >
                              <Tooltip>{renderTooltipContent(marker)}</Tooltip>
                            </Marker>
                          ))}
                          {generatePolylines(markers, incPosition)}
                        </MapContainer>
                      </div>
                    )}
                    <div>
                      <div className="row">
                        <div className="col-lg-8">
                          {(selectedEdge || selectedDevice) && (
                            <div>
                              <h4>
                                {selectedEdge
                                  ? ` Edge : ${
                                      selectedEdge.label ||
                                      selectedEdge.edge_name
                                    }`
                                  : `Device: ${
                                      selectedDevice.label ||
                                      selectedDevice.device_name
                                    }`}
                              </h4>
                              <D3Chart
                                data={
                                  selectedEdge
                                    ? {
                                        INC: locations.INC,
                                        edgeGateways: [selectedEdge],
                                        devices: selectedEdge.devices,
                                      }
                                    : {
                                        INC: locations.INC,
                                        devices: [selectedDevice],
                                      }
                                }
                              />
                            </div>
                          )}
                        </div>
                        {(selectedEdge || selectedDevice) && (
                          <div className="col-lg-4">
                            <div className="round-border topology-info">
                              <div className="inc-card-table">
                                <table className="m-head">
                                  <thead>
                                    <tr>
                                      <th>Edge Name</th>
                                      <th>Status</th>
                                      <th>Managed Devices</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedEdge ? (
                                      <tr>
                                        <td>
                                          {selectedEdge.edge_name ||
                                            selectedEdge.label}
                                        </td>
                                        <td>
                                          {selectedEdge.status === 1
                                            ? "Online"
                                            : "Offline"}
                                        </td>
                                        <td>{selectedEdge.total_devices}</td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td colSpan="2">No Edge Selected</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="inc-card-table">
                                <table className="m-head">
                                  <thead>
                                    <tr>
                                      <th>Device Mode</th>
                                      <th>Status</th>
                                      <th>Mac Address</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedEdge ? (
                                      selectedEdge.devices &&
                                      selectedEdge.devices.length > 0 ? (
                                        selectedEdge.devices.map(
                                          (device, idx) => (
                                            <tr key={idx}>
                                              <td>{device.mode}</td>
                                              <td>{device.health}</td>
                                              <td>{device.mac_addess}</td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No Master Device</td>
                                        </tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="3">No Edge Selected</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="inc-card-table">
                                <table className="m-head">
                                  <thead>
                                    <tr>
                                      <th>Device Mode</th>
                                      <th>Status</th>
                                      <th>Mac Address</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedEdge ? (
                                      selectedEdge.devices &&
                                      selectedEdge.devices.length > 0 ? (
                                        selectedEdge.devices.flatMap((device) =>
                                          device.slaves &&
                                          device.slaves.length > 0
                                            ? device.slaves.map(
                                                (slave, indx) => (
                                                  <tr key={indx}>
                                                    <td>{slave.mode}</td>
                                                    <td>{slave.health}</td>
                                                    <td>{slave.mac_addess}</td>
                                                  </tr>
                                                )
                                              )
                                            : []
                                        ).length > 0 ? (
                                          selectedEdge.devices.flatMap(
                                            (device) =>
                                              device.slaves &&
                                              device.slaves.length > 0
                                                ? device.slaves.map(
                                                    (slave, indx) => (
                                                      <tr key={indx}>
                                                        <td>{slave.mode}</td>
                                                        <td>{slave.health}</td>
                                                        <td>
                                                          {slave.mac_addess}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )
                                                : []
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan="3">No Slave Device</td>
                                          </tr>
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No Edge Selected</td>
                                        </tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="3">No Edge Selected</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`tab-pane fade ${
                  activeTab === "https" ? "active show" : ""
                }`}
              >
                {activeTab === "https" && (
                  <div className="topology-draft">
                    {httpPopup && incPosition && (
                      <div style={{ height: "100vh" }}>
                        <MapContainer
                          key={mapKey}
                          center={[21.1458, 79.0882]}
                          zoom={5}
                          style={{ height: "80%", width: "100%" }}
                        >
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                          {httpMarkers.map((marker, index) => (
                            <Marker
                              key={index}
                              position={marker.position}
                              icon={icon(marker.icon)}
                              eventHandlers={{ click: marker.onClick }} // Add click handler
                            >
                              <Tooltip> {renderTooltipContent(marker)}</Tooltip>
                            </Marker>
                          ))}
                          {generatePolylines(httpMarkers, incPosition)}
                        </MapContainer>
                      </div>
                    )}
                    <div>
                      <div className="row">
                        <div className="col-lg-8">
                          {selectedDevice && (
                            <>
                              <h4>{` Device : ${selectedDevice.device_name}`}</h4>
                              <D3Chart
                                data={{
                                  INC: locations.INC,
                                  // Check if the selected device is wired or wireless
                                  ...(selectedDevice.type === "wired"
                                    ? { wired: [selectedDevice] } // Only include wired devices
                                    : { wireless: [selectedDevice] }), // Only include wireless devices
                                }}
                              />
                            </>
                          )}
                        </div>
                        {selectedDevice && (
                          <div className="col-lg-4">
                            <div className="round-border topology-info">
                              <div className="inc-card-table">
                                <table className="m-head">
                                  <thead>
                                    <tr>
                                      <th>Device Name</th>
                                      <th>Status</th>
                                      <th>Managed Clients</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedDevice ? (
                                      <tr>
                                        <td>{selectedDevice.device_name}</td>
                                        <td>
                                          {selectedDevice.status === 0
                                            ? "Offline"
                                            : "Online"}
                                        </td>
                                        <td>
                                          {selectedDevice.connected_clients ||
                                            "0"}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td colSpan="2">No Device Selected</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="inc-card-table">
                                <table className="m-head">
                                  <thead>
                                    <tr>
                                      <th>Device Name</th>
                                      <th>Status</th>
                                      <th>Device Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedDevice ? (
                                      selectedDevice.clients &&
                                      selectedDevice.clients.length > 0 ? (
                                        selectedDevice.clients.map(
                                          (client, indx) => (
                                            <tr key={indx}>
                                              <td>{client.deviceName}</td>
                                              <td>
                                                {client.isConnected === 0
                                                  ? "Offline"
                                                  : "Online"}
                                              </td>
                                              <td>{client.connDeviceType}</td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No Clients</td>
                                        </tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="3">No Device Selected</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Chat />
      </div>
    </div>
  );
}

export default Topology;
