import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

class allApi {
  constructor() {
    this.authToken = this.getToken();
  }

  async fetch(url, options = {}) {
    const token = this.getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token != undefined) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const baseUrl = window.location.origin;
      const response = await axios(`${baseUrl}/${url}`, {
        ...options,
        headers,
      });

      return response.data;
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  }

  setToken(token) {
    cookies.set("vvdnTech", token, { path: "/" });
  }

  getToken() {
    return localStorage.getItem("sessionToken");
  }

  // login api call
  // async login(endpoint, data) {
  //   try {
  //     const response = await this.fetch(endpoint, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json; charset=UTF-8",
  //       },
  //       credentials: "include",
  //       body: JSON.stringify(data),
  //     });
  //     const { status, data: responseData } = response;
  //     if (status === 200) {
  //       this.setToken(responseData?.data?.token);
  //     }
  //     return response;
  //   } catch (error) {
  //     console.error("Login Error:", error);
  //     throw error;
  //   }
  // }

  async genericSearch(endpoint, query) {
    const queryParams = new URLSearchParams({ search: query }).toString();
    const searchEndpoint = `${endpoint}?${queryParams}`;

    try {
      return await this.getRequest(searchEndpoint);
    } catch (error) {
      console.error(`Error with search request at ${searchEndpoint}:`, error);
      throw error;
    }
  }

  // separate api calls for different types of requests

  async postRequest(endpoint, userEmail, payload) {
    let urlWithParams;
    if (endpoint === "api/forgotPassword") {
      urlWithParams = `${endpoint}?email=${userEmail.email}`;
    } else {
      urlWithParams = `${endpoint}`;
    }

    const requestBody = {
      data: userEmail.email,
    };
    if (payload !== null) {
      requestBody.payload = payload;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      credentials: "include",
      data: requestBody.payload,
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await this.fetch(urlWithParams, requestOptions);
      return response;
    } catch (error) {
      console.error("Error in postRequest:", error);
      throw error;
    }
  }

  // separate POST api calls for different types of requests
  async postMultiRequest(endpoint, formData) {
    const headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };

    try {
      const response = await axios.post(
        `${window.location.origin}/${endpoint}`,
        formData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error("Error in postMultiRequest:", error);
      throw error;
    }
  }

  async patchRequest(endpoint, data) {
    return this.fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      body: JSON.stringify(data),
    });
  }

  async webSoc(endpoint, data) {
    return this.fetch(endpoint, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": window.location.origin,
      },
      body: JSON.stringify(data),
    });
  }

  async getRequest(endpoint, payload, includeToken = true) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (includeToken) {
      const token = this.getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    try {
      const queryParams = payload
        ? new URLSearchParams(payload).toString()
        : "";
      const urlWithParams = payload ? `${endpoint}?${queryParams}` : endpoint;
      return await this.fetch(urlWithParams, {
        method: "GET",
        mode: "cors",
        headers,
        cache: "no-store",
      });
    } catch (error) {
      console.error("GET Request Error:", error);
      throw error;
    }
  }

  async getDownloadRequest(endpoint, payload, includeToken = true) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (includeToken) {
      const token = this.getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    try {
      // Prepare query parameters
      const queryParams = payload
        ? new URLSearchParams(
            Object.entries(payload).reduce((acc, [key, value]) => {
              if (Array.isArray(value)) {
                value.forEach((v) => acc.append(key, v));
              } else {
                acc.append(key, value);
              }
              return acc;
            }, new URLSearchParams())
          ).toString()
        : "";
      const urlWithParams = queryParams
        ? `${endpoint}?${queryParams}`
        : endpoint;

      const response = await fetch(urlWithParams, {
        method: "GET",
        mode: "cors",
        headers,
        cache: "no-store",
      });

      // Check response status
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Determine the response type for downloads (Blob)
      const contentType = response.headers.get("Content-Type");
      if (
        contentType &&
        (contentType.includes("application/octet-stream") ||
          contentType.includes("application/pdf"))
      ) {
        return await response.blob(); // Parse Blob response
      } else {
        throw new Error("Unsupported Content-Type for download");
      }
    } catch (error) {
      console.error("Download Request Error:", error);
      throw error;
    }
  }

  async putRequest(endpoint, payload) {
    let urlWithParams = `${endpoint}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      credentials: "include",
      data: payload,
    };
    try {
      const response = await this.fetch(urlWithParams, requestOptions);
      return response;
    } catch (error) {
      console.error("Error in putRequest:", error);
      throw error;
    }
  }
  async putMultiRequest(endpoint, formData) {
    const headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };

    try {
      const response = await axios.put(
        `${window.location.origin}/${endpoint}`,
        formData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error("Error in putMultiRequest:", error);
      throw error;
    }
  }

  // logout api call
  async logout(endpoint, data) {
    return this.fetch(endpoint, { method: "POST", body: JSON.stringify(data) });
  }

  // DELETE API
  async deleteRequest(endpoint, payload) {
    const authToken = this.getToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    try {
      const baseUrl = window.location.origin;
      const response = await axios.delete(`${baseUrl}/${endpoint}`, {
        headers: headers,
        data: payload,
      });

      return response.data;
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  }
}

export default allApi;
