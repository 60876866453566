import React, { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";
import Loader from "../common/Loader";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";
import moment from "moment";
import Chat from "../common/chat";

function App() {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  //const severityLevel = queryParams.get("severityLevel");
  const [severityLevel, setSeverityLevel] = useState(null);
  const [totalDevices, setTotalDevices] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceListings, setDeviceListings] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [originalListings, setOriginalListings] = useState([]);
  const [devicesPerPage] = useState(50);
  const [isSearchOpen, setIsSearchOpen] = useState({
    device_name: false,
    alarmType: false,
    severityLevel: false,
    is_acknowledged: false,
    is_autoclear: false,
    notification: false,
    alarm_behaviour: false,
    alarm_description: false,
    alarm_time: false,
    acked_time: false,
    cleared_time: false,
    is_read: false,
    event_name: false,
    device_ipv6: false,
    device_ip: false,
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [alarmToDelete, setAlarmToDelete] = useState(null);
  const [faults, setFaults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [sortOrder, setSortOrder] = useState({
    device_name: "asc",
    device_ip: "asc",
    severityLevel: "asc",
    alarm_behaviour: "asc",
    event_name: "asc",
    alarm_time: "asc",
    alarm_description: "asc",
  });
  const [alertsData, setAlertsData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("1w");
  const [selectedCategory, setSelectedCategory] = useState("Miscellaneous");
  const [selectedSeverity, setSelectedSeverity] = useState("Critical");
  const [trendsLoading, settrendsLoading] = useState(true);
  const [trendsData, setTrendsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLinks, setPageLinks] = useState([]);
  const severityLevels = {
    Critical: 1,
    Major: 2,
    Minor: 3,
    Info: 4,
  };
  useEffect(() => {
    if (location.state && location.state.severityAlerts) {
      const severityAlerts = location.state.severityAlerts;
      setDeviceListings(severityAlerts);
      setFilteredDevices(severityAlerts);
      setTotalDevices(severityAlerts.length);
      setLoading(false);
    } else {
      fetchAlarmData();
    }
  }, [location.state]);

  useEffect(() => {
    fetchAlertsChartData();
  }, []);
  // useEffect(() => {
  //   fetchAlarmData(currentPage);
  // }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchAlertTrends();
  }, [selectedInterval, selectedCategory, selectedSeverity]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  const handleSeverityChange = (severity) => {
    setSelectedSeverity(severity);
  };

  // useEffect(() => {
  //   if (severityLevel && !location.state) {
  //     filterDevicesBySeverity(severityLevel, deviceListings);
  //   }
  // }, [severityLevel, deviceListings]);

  const filterDevicesBySeverity = () => {
    if (severityLevel) {
      const filtered = deviceListings.filter(
        (alarm) => alarm.severityLevel === severityLevel
      );
      setFilteredDevices(filtered);
      setTotalDevices(filtered.length);
    } else {
      setFilteredDevices(deviceListings);
      setTotalDevices(deviceListings.length);
    }
  };

  const fetchAlarmData = async (page = 1) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms/all?page=${page}&size=${devicesPerPage}`
      );
      if (response && response.items) {
        setDeviceListings(response.items);
        //setFilteredDevices(response.items); // Use this directly
        setOriginalListings(response.items);
        setTotalPages(response.totalPages || 1);
        setTotalDevices(response.totalItems || 0);
      } else {
        console.error("Invalid API response:", response);
        setDeviceListings([]);
        setFilteredDevices([]);
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (alarmId) => {
    try {
      const api = new allApi();
      await api.deleteRequest(`api/alarms/${alarmId}`);
      fetchAlarmData();
      setAlarmToDelete(null);
    } catch (error) {
      console.error("Error deleting alarm:", error);
    }
  };

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedCheckboxes(filteredDevices.map((device) => device.alarm_id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      // Reset filtered devices if the search box is cleared
      setDeviceListings(originalListings); // Fetch all alarms or reset to initial data
    } else {
      // Filter based on the input value and the field (name)
      const filtered = originalListings.filter((device) =>
        device[name]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setDeviceListings(filtered);
    }

  };

  const handleSearchBox = (header) => {
    const updatedSearchOpen = {
      ...isSearchOpen,
      [header]: !isSearchOpen[header],
    };
    setIsSearchOpen(updatedSearchOpen);
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const totalFilteredDevices = filteredDevices.length;
  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const handleFilterDevice = () => {
    return filteredDevices.length
      ? filteredDevices.slice(
        (currentPage - 1) * devicesPerPage,
        currentPage * devicesPerPage
      )
      : [];
  };

  const handleSortChange = (field) => {
    // Toggle the sort order for the clicked field
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";

    // Sort the gateways based on the field and order
    const sortedDevices = [...deviceListings].sort((a, b) => {
      // Handle undefined values gracefully
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setDeviceListings(sortedDevices);
    setSortOrder((prevState) => ({
      ...prevState,
      [field]: newOrder, // Set the new sort order for the field
    }));
  };

  const successhandleClose = () => {
    setShow(false);
    setAlarmToDelete(null);
  };

  // const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchAlarmData(nextPage);
    }
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
    fetchAlarmData(1);
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      fetchAlarmData(prevPage);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchAlarmData(page);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchAlarmData(totalPages);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const COLORS = [
    "#FF6B6B",
    "#FFAD01",
    "#26BF78",
    "#118AB2",
    "#a4de6c",
    "#8dd1e1",
  ];

  const fetchAlertsChartData = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/alarms/charts");

      if (response?.response) {
        // Transform the data into an array suitable for the chart
        const formattedData = Object.keys(response.response).map(
          (category) => ({
            name: category,
            value: response.response[category].count,
          })
        );
        setAlertsData(formattedData);
      } else {
        console.error("Unexpected API response:", response);
        setAlertsData([]);
      }
    } catch (error) {
      console.error("Error fetching alerts data:", error);
    } finally {
      setLoading(false);
    }
  };
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        className="custom-legend category"
        style={{
          listStyleType: "none",
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 10,
              marginBottom: 6,
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: entry.color,
                marginRight: 6,
              }}
            />
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const fetchAlertTrends = async () => {
    settrendsLoading(true);
    try {
      const api = new allApi();
      const severity = severityLevels[selectedSeverity];
      const response = await api.getRequest(
        `api/alarms/alertTrends?duration=${selectedInterval}&category=${selectedCategory}&severityLevel=${severity}`
      );

      if (Array.isArray(response)) {
        const trendsData = response.map((item) => ({
          ...item,
          alert_time: formatAlertTime(item.alert_time), // Make sure this is returning a timestamp!
        }));
        setTrendsData(trendsData);
      } else {
        setTrendsData([]);
      }

    } catch (error) {
      console.error("Error fetching alerts data:", error);
    } finally {
      settrendsLoading(false);
    }
  };

  const formatAlertTime = (alertTime) => {
    // Handle "HH:MM" time format (24-hour)
    if (/^\d{2}:\d{2}$/.test(alertTime)) {
      const today = new Date();
      const [hours, minutes] = alertTime.split(":");
      today.setHours(parseInt(hours, 10));
      today.setMinutes(parseInt(minutes, 10));
      today.setSeconds(0);
      today.setMilliseconds(0);

      return today.getTime(); // Return timestamp for X-Axis plotting
    }

    // Handle "DD-MM-YYYY" date format
    if (/^\d{2}-\d{2}-\d{4}$/.test(alertTime)) {
      const [day, month, year] = alertTime.split("-");
      const formattedDate = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );

      if (isNaN(formattedDate.getTime())) {
        console.error("Invalid alert_time format:", alertTime);
        return null;
      }

      return formattedDate.getTime(); // Return timestamp
    }

    // If format doesn't match, log error and return null
    console.error("Invalid alert_time format:", alertTime);
    return null;
  };


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row mb-4">
          <div className="col-lg-5">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Category</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-lg-6">
                      <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                          <Pie
                            data={alertsData}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                          >
                            {alertsData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          {/* <Legend
                            content={renderLegend}
                            verticalAlign="bottom"
                            height={60}
                            wrapperStyle={{ marginTop: "20px" }}
                          />  */}
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="col-lg-6 category-label">
                      <ul>
                        <li className="ms">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Miscellaneous
                        </li>
                        <li className="dc">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Device Configuration
                        </li>
                        <li className="ds">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Device Status
                        </li>
                        <li className="th">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Threshold
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Alerts Summary</h3>

                <div className="summary-dropdown">
                  <div>
                    {/* Interval Dropdown */}
                    <span
                      className="dropdown"
                      id="intervalDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        {selectedInterval}{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="intervalDropdown"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleIntervalChange("24h");
                            }}
                          >
                            24 Hour
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleIntervalChange("1w");
                            }}
                          >
                            1 Week
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleIntervalChange("2w");
                            }}
                          >
                            2 Week
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleIntervalChange("1m");
                            }}
                          >
                            Monthly
                          </a>
                        </li>
                      </ul>
                    </span>
                  </div>
                  <div>
                    <span
                      className="dropdown"
                      id="categoryDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        {selectedCategory}{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <ul
                        className="dropdown-menu dropdown-menu-start"
                        aria-labelledby="categoryDropdown"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryChange("Miscellaneous");
                            }}
                          >
                            Miscellaneous
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryChange("Threshold");
                            }}
                          >
                            Threshold
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryChange("Device Configuration");
                            }}
                          >
                            Device Configuration
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryChange("Device Status");
                            }}
                          >
                            Device Status
                          </a>
                        </li>
                      </ul>
                    </span>
                  </div>

                  <div>
                    {/* Severity Dropdown */}
                    <span
                      className="dropdown"
                      id="severityDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        {selectedSeverity}{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="severityDropdown"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSeverityChange("Critical");
                            }}
                          >
                            Critical
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSeverityChange("Major");
                            }}
                          >
                            Major
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSeverityChange("Minor");
                            }}
                          >
                            Minor
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSeverityChange("Info");
                            }}
                          >
                            Info
                          </a>
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
              <div className="inc-card-body">
                {trendsLoading ? (
                  <Loader />
                ) : (
                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart
                      data={trendsData}
                      margin={{ top: 20, right: 30, left: -20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="alert_time"
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          return selectedInterval === "24h"
                            ? date.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                            : date.toLocaleDateString();
                        }}
                      />
                      <YAxis dataKey="alert_count" />
                      <Tooltip
                        labelFormatter={(label) => {
                          // Format the X-Axis (alert_time) correctly
                          const date = new Date(label);

                          return selectedInterval === "24h"
                            ? date.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false, // Ensures 24-hour format
                            })
                            : date.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            });
                        }}
                        formatter={(value) => [`alert count: ${value}`]}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="alert_count"
                        stroke="#8884d8"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="inc-card h-auto">
          <div className="inc-card-title">
            <h3>Alerts List</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => fetchAlarmData()}
              >
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>

              {/* <button className="icon-btn" title="Download">
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
                <button className="icon-btn" title="Filter">
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div>
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Device Name
                        <i
                          className={`fa ${sortOrder.device_name === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("device_name")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Device IP
                        <i
                          className={`fa ${sortOrder.device_ip === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("device_ip")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Severity Level
                        <i
                          className={`fa ${sortOrder.severityLevel === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("severityLevel")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Alerts Behaviour
                        <i
                          className={`fa ${sortOrder.alarm_behaviour === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("alarm_behaviour")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Event Name
                        <i
                          className={`fa ${sortOrder.event_name === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("event_name")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Alerts Time
                        <i
                          className={`fa ${sortOrder.alarm_time === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("alarm_time")}
                          aria-hidden="true"
                        ></i>
                      </th>
                      <th>
                        Alerts Description
                        <i
                          className={`fa ${sortOrder.alarm_description === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                            }`}
                          onClick={() => handleSortChange("alarm_description")}
                          aria-hidden="true"
                        ></i>
                      </th>
                    </tr>
                    <tr className="search-border">
                      <td></td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="device_name"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="device_ip"
                          onKeyUp={handleSearch}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="severityLevel"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_behaviour"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="event_name"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_time"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="searchBox smallWidth"
                          type="text"
                          name="alarm_description"
                          onKeyUp={(e) => handleSearch(e)}
                        />
                      </td>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan="8">
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : deviceListings.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="8">
                          <div style={{ textAlign: "center" }}>
                            <p>No alerts to display</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {deviceListings.map((device, index) => (
                        <tr key={index}>
                          <td></td>
                          <td>{device.device_name || "N/A"}</td>
                          <td>{device.device_ip || "N/A"}</td>
                          <td>{device.severityLevel || "N/A"}</td>
                          <td>{device.alarm_behaviour || "N/A"}</td>
                          <td>{device.event_name || "N/A"}</td>
                          <td>{device.alarm_time || "N/A"}</td>
                          <td>{device.alarm_description || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-container">
            {totalDevices > 0 ? (
              <>
                <div className="pagination-info">
                  {totalDevices > 0 ? (
                    <p>
                      Showing {indexOfFirstDevice + 1}-
                      {location?.state && location?.state?.severityAlerts
                        ? totalDevices
                        : Math.min(
                          currentPage * devicesPerPage,
                          totalDevices
                        )}{" "}
                      of {totalDevices} records
                    </p>
                  ) : (
                    <p>No Records Available</p>
                  )}
                </div>
                {!location?.state && !location?.state?.severityAlerts && (
                  <div className="pagination">
                    <button
                      onClick={handleFirstPage}
                      disabled={currentPage === 1}
                    >
                      First Page
                    </button>
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                    >
                      Last Page
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div>
                <p>No Records Available</p>
              </div>
            )}
          </div>
        </div>
        <Chat />
      </div>
    </div>
  );
}

export default App;
