import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import clientGraph from "../../../../assets/images/Graph.png";
import edgeGraph from "../../../../assets/images/edge-chart.png";
import cpuIcon from "../../../../assets/images/cpu.png";
import memoryIcon from "../../../../assets/images/memory.png";
import diskIcon from "../../../../assets/images/disk.png";
import dbIcon from "../../../../assets/images/db.png";
import thumUp from "../../../../assets/images/thumb-up.png";
import ApexCharts from "react-apexcharts";
import allApi from "../../../../api/allApi";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate, useLocation } from "react-router-dom";
import { Progress } from "antd";
import { parse, format } from "date-fns";
import Chat from "../../common/chat";
import OverviewDevices from "./OverviewDevices";
import OverviewTrends from "./OverviewTrends";
import OverviewSystemHealth from "./OverviewSystemHealth";
// import { useLanguage } from "../../LanguageContext";

const Dashboard = () => {
  // const { localization } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState({
    onlineCount: 0,
    offlineCount: 0,
    totalDevices: 0,
    waitingForConnectionCount: 0,
  });
  const [faults, setFaults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [edgeCounts, setEdgeCounts] = useState({});
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [poeData, setPoeData] = useState("");
  const [poeOptions, setPoeOptions] = useState("");
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [alerts, setAlerts] = useState();
  const severityLevelMap = {
    CRITICAL: 1,
    MAJOR: 2,
    MINOR: 3,
    INFO: 4,
  };
  const [license, setLicense] = useState("");
  const [healthStatus, setHealthStatus] = useState("All");
  const statusMap = {
    ONLINE: "Online",
    OFFLINE: "Offline",
    AWAITING: "Awaiting",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const health = searchParams.get("health") || "All";
      setHealthStatus(health);

      const api = new allApi();
      const response = await api.getRequest("api/devices");
      if (response) {
        let filteredDevices = response;
        if (health !== "All") {
          filteredDevices = response.filter(
            (device) => device.health === health
          );
        }
        setDeviceData({
          onlineCount: filteredDevices.filter(
            (device) => device.health === "Online"
          ).length,
          offlineCount: filteredDevices.filter(
            (device) => device.health === "Offline"
          ).length,
          waitingForConnectionCount: filteredDevices.filter(
            (device) => device.health === "Awaiting"
          ).length,
          totalDevices: filteredDevices.length,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const onlinePercentage =
    (deviceData.onlineCount / deviceData.totalDevices) * 100;
  const offlinePercentage =
    (deviceData.offlineCount / deviceData.totalDevices) * 100;
  const waitingForConnectionCount =
    (deviceData.waitingForConnectionCount / deviceData.totalDevices) * 100;

  const data = [
    { name: "Online", value: deviceData.onlineCount, type: "ONLINE" },
    { name: "Offline", value: deviceData.offlineCount, type: "OFFLINE" },
    {
      name: "Awaiting",
      value: deviceData.waitingForConnectionCount,
      type: "AWAITING",
    },
  ];
  const COLORS = ["#26BF78", "#FF6B6B", "#eb8919"];

  const fetchDeviceByStatus = async (statuses) => {
    try {
      setLoading(true);
      const api = new allApi();
      const statusQueries = statuses
        .map((status) => `status=${status}`)
        .join("&");
      const response = await api.getRequest(
        `api/devices/status?${statusQueries}`
      );
      if (response) {
        const deviceStatus = response;
        navigate("/device-listing", { state: { deviceStatus } });
      } else {
        console.error("Invalid data format", response);
      }
    } catch (error) {
      console.error("Error fetching device", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDevice = (status) => {
    switch (status) {
      case "ONLINE":
        return "Online";
      case "OFFLINE":
        return "Offline";
      case "AWAITING":
        return "Awaiting";
      default:
        return status;
    }
  };

  const handleHealthStatusClick = (statusType) => {
    fetchDeviceByStatus([statusType]);
  };

  
  useEffect(() => {
    const storedLicense = localStorage.getItem("license");
    setLicense(storedLicense);
  }, []);

  useEffect(() => {
    fetchEdgeData();
    fetchFaultsOverview();
  }, [selectedInterval]);

  useEffect(() => {}, [edgeCounts]);

  const fetchFaultsOverview = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/alarms/overview");
      setFaults(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching faults overview:", error);
      setLoading(false);
    }
  };

  const fetchEdgeData = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/gateway/edge_info");
      const { activeCount, inactiveCount, managedByGateway } = response;
      const totalCount = activeCount + inactiveCount;
      setEdgeCounts({
        activeCount,
        inactiveCount,
        managedByGateway,
        totalCount,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching edge data:", error);
      setLoading(false);
    }
  };

  const getSeverityLevelValue = (severityLevel) =>
    severityLevelMap[severityLevel] || null;

  const fetchAlertsBySeverity = async (severityLevel) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms/severity/${severityLevel}`
      );

      if (response && Array.isArray(response)) {
        const severityAlerts = response;
        navigate("/alarms", { state: { severityAlerts } });
        console.log("severityalerts", severityAlerts);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching severity alarms", error);
    } finally {
      setLoading(false);
    }
  };
  
  const formatSeverityLevel = (severityLevel) => {
    switch (severityLevel) {
      case "CRITICAL":
        return "Critical";
      case "MAJOR":
        return "Major";
      case "MINOR":
        return "Minor";
      case "INFO":
        return "Info";
      default:
        return severityLevel;
    }
  };

  const faultsData = Array.isArray(faults)
    ? faults.map(({ alarmSeverityLevel, count }) => ({
        name: formatSeverityLevel(alarmSeverityLevel),
        value: count,
      }))
    : [];

  const handleFaultClick = (alarmSeverityLevel) => {
    const severityValue = getSeverityLevelValue(alarmSeverityLevel);
    if (severityValue) {
      fetchAlertsBySeverity(severityValue);
    } else {
      console.error("Invalid severity level:", alarmSeverityLevel);
    }
  };

  const handleEdgeCountClick = async (status) => {
    setLoading(true);
    try {
      const api = new allApi();
      const statusResponse = await api.getRequest(
        `api/gateway/edges/status?status=${status}`
      );
      if (statusResponse) {
        navigate("/edge-management", {
          state: { statusResponse: statusResponse },
        });
      }
    } catch (error) {
      console.error("Error fetching edge status data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdgeManagedCountClick = async (managedDevices) => {
    setLoading(true);
    try {
      const api = new allApi();
      const managedDevicesResponse = await api.getRequest(
        "api/devices/managedDevices?managedDevices=true"
      );

      if (managedDevices) {
        navigate("/device-listing", {
          state: { deviceStatus: managedDevicesResponse },
        });
      }
    } catch (error) {
      console.error("Error fetching edge status data:", error);
    } finally {
      setLoading(false);
    }
  };

  const faultsColors = faultsData.map(({ name }) => {
    if (name === "Critical") return "#FF6B6B";
    if (name === "Major") return "#FFAD01";
    if (name === "Minor") return "#26BF78";
    if (name === "Info") return "#118AB2";
    return "#CCCCCC";
  });

  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ marginRight: "20px" }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: COLORS[index % COLORS.length],
                marginRight: "5px",
              }}
            ></span>
            {`${entry.value}: ${entry.payload.value}`}
          </div>
        ))}
      </div>
    );
  };

  const renderPie = (props) => {
    const { payload } = props;
    const total = payload.reduce((acc, entry) => acc + entry.value, 0);

    return (
      <div style={{ marginRight: "20px" }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: entry.value < 80 ? "#FF0000" : "#26BF78",
                marginRight: "5px",
              }}
            ></span>
            {`${entry.name}: ${((entry.value / total) * 100).toFixed(2)}%`}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const getPoeUsage = async () => {
      try {
        setLoading(true);
        // setRefreshing(true);
        const api = new allApi();
        const response = await api.getRequest("api/client/connected");

        if (response) {
          const { wiredClients, wirelessClients } = response;
          const wiredCount = wiredClients.length;
          const wirelessCount = wirelessClients.length;
          const maxVal = wiredCount + wirelessCount;
          const minVal = 0;
          const tickAmount = maxVal <= 5 ? maxVal : Math.ceil(maxVal / 200);
          setMaxValue(maxVal);
          setMinValue(minVal);
          const chartData = [
            { x: "Wired", y: wiredCount },
            { x: "Wireless", y: wirelessCount },
          ];

          setSeries([
            {
              data: chartData,
            },
          ]);

          setCategories(["Wired Clients", "Wireless Clients"]);
          // Update chart options with max value
          setPoeOptions({
            chart: {
              type: "bar",
              height: 135,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "55%",
                endingShape: "rounded",
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            yaxis: {
              categories: ["Wired Clients", "Wireless Clients"],
            },
            xaxis: {
              title: {
                text: "Clients",
              },
              labels: {
                formatter: function (val) {
                  return Math.round(val);
                },
              },
              max: maxVal,
              min: minVal,
              tickAmount: tickAmount,
            },
            tooltip: {
              y: {
                title: {
                  formatter: function () {
                    return "Managed Clients : ";
                  },
                },
              },
            },
            fill: {
              opacity: 1,
            },
          });
        } else {
          console.error("poeData is not an array:", response);
        }
      } catch (error) {
        console.error("Error fetching ports", error);
      } finally {
        setLoading(false);
        // setRefreshing(false);
      }
    };
    getPoeUsage();
  }, []);

  const totalDeviceCount = deviceData.totalDevices;

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="inc-overview-grid">
          <div className="inc-grid-col-1">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Devices</h3>
                <span className="card-count">{totalDeviceCount} </span>
              </div>
              <div className="inc-card-body">
                <OverviewDevices />
              </div>
            </div>
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Alerts</h3>
                <span className="card-count">
                  {Array.isArray(faults)
                    ? faults.reduce((total, entry) => total + entry.count, 0)
                    : 0}
                </span>
              </div>
              <div className="inc-card-body alarm-chart">
                {loading ? (
                  <Loader />
                ) : faultsData && faultsData.length > 0 ? (
                  <PieChart width={200} height={243}>
                    <Pie
                      data={faultsData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="45%"
                      innerRadius={53}
                      outerRadius={70}
                      fill="#8884d8"
                    >
                      {faultsData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={faultsColors[index % faultsColors.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                ) : (
                  <div>No data to display</div>
                )}
                <div className="fault-legends">
                  {Array.isArray(faults) &&
                    faults.map((entry, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleFaultClick(entry.alarmSeverityLevel)
                        }
                      >
                        <strong
                          style={{
                            color:
                              entry.alarmSeverityLevel === "MAJOR"
                                ? "#FFAD01"
                                : faultsColors[index % faultsColors.length],
                          }}
                        >
                          {entry.count}
                        </strong>
                        <span
                          style={{
                            color:
                              entry.alarmSeverityLevel === "MAJOR"
                                ? "#FFAD01"
                                : faultsColors[index % faultsColors.length],
                          }}
                        >
                          {formatSeverityLevel(entry.alarmSeverityLevel)}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Managed Clients</h3>

                <span className="card-count">{maxValue}</span>
              </div>
              <div
                className="inc-card-body"
                onClick={() => navigate("/client")}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <div className="manage-client">
                    {series.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <ResponsiveContainer aspect={1.5}>
                        <ApexCharts
                          options={poeOptions}
                          series={series}
                          type="bar"
                          height={250}
                          width={375}
                        />
                      </ResponsiveContainer>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Edges</h3>
                <span className="card-count">{edgeCounts.totalCount}</span>
              </div>
              <div className="inc-card-body">
                {edgeCounts && edgeCounts.totalCount > 0 ? (
                  <div
                    className="edge-chart"
                    onClick={() => handleEdgeCountClick("active")}
                  >
                    <Progress
                      percent={
                        edgeCounts.totalCount
                          ? (edgeCounts.activeCount / edgeCounts.totalCount) *
                            100
                          : 0
                      }
                      status="active"
                      showInfo={false}
                    />
                  </div>
                ) : (
                  <div className="edge-chart">No data to display</div>
                )}
                <div className="edge-legend">
                  <ul>
                    <li onClick={() => handleEdgeCountClick("active")}>
                      <span className="active"></span> Active{" "}
                      <strong>{edgeCounts.activeCount}</strong>
                    </li>
                    <li onClick={() => handleEdgeCountClick("inactive")}>
                      <span className="inactive"></span> Inactive
                      <strong>{edgeCounts.inactiveCount}</strong>
                    </li>
                    <li onClick={() => handleEdgeManagedCountClick("managed")}>
                      {" "}
                      <span className="managed"></span> Managed Devices{" "}
                      <strong>{edgeCounts.managedByGateway}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="inc-card box5">
              <OverviewTrends />
            </div>
          </div>
          {license &&
            (license !== "Standard" ||
              license === "Bussiness" ||
              license === "Enterprise") && (
              <div className="inc-card">
                <OverviewSystemHealth />
              </div>
            )}
        </div>
      </div>
      <Chat />
    </div>
  );
};

export default Dashboard;
