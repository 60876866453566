import React, { useState, useEffect } from "react";
import deviceImage from "../../../assets/images/device.png";
import "leaflet/dist/leaflet.css";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import StackedHorizontalChart from "../dashboard/StackedHorizontalChart";
// import { useLocation } from "react-router-dom";
import Chat from "../common/chat";
import { useLocation } from "react-router-dom";

function Tr069Dashboard() {
  const location = useLocation();
  const { deviceId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState(null);

  useEffect(() => {
    if (deviceId) {
      fetchDeviceDetails(deviceId);
    }
  }, [deviceId]);

  const fetchDeviceDetails = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/tr069/device/${deviceId}`);
      if (response) {
        setDeviceDetails(response);
      } else {
        console.error("No data returned from API");
      }
    } catch (error) {
      console.error("Error fetching device details:", error.message || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                {/* {deviceDetails && ( */}
                <div className="dashboard-device-icon">
                  <img src={deviceImage} alt="" />
                  <strong>
                    {deviceDetails?.genieInfo.deviceID.oui || "N/A"}
                  </strong>
                </div>
                {/* )} */}

                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>
                        {deviceDetails?.genieInfo.deviceID.serialNumber ||
                          "N/A"}
                      </strong>

                      <span>Serial Number</span>
                    </div>
                    <div>
                      <strong>
                        {deviceDetails?.genieInfo.internetGatewayDevice
                          .lanDevice.lanDeviceConfig.hosts.host.host_1.ipAddress
                          .value || "N/A"}
                      </strong>
                      <span>IP Address</span>
                    </div>
                    <div>
                      <strong>
                        <strong>
                          {deviceDetails?.genieInfo.internetGatewayDevice
                            .wanDevice.wanDeviceConfig.wanConnectionDevice
                            .wanConnectionDeviceConfig.wanIPConnection
                            .wanIpConnectionConfig.macAddress.value || "N/A"}
                        </strong>
                      </strong>
                      <span>Mac Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.health}</strong>
                      <span>Device Health</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-8">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Data Usage</h3>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : series.length === 0 ? (
                <div className="no-data-box">
                  <div className="no-data-text">No Data Available</div>
                </div>
              ) : (
                <StackedHorizontalChart data={series} />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Connected Client</h3>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : series.length === 0 ? (
                <div className="no-data-box">
                  <div className="no-data-text">No Data Available</div>
                </div>
              ) : (
                <StackedHorizontalChart data={series} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Chat />
    </div>
  );
}

export default Tr069Dashboard;
