import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Breadcrumb from "../../../Breadcrumb";

function Layout({ children, heading }) {
  const location = useLocation();

  // Define labels for breadcrumb paths
  const breadcrumbLabels = {
    "": "Home",
    "device-listing": "Device Management",
    "device-detail": "Device Info",
    "device-snmp": "SNMP Device",
    "topology": "Device Topology",
    "OTAManagement": "OTA Management",
    "alarms": "Alerts",
    "system-info": "System Information",
    "user-management": "User Management",
    "audits": "Audits",
    // Add other route fragments as needed
  };

  // Generate breadcrumbs by splitting the path
  const pathnames = location.pathname.split("/").filter((x) => x);

  const breadcrumbs = [
    { label: breadcrumbLabels[""], path: "/" }, // Add "Home" as the first breadcrumb
    ...pathnames.map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      return {
        label: breadcrumbLabels[value] || value, // Use label or fallback to value
        path: to,
      };
    }),
  ];

  return (
    <div className="app-layout">
      <div id="layout-wrapper">
        <Header heading={heading} />
        <Sidebar />
        <main className="body-container">
          {/* Render the Breadcrumb component */}
          {breadcrumbs.length > 1 && (
            <Breadcrumb breadcrumbs={breadcrumbs} />
          )}
          {children}
          <div className="copyright-text">
            INC &copy; 2025, VVDN All rights reserved
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
