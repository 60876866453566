import React, { useState, useEffect } from "react";
import deviceone from "../../../assets/images/r1.png";
import devicetwo from "../../../assets/images/r2.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";
import allApi from "../../../api/allApi";
import errorIcon from "../../../assets/images/error-2.png";
import ap from "../../../assets/images/ap-2.png";
import switchImg from "../../../assets/images/switch.png";
import { all } from "axios";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Loader from "../common/Loader";
import Chat from "../common/chat";

function HTTPS() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [models, setModels] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceMonitoringInterval, setDeviceMonitoringInterval] = useState("");
  const [deviceHealthInterval, setDeviceHealthInterval] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelsPerPage] = useState(10);
  const [deleteModel, setDeleteModel] = useState(false);
  const [modeName, setModeName] = useState([]);
  const [devType, setDevType] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [editData, setEditData] = useState({
    vendorName: "",
    modelName: "",
    deviceType: "",
    deviceMonitoringInterval: "",
    deviceHealthInterval: "",
    icon_image: null,
    icon_name: "",
  });

  const [icon_name, seticon_name] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const isDisabled = true;

  const [formData, setFormData] = useState({
    vendorName: "",
    modelName: "",
    deviceType: "",
    deviceMonitoringInterval: "",
    deviceHealthInterval: "",
    icon_image: null,
    icon_name: "",
    modelFamily: "",
    image: null,
  });
  const [fileErrors, setFileErrors] = useState({
    deviceImage: "",
    vendorImage: "",
  });
  const [modelName, setModelName] = useState("");
  const handleAddModelClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (editData && editModel) {
      setFormData({
        vendorName: editData.vendorName || "NETGEAR",
        modelName: editData.modelName || "",
        deviceType: editData.deviceType || "",
        deviceMonitoringInterval: editData.deviceMonitoringInterval || "",
        deviceHealthInterval: editData.deviceHealthInterval || "",
        icon_name: editData.icon_name || null,
        icon_image: editData.icon_image || null,
        image: editData.image_path || "",
      });
    }
  }, [editData, editModel]);

  const fetchModels = async () => {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/model/allModels");

      const processedResponse = response.map((model) => {
        if (model.icon_image) {
          return {
            ...model,
            icon_image: `data:image/png;base64,${model.icon_image}`,
          };
        }
        return model;
      });

      setModels(processedResponse);
      setLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error("Error fetching models", error);
    }
  };
  const openDeleteModel = (models) => {
    setDeleteModel(true);
    setModeName(models.modelName);
    setDevType(models.deviceType);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputText = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateImage = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    const maxSize = 1 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      return "Choose Correct File Path";
    }

    if (file.size > maxSize) {
      return "The file should be less than 1 MB";
    }

    return "";
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  // const handleFileChange = async (e, field) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // const error = validateImage(file);
  //     // if (error) {
  //     //   setFileErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  //     //   return;
  //     // }

  //     const { width, height } = await getImageDimensions(file);
  //     // if ((width !== 16 || height !== 16) && (width > 32 || height > 32)) {
  //     //   setFileErrors((prevErrors) => ({
  //     //     ...prevErrors,
  //     //     [field]: "Image dimensions should be either 16x16 or up to 32x32.",
  //     //   }));
  //     //   return;
  //     // }

  //     setFileErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  //     setFormData((prevData) => ({ ...prevData, [field]: file }));
  //   }
  // };

  const getModelOptions = () => {
    switch (formData.deviceType) {
      case "AP":
        return [
          { value: "WAX650", label: "WAX650" },
          { value: "WAX650E", label: "WAX650E" },
        ];
      case "SW":
        return [
          { value: "GXCP", label: "GXCP" },
          { value: "GC320E", label: "GC320E" },
        ];
      case "ROUTER":
        return [
          { value: "BR500", label: "BR500" },
          { value: "PR60", label: "PR60" },
        ];
      default:
        return [];
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFormats = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 2 * 1024 * 1024; // 2 MB
  
      // Check file type
      if (!validFormats.includes(file.type)) {
        setFileErrors((prevErrors) => ({
          ...prevErrors,
          deviceImage: "Only JPG, JPEG, and PNG formats are allowed.",
        }));
        return;
      }
  
      // Check file size
      if (file.size > maxSize) {
        setFileErrors((prevErrors) => ({
          ...prevErrors,
          deviceImage: "File size should be less than 2 MB.",
        }));
        return;
      }
  
      // Check resolution
      const img = new Image();
      img.onload = () => {
        if (img.width > 1200 || img.height > 1200) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            deviceImage: "Resolution should not exceed 1200 x 1200.",
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            icon_image: file,
            icon_name: file.name,
          }));
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            deviceImage: "",
          }));
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };
  

  const validateFields = (deviceMonitoringInterval, deviceHealthInterval) => {
    const regex = /^[0-9]*$/;
    return (
      regex.test(deviceMonitoringInterval) && regex.test(deviceHealthInterval)
    );
  };

  const handleAddModal = async () => {
    try {
      if (
        !validateFields(
          formData.deviceMonitoringInterval,
          formData.deviceHealthInterval
        )
      ) {
        setErrorMessage("Monitoring input fields only allows integer values");
        return;
      }else {
        setErrorMessage("");
      }

      const api = new allApi();
      const data = new FormData();

      data.append("modelName", formData.modelName);
      data.append("deviceType", formData.deviceType);
      data.append(
        "deviceMonitoringInterval",
        formData.deviceMonitoringInterval
      );
      data.append("deviceHealthInterval", formData.deviceHealthInterval);

      if (formData.icon_image) {
        data.append("icon_image", formData.icon_image);
        data.append("icon_name", formData.icon_name);
      }
      if (formData.deviceType == "AP") {
        data.append("modelFamily", formData.modelFamily);
      }

      console.log("Payload before sending:", {
        modelName: formData.modelName,
        deviceType: formData.deviceType,
        deviceMonitoringInterval: formData.deviceMonitoringInterval,
        deviceHealthInterval: formData.deviceHealthInterval,
        icon_image: formData.icon_image ? "Image selected" : "No image",
      });

      const response = await api.postMultiRequest(
        "api/model/addHttpModel",
        data
      );
      if (response) {
        setModels((prevModels) => [...prevModels, response]);
        setIsModalOpen(false);
        setShowSuccessModal(true);
        setFormData({
          vendorName: "",
          modelName: "",
          deviceType: "",
          deviceMonitoringInterval: "",
          deviceHealthInterval: "",
          icon_image: null,
          icon_name: "",
          errorMessage: "",
        });
      }
    } catch (error) {
      console.error("Error adding model", error);
    }
  };
  const handleDeleteModel = async (modelName, deviceType) => {
    try {
      const api = new allApi();
      const payload = { modelName, deviceType };
      await api.deleteRequest("api/model/deleteHttpModel", payload);

      // Update the models list after deletion
      setModels((prevModels) =>
        prevModels.filter((model) => model.modelName !== modelName)
      );
      setDeleteModel(false);
      setShowDeleteSuccessModal(true);
      //alert(`${modelName} is deleted successfully.`);
    } catch (error) {
      console.error("Error deleting model", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setErrorMessage("");
    setFormData({
      vendorName: "",
      modelName: "",
      deviceType: "",
      deviceMonitoringInterval: "",
      deviceHealthInterval: "",
      icon_image: null,
      icon_name: "",
      modelFamily: "",
      image: null,
    });
    // fetchModels();
  };

  const handleEditData = async (deviceType, modelName) => {
    try {
      console.log("Fetching data for:", { deviceType, modelName });
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/getHttpModel?deviceType=${deviceType}&modelName=${modelName}`
      );
      console.log("API Response: ", response);
      if (response) {
        setEditData(response); // Ensures editData is populated
        setFormData(response); // Sets formData for the current form
        setEditModel(true);
      } else {
        console.log("No data returned from API.");
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const handleClose = () => {
    setEditModel(false);
    setErrorMessage("");
  };

  const updateModel = async () => {
    try {
      if (
        !validateFields(
          formData.deviceMonitoringInterval,
          formData.deviceHealthInterval
        )
      ) {
        setErrorMessage("Monitoring input fields only allows integer values");
        return;
      }else {
        setErrorMessage("");
      }
      const api = new allApi();
      const data = new FormData();

      // Use a fallback if editData is undefined
      const previousData = editData || {};

      // Always append basic form data
      data.append("modelName", formData.modelName);
      data.append("deviceType", formData.deviceType === "Switch" ? "SW" : "AP");
      data.append(
        "deviceMonitoringInterval",
        formData.deviceMonitoringInterval
      );
      data.append("deviceHealthInterval", formData.deviceHealthInterval);

      // Append icon_image and icon_name only if they are changed
      if (
        formData.icon_image &&
        (!previousData.icon_image ||
          formData.icon_image !== previousData.icon_image)
      ) {
        data.append("icon_image", formData.icon_image);
      }
      if (
        formData.icon_name &&
        (!previousData.icon_name ||
          formData.icon_name !== previousData.icon_name)
      ) {
        data.append("icon_name", formData.icon_name);
      }

      console.log("Payload :", {
        modelName: formData.modelName,
        deviceType: formData.deviceType,
        deviceMonitoringInterval: formData.deviceMonitoringInterval,
        deviceHealthInterval: formData.deviceHealthInterval,
        icon_image:
          formData.icon_image && formData.icon_image !== previousData.icon_image
            ? "Image selected"
            : "No image or unchanged",
        icon_name:
          formData.icon_name && formData.icon_name !== previousData.icon_name
            ? formData.icon_name
            : "Unchanged",
      });

      // Send the API request
      const response = await api.putMultiRequest(
        `api/model/updateHttpModel`,
        data
      );
      if (response) {
        setFormData(response);
        setErrorMessage("");
        handleClose();
        setShowUpdateSuccessModal(true);
      }
    } catch (error) {
      console.error("Failed to update Model", error);
    }
  };

  const indexOfLastModel = currentPage * modelsPerPage;
  const indexOfFirstModel = indexOfLastModel - modelsPerPage;
  const totalFilteredModel = models.length;
  const currentModels = models.slice(indexOfFirstModel, indexOfLastModel);

  const totalPages = Math.ceil(totalFilteredModel / modelsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleFirstPage = () =>{
    setCurrentPage(1);
  }
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const disableButton = !(
    formData.deviceMonitoringInterval &&
    formData.deviceHealthInterval &&
    formData.vendorName &&
    formData.deviceType &&
    formData.modelName &&
    formData.icon_image &&
    !fileErrors.deviceImage
  );

  

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>HTTPS Management</h3>
            <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                onClick={handleAddModelClick}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add
              </button>

              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => fetchModels()}
              >
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>
              {/* <button className="icon-btn" title="Delete">
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="inc-card-table">
                <table className="full-table m-head device-pic">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Model Image</th>
                      <th>Model Name</th>
                      <th>Device Type</th>
                      <th>Communication protocol</th>
                      <th>Monitoring interval</th>
                      <th>Health interval</th>
                      <th>Capabilities</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentModels.map((model) => (
                      <tr key={model.id}>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>
                          <img src={model.icon_image} alt="Device" />
                        </td>
                        <td>{model.modelName || "N/A"}</td>
                        <td>{model.deviceType || "N/A"}</td>
                        <td>{model.deviceCommunicationProtocol || "N/A"}</td>
                        <td>{model.deviceMonitoringInterval || "N/A"}</td>
                        <td>{model.deviceHealthInterval || "N/A"}</td>
                        <td>{model.capabilities || "N/A"}</td>
                        <td>
                          <div className="button-group">
                            <button
                              title="Delete"
                              // data-bs-toggle="modal"
                              // data-bs-target="#deleteModel"
                              onClick={() => openDeleteModel(model)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <button title="Edit">
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                                onClick={() => {
                                  handleEditData(
                                    model.deviceType,
                                    model.modelName
                                  );
                                }}
                              ></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {isModalOpen && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Model</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCancel}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="vendorName"
                              className="col-form-label"
                            >
                              Vendor Name <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="vendorName"
                              value={formData.vendorName}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Vendor</option>
                              <option value="NETGEAR">NETGEAR</option>
                              <option value="CISCO">CISCO</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceType"
                              className="col-form-label"
                            >
                              Device Type <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="deviceType"
                              value={formData.deviceType}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Device Type</option>
                              <option value="AP">AP</option>
                              <option value="SW">Switch</option>
                              <option value="ROUTER">Router</option>
                            </select>
                          </div>
                          {formData.deviceType == "AP" && (
                            <div className="col-lg-6">
                              <label
                                htmlFor="modelName"
                                className="col-form-label"
                              >
                                Model Family{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select"
                                name="modelFamily"
                                value={formData.modelFamily}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Family</option>
                                {getModelOptions().map((model) => (
                                  <option key={model.value} value={model.value}>
                                    {model.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div className="col-lg-6">
                            <label
                              htmlFor="modelName"
                              className="col-form-label"
                            >
                              Model Name <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="modelName"
                              value={formData.modelName}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Model</option>
                              {getModelOptions().map((model) => (
                                <option key={model.value} value={model.value}>
                                  {model.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceMonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval (in Seconds){" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              // id="deviceMonitoringInterval"
                              name="deviceMonitoringInterval"
                              value={formData.deviceMonitoringInterval}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceHealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval (in Seconds){" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              // id="deviceHealthInterval"
                              name="deviceHealthInterval"
                              value={formData.deviceHealthInterval}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceImage"
                              className="col-form-label"
                            >
                              Device Image{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="deviceImage"
                              onChange={handleImageChange}
                            />
                            {fileErrors.deviceImage && (
                              <div className="text-danger">
                                {fileErrors.deviceImage}
                              </div>
                            )}
                          </div>
                        </div>
                        {errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        )}
                        <div className="border-line"></div>

                        <h4 className="mt-2">Capabilities</h4>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="port" className="col-form-label">
                              Supported Port
                            </label>
                            <select
                              className="form-select"
                              name="port"
                              placeholder="port"
                            >
                              <option>8</option>
                              <option>12</option>
                              <option>16</option>
                              <option>32</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="ClientSupport"
                              className="col-form-label"
                            >
                              MAX Client Support
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ClientSupport"
                              placeholder="ClientSupport"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>&nbsp;</label>
                            <div className="switch-box">
                              <strong>Radio Enabled</strong>
                              <label className="ice-switch">
                                <input type="checkbox" name="Radio" />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-btn primary-btn"
                      disabled={disableButton}
                      onClick={handleAddModal}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {editModel && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Model</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="vendorName"
                              className="col-form-label"
                            >
                              Vendor Name
                            </label>
                            <input
                              type="text"
                              disabled={isDisabled}
                              style={{
                                cursor: isDisabled ? "not-allowed" : "default",
                              }}
                              className="form-control"
                              id="vendorName"
                              name="vendorName"
                              value={formData.vendorName || "NETGEAR"}
                              readOnly
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceType"
                              className="col-form-label"
                            >
                              Device Type
                            </label>
                            <input
                              type="text"
                              disabled={isDisabled}
                              style={{
                                cursor: isDisabled ? "not-allowed" : "default",
                              }}
                              className="form-control"
                              id="deviceType"
                              name="deviceType"
                              value={formData.deviceType || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="modelName"
                              className="col-form-label"
                            >
                              Model Name
                            </label>
                            <input
                              type="text"
                              disabled={isDisabled}
                              style={{
                                cursor: isDisabled ? "not-allowed" : "default",
                              }}
                              className="form-control"
                              id="modelName"
                              name="modelName"
                              value={formData.modelName || ""}
                              readOnly
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceMonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval (in Seconds)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="deviceMonitoringInterval"
                              name="deviceMonitoringInterval"
                              value={formData.deviceMonitoringInterval || ""}
                              onChange={handleInputText}
                            />
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceHealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval (in Seconds)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="deviceHealthInterval"
                              name="deviceHealthInterval"
                              value={formData.deviceHealthInterval || ""}
                              onChange={handleInputText}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceImage"
                              className="col-form-label"
                            >
                              Device Image
                            </label>
                            <div>{formData.icon_name || "No icon name"}</div>
                            {/* {formData.icon_image && (
                              <div className="image-preview mb-2">
                                <img
                                  src={`data:image/png;base64,${formData.icon_image}`}
                                  alt={formData.icon_name || "Device Icon"}
                                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                                />
                                <div>{formData.icon_name || "No icon name"}</div>
                              </div>
                            )} */}
                            <input
                              type="file"
                              className="form-control"
                              name="icon_image"
                              onChange={handleImageChange}
                            />
                            {fileErrors.deviceImage && (
                              <div className="text-danger">
                                {fileErrors.deviceImage}
                              </div>
                            )}
                          </div>
                          {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-btn primary-btn"
                      onClick={updateModel}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="pagination-container">
            {totalFilteredModel > 0 ? (
              <>
                <div className="pagination-info">
                  <p>
                    Showing {indexOfFirstModel + 1}
                    {"-"}
                    {Math.min(
                      currentPage * modelsPerPage,
                      totalFilteredModel
                    )}{" "}
                    of {totalFilteredModel} records
                  </p>
                </div>
                <div className="pagination">
                <button
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                  >
                    First Page
                  </button>
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  {getPageNumbers().map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageClick(page)}
                      className={currentPage === page ? "active" : ""}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <button
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                  >
                    Last Page
                  </button>
                </div>
              </>
            ) : (
              <div>
                <p>No Records Available</p>
              </div>
            )}
          </div>
        </div>
        <Chat />
      </div>
      {/* <div
        className={`modal fade`}
        id="deleteModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure to delete {modeName} Model?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteModel(modeName,devType)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {deleteModel && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <h3>Are you sure you want to delete?</h3>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline"
                    onClick={() => setDeleteModel(false)}
                  >
                    No
                  </button>
                  <button
                    className="text-btn primary-btn"
                    onClick={() => handleDeleteModel(modeName, devType)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Model added successfully</p>
        <Button
          onClick={() => {
            setShowSuccessModal(false);
            fetchModels();
          }}
        >
          OK
        </Button>
      </Modal>
      <Modal
        show={showDeleteSuccessModal}
        onHide={() => setShowDeleteSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Model deleted successfully</p>
        <Button
          onClick={() => {
            setShowDeleteSuccessModal(false);
            fetchModels();
          }}
        >
          OK
        </Button>
      </Modal>
      <Modal
        show={showUpdateSuccessModal}
        onHide={() => setShowUpdateSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Model updated successfully</p>
        <Button
          onClick={() => {
            setShowUpdateSuccessModal(false);
            fetchModels();
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default HTTPS;
