import React from 'react';
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useEffect, useState } from "react";

function ConnectedClientExpandation() {
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connectedClientsDuration, setConnectedClientsDuration] = useState("24h");

  useEffect(() => {
    getConnectedClientPerSSID(connectedClientsDuration);
  }, [connectedClientsDuration]);

  const getConnectedClientPerSSID = async (connectedClientsDuration) => {
    try {
        setLoading(true);
        const api = new allApi();

        // Fetch data with the specified duration
        const response = await api.getRequest(
            `api/wireless/connectedClientsPerSsid?commandType=2&duration=${connectedClientsDuration}&networkId=6001`
        );

        if (response.status === "OK") {
            const data = response.response;

            const seriesData = data.map((item) => ({
                name: item.ssidName,
                data: item.data.map((client) => {
                    // Parse timestamp based on `interval1`
                    const date = new Date(client.interval1);

                    // Round time based on the duration
                    if (connectedClientsDuration === "8h" || connectedClientsDuration === "24h") {
                        date.setMinutes(0, 0, 0); // Round to the start of the hour
                    } else if (connectedClientsDuration === "7d" || connectedClientsDuration === "30d") {
                        date.setHours(0, 0, 0, 0); // Round to the start of the day
                    }

                    return {
                        x: date.getTime(),
                        y: client.totalClientSum,
                    };
                }),
            }));

            // Extract unique rounded timestamps for the x-axis categories
            const timeStamps = [...new Set(seriesData.flatMap(series => series.data.map(point => point.x)))];

            setConnectedClientCategories(timeStamps);
            setConnectedClientSeries(seriesData);
        } else {
            console.error("Failed to fetch data:", response.message);
        }
    } catch (error) {
        console.error("Error fetching connected client data:", error);
    } finally {
        setLoading(false);
    }
};

const connectedClientsOptions = {
    chart: {
        type: "line",
        height: 350,
        toolbar: { show: false },
    },
    xaxis: {
        type: "datetime",
        categories: ConnectedClientCategories,
        labels: {
            formatter: (value) => {
                const date = new Date(value);

                // Format based on the duration selected
                if (connectedClientsDuration === "7d" || connectedClientsDuration === "30d") {
                    return date.toLocaleDateString("en-IN", { day: "2-digit", month: "short" }); // DD MMM format
                } else {
                    return date.toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    });
                }
            },
            style: {
                colors: "#9aa0ac",
                fontSize: "12px",
            },
        },
        axisTicks:{
          show: false,
        },
    },
    yaxis: {
        min: 0,
        title: { text: "Client Count" },
        labels: { formatter: (val) => val.toFixed(0) },
    },
    stroke: { width: 1 },
    dataLabels: { enabled: false },
    legend: {
        position: "bottom",
        horizontalAlign: "center",
        showForSingleSeries: true,
        labels: {
            colors: "#9aa0ac",
            fontSize: "12px",
        },
    },
    zoom: { enabled: false },
};




  const handleConnectedClientsDurationChange = (newDuration) => {
    setConnectedClientsDuration(newDuration);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Connected Clients per SSID</h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span className="dropdown" data-bs-toggle="dropdown">
                  <span>
                    {connectedClientsDuration === "24h" ? "Last 24 Hrs" : connectedClientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" onClick={() => handleConnectedClientsDurationChange("8h")}>
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => handleConnectedClientsDurationChange("24h")}>
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => handleConnectedClientsDurationChange("7d")}>
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => handleConnectedClientsDurationChange("30d")}>
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <Chart options={connectedClientsOptions} series={ConnectedClientSeries} type="line" height={350} />
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>SSID Name</th>
                          <th>Client Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ConnectedClientSeries.flatMap((seriesItem) =>
                          seriesItem.data.map((dataItem, index) => (
                            <tr key={index}>
                              <td>{seriesItem.name}</td>
                              <td>{dataItem.y}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectedClientExpandation;
