import React, { useState, useEffect } from "react";
import Chat from "../common/chat";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import helpIcon from "../../../assets/images/help.svg";
import { all } from "axios";
import { Tooltip } from "react-tooltip";
import Loader from "../common/Loader";

function DeviceSettings() {
  const location = useLocation();
  const { deviceId } = location.state || {};
  //const [mode, setMode] = useState("Master");
  //const [linkType, setLinkType] = useState("P2P");
  const [link, setLink] = useState([]);
  const [noOfSlaves, setNoOfSlaves] = useState(1);
  const [loading, setLoading] = useState(false);
  const [backhaulLoading, setBackHaulLoading] = useState(false);
  const [atpcLoading, setAtpcLoading] = useState(false);
  const [ubrLoading, setUbrLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLinkPassword, setShowLinkPassword] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showAtpcSuccessPopup, setShowAtpcSuccessPopup] = useState(false);
  const [showPasswordSuccessPopup, setShowPasswordSuccessPopup] =
    useState(false);
  const [showEventSuccessPopup, setShowEventSuccessPopup] = useState(false);
  const [showBackhaulSuccessPopup, setShowBackhaulSuccessPopup] =
    useState(false);
  const [showUbrSettingsSuccessPopup, setShowUbrSettingsSuccessPopup] =
    useState(false);
  const [eventId, setEventId] = useState({});
  const [timezoneList, setTimezoneList] = useState([]);
  const [backhaulConfig, setBackhaulConfig] = useState({
    channelBandWidth: "",
    dfsEnable: "",
    radio5_channelSelection: "",
    tx_power: "",
  });
  const [backHaulData, setBackHaulData] = useState({
    channelBandWidth: "",
    dfsEnable: "",
    radio5_channelSelection: "",
    tx_power: "",
  });
  const [generalData, setGeneralData] = useState({
    deviceName: "",
    model: "",
    deviceDateTime: "",
    timeZone: "",
  });
  const [atpcData, setAtpcData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [eventData, setEventData] = useState({
    eventId: eventId || 0,
    cpuOverload: 1,
    cpuOverloadClear: 0,
    memoryOverload: 1,
    memoryOverloadClear: 0,
    gpsLocked: 1,
    gpsNotLocked: 0,
    systemRebootTrap: 1,
    temperatureThresholdReached: 1,
    temperatureThresholdReachedClear: 0,
    ethernetSpeedChanged: 1,
    ethernetDuplexChanged: 0,
    linkUp: 1,
    linkDown: 0,
    radioUp: 1,
    radioDown: 0,
    ethernetUp: 1,
    ethernetDown: 0,
    linkPasswordChange: 1,
    chainDisconnect: 1,
    chainDisconnectClear: 0,
    ethernetErrorOverload: 1,
    ethernetErrorOverloadClear: 0,
    wirelessErrorOverload: 1,
    wirelessErrorOverloadClear: 0,
    firmwareUpgradeSuccess: 1,
    firmwareUpgradeFail: 0,
    cpuThreshold: 1,
    memoryThreshold: 0,
    temperatureThreshold: 1,
    ethernetErrorThreshold: 0,
    wirelessErrorThreshold: 1,
  });
  const [ubrData, setUbrData] = useState({
    mode: "",
    linkType: "",
    slaveCount: 1,
    ssidName: "",
    linkSecurity: "",
    linkPassword: "",
  });

  useEffect(() => {
    if (deviceId) {
      getGeneralConfig(deviceId);
      getBackHaul(deviceId);
      getATPC(deviceId);
      getDevicePassword(deviceId);
      getEvents(deviceId);
      getTimezone();
      getLinkSecurity();
      getUbrSettings(deviceId);
    }
  }, [deviceId]);

  const getBackHaul = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getBackHaulRadio?deviceId=${deviceId}`
      );

      if (response.status === "OK" && response.response) {
        setBackhaulConfig(response.response);
        setBackHaulData(response.response);
      }
    } catch (error) {
      console.error("Failed to fetch Backhaul data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBackHaulData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addBackHaul = async () => {
    setBackHaulLoading(true);
    try {
      const api = new allApi();
      const payload = {
        deviceId: deviceId,
        channelBandWidth: parseInt(backHaulData.channelBandWidth, 10),
        dfsEnable: backHaulData.dfsEnable,
        radio5_channelSelection: parseInt(
          backHaulData.radio5_channelSelection,
          10
        ),
        tx_power: backHaulData.tx_power,
      };
      const response = await api.postRequest(
        "api/snmp/snmpBackhaulRadio",
        "",
        payload
      );
      if (response.status === "OK") {
        setShowBackhaulSuccessPopup(true);
        await getBackHaul(deviceId);
      }
    } catch (error) {
      console.log("Error fetching backhaul data", error);
    } finally {
      setBackHaulLoading(false);
    }
  };

  const handleBackhaulCancelPopup = () => {
    setShowBackhaulSuccessPopup(false);
  };

  // const handleModeChange = (e) => {
  //   setMode(e.target.value);
  //   setLinkType("P2P");
  // };

  // const handleLinkTypeChange = (e) => {
  //   setLinkType(e.target.value);
  // };

  const handleNoOfSlavesChange = (e) => {
    setNoOfSlaves(parseInt(e.target.value, 10));
  };

  const channelOptions = [];
  for (let i = 38; i <= 130; i++) {
    channelOptions.push(`${i} GHz`);
  }

  const getGeneralConfig = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getSnmpGeneral?deviceId=${deviceId}`
      );
      if (response && response.response) {
        const data = response.response;
        setGeneralData({
          deviceName: data.deviceName,
          model: data.model,
          deviceDateTime: new Date(data.deviceDateTime * 1000).toLocaleString(),
          timeZone: data.timeZone,
        });
      }
    } catch (error) {
      console.log("Error fetching config", error);
    }
  };

  const postGeneralConfig = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const epochDeviceDateTime = Math.floor(
        new Date(generalData.deviceDateTime).getTime() / 1000
      );
      const payload = {
        deviceId: deviceId,
        deviceName: generalData.deviceName,
        //model: generalData.model,
        //deviceDateTime: epochDeviceDateTime,
        timeZone: generalData.timeZone,
      };
      const response = await api.postRequest(
        "api/snmp/snmpGeneral",
        "",
        payload
      );
      if (response) {
        console.log("data changed successfully");

        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.log("Error in storing data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
  };

  const getATPC = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getAtpcConfig?deviceId=${deviceId}`
      );
      if (response && response.response) {
        const data = response.response;
        setAtpcData(data);
      }
    } catch (error) {
      console.log("Error fetching atpc info", error);
    }
  };
  const postATPC = async () => {
    setAtpcLoading(true);
    try {
      const api = new allApi();
      const payload = {
        deviceId: deviceId,
        atpcEnable: atpcData.atpcEnable,
        maxTxPower: parseInt(atpcData.maxTxPower, 10),
        minTxPower: parseInt(atpcData.minTxPower, 10),
        maxRssi: parseInt(atpcData.maxRssi, 10),
        minRssi: parseInt(atpcData.minRssi, 10),
      };
      const response = await api.postRequest(
        "api/snmp/atpcConfig",
        "",
        payload
      );
      if (response && response.response) {
        console.log("ATPC data changes successfully", response.response);

        setShowAtpcSuccessPopup(true);
      }
    } catch (error) {
      console.log("Error in changing atpc data", error);
    } finally {
      setAtpcLoading(false);
    }
  };

  const handleAtpcCancelPopup = () => {
    setShowAtpcSuccessPopup(false);
  };

  const handleAtpcToggle = () => {
    setAtpcData((prev) => ({
      ...prev,
      atpcEnable: prev.atpcEnable === 1 ? 2 : 1,
    }));
  };

  const getDevicePassword = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getDevicePassword?deviceId=${deviceId}`
      );
      if (response && response.response) {
        const data = response.response;
        setPasswordData(data);
      }
    } catch (error) {
      console.error("Error fetching password", error);
    }
  };

  const postDevicePassword = async () => {
    setErrorMessage("");
    if (passwordData.password !== passwordData.confirmPassword) {
      setErrorMessage(
        "The passwords you entered do not match. Please make sure both fields contain the same password and try again."
      );
      return;
    }
    setPasswordLoading(true);
    try {
      const api = new allApi();
      const payload = {
        deviceId: deviceId,
        password: passwordData.password,
        confirmPassword: passwordData.confirmPassword,
      };
      const response = await api.postRequest(
        "api/snmp/devicePassword",
        "",
        payload
      );
      if (response && response.response) {
        console.log("Password changed successfully", response.response);

        setShowPasswordSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error setting password", error);
    } finally {
      setPasswordLoading(false);
    }
  };

  const handlePasswordCancelPopup = () => {
    setShowPasswordSuccessPopup(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  const toggleLinkPassword = () => {
    setShowLinkPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const handlePassowrdCancel = () => {
    setErrorMessage("");
  };

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const handlePassword = (e) => {
    const value = e.target.value;
    setPasswordData({
      ...passwordData,
      password: value,
    });
    if (value && !isValidPassword(value)) {
      setPasswordError(
        "Password must be 8-20 characters long and include uppercase and lowercase letters, a number, and a special character.."
      );
    } else {
      setPasswordError("");
    }
  };

  const getEvents = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getSnmpEvents?deviceId=${deviceId}`
      );
      if (response && response.response) {
        const data = response.response;
        setEventData(data);
        setEventId(data.eventId);
      }
    } catch (error) {
      console.error("Error fetching events", error);
    }
  };

  const postEvents = async () => {
    setEventsLoading(true);
    try {
      const api = new allApi();
      const payload = {
        deviceId: deviceId,
        eventId: eventId || 0,
        cpuOverload: eventData.cpuOverload,
        cpuOverloadClear: eventData.cpuOverloadClear,
        memoryOverload: eventData.memoryOverload,
        memoryOverloadClear: eventData.memoryOverloadClear,
        gpsLocked: eventData.gpsLocked,
        gpsNotLocked: eventData.gpsNotLocked,
        systemRebootTrap: eventData.systemRebootTrap,
        temperatureThresholdReached: eventData.temperatureThresholdReached,
        temperatureThresholdReachedClear:
          eventData.temperatureThresholdReachedClear,
        ethernetSpeedChanged: eventData.ethernetSpeedChanged,
        ethernetDuplexChanged: eventData.ethernetDuplexChanged,
        linkUp: eventData.linkUp,
        linkDown: eventData.linkDown,
        radioUp: eventData.radioUp,
        radioDown: eventData.radioDown,
        ethernetUp: eventData.ethernetUp,
        ethernetDown: eventData.ethernetDown,
        linkPasswordChange: eventData.linkPasswordChange,
        chainDisconnect: eventData.chainDisconnect,
        chainDisconnectClear: eventData.chainDisconnectClear,
        ethernetErrorOverload: eventData.ethernetErrorOverload,
        ethernetErrorOverloadClear: eventData.ethernetErrorOverloadClear,
        wirelessErrorOverload: eventData.wirelessErrorOverload,
        wirelessErrorOverloadClear: eventData.wirelessErrorOverloadClear,
        firmwareUpgradeSuccess: eventData.firmwareUpgradeSuccess,
        firmwareUpgradeFail: eventData.firmwareUpgradeFail,
        cpuThreshold: eventData.cpuThreshold,
        memoryThreshold: eventData.memoryThreshold,
        temperatureThreshold: eventData.temperatureThreshold,
        ethernetErrorThreshold: eventData.ethernetErrorThreshold,
        wirelessErrorThreshold: eventData.wirelessErrorThreshold,
      };
      const response = await api.postRequest(
        "api/snmp/snmpEvents",
        "",
        payload
      );
      if (response && response.response) {
        console.log("Events updated successfully");

        setShowEventSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error updating events", error);
    } finally {
      setEventsLoading(false);
    }
  };

  const handleEventCancelPopup = () => {
    setShowEventSuccessPopup(false);
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setEventData((prev) => ({
      ...prev,
      [name]: checked ? 1 : 2,
    }));
  };

  const handleEventInputChange = (event) => {
    const { name, value } = event.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getTimezone = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/timeZone");
      if (response && response.response) {
        const data = response.response;
        setTimezoneList(data);
      }
    } catch (error) {
      console.error("Error fetching timezone", error);
    }
  };

  const getUbrSettings = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/snmp/getUbrSetting?deviceId=${deviceId}`
      );
      if (response && response.response) {
        const data = response.response;
        setUbrData(data);
      }
    } catch (error) {
      console.error("Error fetching ubr settings");
    }
  };

  const getLinkSecurity = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/getLinkSecurity");
      if (response && response.response) {
        const data = response.response;
        setLink(data);
      }
    } catch (error) {
      console.error("Error fetching link security");
    }
  };

  const postUbrSettings = async () => {
    setUbrLoading(true);
    try {
      const api = new allApi();
      const payload = {
        deviceId: deviceId,
        mode: ubrData.mode,
        linkType: ubrData.linkType,
        slaveCount: ubrData.slaveCount,
        ssidName: ubrData.ssidName,
        linkSecurity: ubrData.linkSecurity,
        linkPassword: ubrData.linkPassword,
      };
      const response = await api.postRequest(
        "api/snmp/ubrSetting",
        "",
        payload
      );
      if (response && response.response) {
        console.log("Ubr settings changed successfully");

        setShowUbrSettingsSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error in updating ubr settings");
    } finally {
      setUbrLoading(false);
    }
  };

  const handleUbrSettingsCancelPopUp = () => {
    setShowUbrSettingsSuccessPopup(false);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Device Settings</h3>
          </div>
          <div className="inc-card-body">
            <div className="sideTab-container">
              <div className="inc-side-tab snmp-config-link">
                <ul className="nav-side w-scroll" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      id="pills-general-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-general"
                      type="button"
                      role="tab"
                      aria-controls="pills-general"
                      aria-selected="true"
                    >
                      <div>
                        General
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Device Identifiers</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-backhaul-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-backhaul"
                      type="button"
                      role="tab"
                      aria-controls="pills-backhaul"
                      aria-selected="true"
                    >
                      <div>
                        Backhaul Radio
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Internet Exchange Point</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-ubr-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-ubr"
                      type="button"
                      role="tab"
                      aria-controls="pills-ubr"
                      aria-selected="true"
                    >
                      <div>
                        UBR Link Settings
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Unlicensed Band Radio</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-atpc-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-atpc"
                      type="button"
                      role="tab"
                      aria-controls="pills-atpc"
                      aria-selected="true"
                    >
                      <div>
                        ATPC<i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Automatic Transmit Power Control</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-device-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-device"
                      type="button"
                      role="tab"
                      aria-controls="pills-device"
                      aria-selected="true"
                    >
                      <div>
                        Device Password
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Protects Your Device</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-events-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-events"
                      type="button"
                      role="tab"
                      aria-controls="pills-events"
                      aria-selected="true"
                    >
                      <div>
                        Events
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Entuity Incidents</span>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="inc-side-tabcontainer">
                <div
                  className="tab-form snmp-config-tab tab-content w-scroll"
                  id="pills-tabContent"
                >
                  {/* ==================FIRST TAB=========== */}

                  <div
                    className="tab-pane fade show active"
                    id="pills-general"
                    role="tabpanel"
                    aria-labelledby="pills-general-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Device Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="StarLoom"
                          value={generalData.deviceName}
                          onChange={(e) =>
                            setGeneralData({
                              ...generalData,
                              deviceName: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          Model
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="INC-SNMP457"
                          value={generalData.model}
                          // onChange={(e) =>
                          //   setGeneralData({
                          //     ...generalData,
                          //     model: e.target.value,
                          //   })
                          // }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Device DateTime
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tue, 2 Jul 2025, 10:54:36"
                          value={generalData.deviceDateTime}
                          // onChange={(e) =>
                          //   setGeneralData({
                          //     ...generalData,
                          //     deviceDateTime: e.target.value,
                          //   })
                          // }
                          disabled
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          TimeZone
                        </label>
                        <select
                          className="form-select"
                          value={generalData.timeZone}
                          onChange={(e) =>
                            setGeneralData({
                              ...generalData,
                              timeZone: e.target.value,
                            })
                          }
                        >
                          {timezoneList.map((timezone, index) => (
                            <option key={index} value={timezone}>
                              {timezone}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div class="wifi-footer">
                        <button type="button" class="btn btn-outline">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="text-btn primary-btn"
                          onClick={() => postGeneralConfig()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===FIRST TAB END=== */}

                  {/* ==================SECOND TAB=========== */}

                  <div
                    class="tab-pane fade"
                    id="pills-backhaul"
                    role="tabpanel"
                    aria-labelledby="pills-backhaul-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Channel Bandwidth
                        </label>
                        <select
                          className="form-select"
                          name="channelBandWidth"
                          value={backHaulData.channelBandWidth}
                          onChange={handleInputChange}
                        >
                          <option value={7}>10MHz</option>
                          <option value={1}>20MHz</option>
                          <option value={2}>40MHz</option>
                          <option value={4}>80MHz</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          DFS
                        </label>
                        <select
                          className="form-select"
                          name="dfsEnable"
                          value={backHaulData.dfsEnable}
                          onChange={handleInputChange}
                        >
                          <option value={1}>Enable</option>
                          <option value={2}>Disable</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Channel Selection
                        </label>
                        <select
                          className="form-select"
                          name="radio5_channelSelection"
                          value={backHaulData.radio5_channelSelection}
                          onChange={handleInputChange}
                        >
                          <option value={0}>Auto</option>
                          {[...Array(93)].map((_, i) => (
                            <option key={i} value={i + 38}>
                              {i + 38} GHz
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="subnetmask" className="col-form-label">
                          TX Power
                        </label>
                        <select
                          className="form-select"
                          name="tx_power"
                          value={backHaulData.tx_power}
                          onChange={handleInputChange}
                        >
                          {Array.from({ length: 27 }, (_, i) => i + 3).map(
                            (value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    {backhaulLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div class="wifi-footer">
                        <button type="button" class="btn btn-outline">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="text-btn primary-btn"
                          onClick={() => addBackHaul()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===SECOND TAB END=== */}

                  {/* ==================THIRD TAB=========== */}

                  <div
                    className="tab-pane fade"
                    id="pills-ubr"
                    role="tabpanel"
                    aria-labelledby="pills-ubr-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label htmlFor="mode" className="col-form-label">
                          Mode
                        </label>
                        <select
                          className="form-select"
                          id="mode"
                          onChange={(e) =>
                            setUbrData({
                              ...ubrData,
                              mode: e.target.value,
                            })
                          }
                          value={ubrData.mode}
                        >
                          <option value="Master">Master</option>
                          <option value="Slave">Slave</option>
                        </select>
                      </div>
                      {ubrData.mode === "Master" && (
                        <div className="col-lg-4">
                          <label htmlFor="linkType" className="col-form-label">
                            Link Type
                          </label>
                          <select
                            className="form-select"
                            id="linkType"
                            onChange={(e) =>
                              setUbrData({
                                ...ubrData,
                                linkType: e.target.value,
                              })
                            }
                            value={ubrData.linkType}
                          >
                            <option value="p2p">P2P</option>
                            <option value="p2mp">P2MP</option>
                          </select>
                        </div>
                      )}
                      {ubrData.mode === "Slave" && (
                        <div className="col-lg-4">
                          <label htmlFor="ssidName" className="col-form-label">
                            SSID Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="ssidName"
                            placeholder="SSID Name"
                            value={ubrData.ssidName}
                            onChange={(e) =>
                              setUbrData({
                                ...ubrData,
                                ssidName: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>

                    {ubrData.mode === "Master" && (
                      <>
                        {ubrData.linkType === "p2mp" && (
                          <>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor="noOfSlaves"
                                  className="col-form-label"
                                >
                                  No. Of Slaves
                                </label>
                                <select
                                  className="form-select"
                                  id="noOfSlaves"
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      slaveCount: e.target.value,
                                    })
                                  }
                                  value={ubrData.slaveCount}
                                >
                                  {[...Array(16).keys()].map((_, index) => (
                                    <option key={index} value={index + 1}>
                                      {index + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-lg-4">
                                <label
                                  htmlFor={"ssidName"}
                                  className="col-form-label"
                                >
                                  SSID Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={"ssidName"}
                                  placeholder={"SSID Name"}
                                  value={ubrData.ssidName}
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      ssidName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkSecurity"
                                  className="col-form-label"
                                >
                                  Link Security
                                </label>
                                <select
                                  className="form-select"
                                  id="linkSecurity"
                                  value={ubrData.linkSecurity}
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      linkSecurity: e.target.value,
                                    })
                                  }
                                >
                                  {link.map((linkSecurity, index) => (
                                    <option key={index} value={linkSecurity}>
                                      {linkSecurity}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {ubrData.linkSecurity !== "open" && (
                                <div className="col-lg-4">
                                  <div className="visible-password">
                                    <label
                                      htmlFor="linkPassword"
                                      className="col-form-label"
                                    >
                                      Link Password
                                    </label>
                                    <input
                                      type={
                                        showLinkPassword ? "text" : "password"
                                      }
                                      className="form-control"
                                      id="linkPassword"
                                      placeholder="Password"
                                      value={ubrData.linkPassword}
                                      onChange={(e) =>
                                        setUbrData({
                                          ...ubrData,
                                          linkPassword: e.target.value,
                                        })
                                      }
                                    />
                                    <img
                                      src={
                                        showLinkPassword
                                          ? eyeIcon
                                          : eyeCloseIcon
                                      }
                                      alt={
                                        showLinkPassword ? "eye" : "closeeye"
                                      }
                                      className="show-password"
                                      onClick={toggleLinkPassword}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {ubrData.linkType === "p2p" && (
                          <>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor={"ssidName"}
                                  className="col-form-label"
                                >
                                  SSID Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={"ssidName"}
                                  placeholder={"SSID Name"}
                                  value={ubrData.ssidName}
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      ssidName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkSecurity"
                                  className="col-form-label"
                                >
                                  Link Security
                                </label>
                                <select
                                  className="form-select"
                                  id="linkSecurity"
                                  value={ubrData.linkSecurity}
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      linkSecurity: e.target.value,
                                    })
                                  }
                                >
                                  {link.map((linkSecurity, index) => (
                                    <option key={index} value={linkSecurity}>
                                      {linkSecurity}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {ubrData.linkSecurity !== "open" && (
                              <div className="col-lg-4">
                                <div className="visible-password">
                                  <label
                                    htmlFor="linkPassword"
                                    className="col-form-label"
                                  >
                                    Link Password
                                  </label>
                                  <input
                                    type={
                                      showLinkPassword ? "text" : "password"
                                    }
                                    className="form-control"
                                    id="linkPassword"
                                    placeholder="Password"
                                    value={ubrData.linkPassword}
                                    onChange={(e) =>
                                      setUbrData({
                                        ...ubrData,
                                        linkPassword: e.target.value,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      showLinkPassword ? eyeIcon : eyeCloseIcon
                                    }
                                    alt={showLinkPassword ? "eye" : "closeeye"}
                                    className="show-password"
                                    onClick={toggleLinkPassword}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {ubrData.mode === "Slave" && (
                      <>
                        <div className="row mb-3">
                          <div className="col-lg-4">
                            <label
                              htmlFor="linkSecurity"
                              className="col-form-label"
                            >
                              Link Security
                            </label>
                            <select
                              className="form-select"
                              id="linkSecurity"
                              value={ubrData.linkSecurity}
                              onChange={(e) =>
                                setUbrData({
                                  ...ubrData,
                                  linkSecurity: e.target.value,
                                })
                              }
                            >
                              {link.map((linkSecurity, index) => (
                                <option key={index} value={linkSecurity}>
                                  {linkSecurity}
                                </option>
                              ))}
                            </select>
                          </div>
                          {ubrData.linkSecurity !== "open" && (
                            <div className="col-lg-4">
                              <div className="visible-password">
                                <label
                                  htmlFor="linkPassword"
                                  className="col-form-label"
                                >
                                  Link Password
                                </label>
                                <input
                                  type={showLinkPassword ? "text" : "password"}
                                  className="form-control"
                                  id="linkPassword"
                                  placeholder="Password"
                                  value={ubrData.linkPassword}
                                  onChange={(e) =>
                                    setUbrData({
                                      ...ubrData,
                                      linkPassword: e.target.value,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    showLinkPassword ? eyeIcon : eyeCloseIcon
                                  }
                                  alt={showLinkPassword ? "eye" : "closeeye"}
                                  className="show-password"
                                  onClick={toggleLinkPassword}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {ubrLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div className="wifi-footer">
                        <button type="button" className="btn btn-outline">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="text-btn primary-btn"
                          onClick={() => postUbrSettings()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===THIRD TAB END=== */}

                  {/* ==================FOURTH TAB=========== */}

                  <div
                    class="tab-pane fade"
                    id="pills-atpc"
                    role="tabpanel"
                    aria-labelledby="pills-atpc-tab"
                  >
                    <div className="row mb-4">
                      <div class="col-lg-4">
                        <div class="switch-box">
                          <strong>ATPC Enable</strong>
                          <label class="ice-switch">
                            <input
                              type="checkbox"
                              checked={atpcData.atpcEnable === 1}
                              onChange={handleAtpcToggle}
                            />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Max tx Power
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="45"
                          value={atpcData.maxTxPower}
                          onChange={(e) =>
                            setAtpcData({
                              ...atpcData,
                              maxTxPower: e.target.value,
                            })
                          }
                          disabled={atpcData.atpcEnable === 2}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          Min tx Power
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="12"
                          value={atpcData.minTxPower}
                          onChange={(e) =>
                            setAtpcData({
                              ...atpcData,
                              minTxPower: e.target.value,
                            })
                          }
                          disabled={atpcData.atpcEnable === 2}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Max RSSI
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="15"
                          value={atpcData.maxRssi}
                          onChange={(e) =>
                            setAtpcData({
                              ...atpcData,
                              maxRssi: e.target.value,
                            })
                          }
                          disabled={atpcData.atpcEnable === 2}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Min RSSI
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="08"
                          value={atpcData.minRssi}
                          onChange={(e) =>
                            setAtpcData({
                              ...atpcData,
                              minRssi: e.target.value,
                            })
                          }
                          disabled={atpcData.atpcEnable === 2}
                        />
                      </div>
                    </div>
                    {atpcLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div class="wifi-footer">
                        <button type="button" class="btn btn-outline">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="text-btn primary-btn"
                          onClick={() => postATPC()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===FOURTH TAB END=== */}

                  {/* ==================FIFTH TAB=========== */}

                  <div
                    class="tab-pane fade"
                    id="pills-device"
                    role="tabpanel"
                    aria-labelledby="pills-device-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <div className="visible-password">
                          <label for="ipaddress" className="col-form-label">
                            Password
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            value={passwordData.password}
                            onChange={handlePassword}
                          />
                          <img
                            src={showPassword ? eyeIcon : eyeCloseIcon}
                            alt={showPassword ? "eye" : "closeeye"}
                            className="show-password"
                            onClick={togglePasswordVisibility}
                          />
                          {passwordError && (
                            <p className="text-danger">{passwordError}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="visible-password">
                          <label for="subnetmask" className="col-form-label">
                            Confirm Password
                          </label>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Confirm Password"
                            value={passwordData.confirmPassword}
                            onChange={(e) =>
                              setPasswordData({
                                ...passwordData,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                          <img
                            src={showConfirmPassword ? eyeIcon : eyeCloseIcon}
                            alt={showConfirmPassword ? "eye" : "closeeye"}
                            className="show-password"
                            onClick={toggleConfirmPassword}
                          />
                        </div>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {passwordLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div class="wifi-footer">
                        <button
                          type="button"
                          class="btn btn-outline"
                          onClick={() => handlePassowrdCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="text-btn primary-btn"
                          onClick={() => postDevicePassword()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===FIFTH TAB END=== */}

                  {/* ==================SIXTH TAB=========== */}

                  <div
                    class="tab-pane fade event-tab"
                    id="pills-events"
                    role="tabpanel"
                    aria-labelledby="pills-events-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>System Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>CPU Overload</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="cpuOverload"
                                  checked={eventData.cpuOverload === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>CPU Overload Clear</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="cpuOverloadClear"
                                  checked={eventData.cpuOverloadClear === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Memory Overload</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="memoryOverload"
                                  checked={eventData.memoryOverload === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Memory Overload Clear</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="memoryOverloadClear"
                                  checked={eventData.memoryOverloadClear === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>GPS Not Locked</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="gpsNotLocked"
                                  checked={eventData.gpsNotLocked === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>GPS Locked</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="gpsLocked"
                                  checked={eventData.gpsLocked === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>System Reboot Trap</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="systemRebootTrap"
                                  checked={eventData.systemRebootTrap === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Temperature Threshold Reached</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="temperatureThresholdReached"
                                  checked={
                                    eventData.temperatureThresholdReached === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>
                                Temperature Threshold Reached Clear
                              </strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="temperatureThresholdReachedClear"
                                  checked={
                                    eventData.temperatureThresholdReachedClear ===
                                    1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Speed Changed</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetSpeedChanged"
                                  checked={eventData.ethernetSpeedChanged === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Duplex Changed</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetDuplexChanged"
                                  checked={
                                    eventData.ethernetDuplexChanged === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Link Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Error Overload</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetErrorOverload"
                                  checked={
                                    eventData.ethernetErrorOverload === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Error Overload Clear</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetErrorOverloadClear"
                                  checked={
                                    eventData.ethernetErrorOverloadClear === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Wireless Error Overload</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="wirelessErrorOverload"
                                  checked={
                                    eventData.wirelessErrorOverload === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Wireless Error Overload Clear</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="wirelessErrorOverloadClear"
                                  checked={
                                    eventData.wirelessErrorOverloadClear === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Up</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="linkUp"
                                  checked={eventData.linkUp === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Down</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="linkDown"
                                  checked={eventData.linkDown === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Radio Up</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="radioUp"
                                  checked={eventData.radioUp === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Radio Down</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="radioDown"
                                  checked={eventData.radioDown === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Up</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetUp"
                                  checked={eventData.ethernetUp === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Down</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="ethernetDown"
                                  checked={eventData.ethernetDown === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Password Change</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="linkPasswordChange"
                                  checked={eventData.linkPasswordChange === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Chain Disconnect</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="chainDisconnect"
                                  checked={eventData.chainDisconnect === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Chain Disconnect Clear</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="chainDisconnectClear"
                                  checked={eventData.chainDisconnectClear === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Firmware Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Firmware Upgrade Success</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="firmwareUpgradeSuccess"
                                  checked={
                                    eventData.firmwareUpgradeSuccess === 1
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Firmware Upgrade Fail</strong>
                              <label class="ice-switch">
                                <input
                                  type="checkbox"
                                  name="firmwareUpgradeFail"
                                  checked={eventData.firmwareUpgradeFail === 1}
                                  onChange={handleCheckboxChange}
                                />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Threshold</strong>
                        <div className="row mb-2 mt-3">
                          <div className="col-lg-4">
                            <label
                              htmlFor="cpuThreshold"
                              className="col-form-label d-flex"
                            >
                              CPU Threshold
                              <i
                                className="fa fa-info-circle ms-2"
                                data-tooltip-id="cpu-tooltip"
                                data-tooltip-content="Values must be between 1 to 100 %."
                              />
                            </label>
                            <Tooltip id="cpu-tooltip" />
                            <input
                              type="text"
                              className="form-control"
                              name="cpuThreshold"
                              value={eventData.cpuThreshold}
                              onChange={handleEventInputChange}
                            />
                          </div>

                          <div className="col-lg-4">
                            <label
                              htmlFor="memoryThreshold"
                              className="col-form-label d-flex"
                            >
                              Memory Threshold
                              <i
                                className="fa fa-info-circle ms-2"
                                data-tooltip-id="memory-tooltip"
                                data-tooltip-content="Values must be between 1 to 100 %."
                              />
                            </label>
                            <Tooltip id="memory-tooltip" />
                            <input
                              type="text"
                              className="form-control"
                              name="memoryThreshold"
                              value={eventData.memoryThreshold}
                              onChange={handleEventInputChange}
                            />
                          </div>

                          <div className="col-lg-4">
                            <label
                              htmlFor="temperatureThreshold"
                              className="col-form-label d-flex"
                            >
                              Temperature Threshold
                              <i
                                className="fa fa-info-circle ms-2"
                                data-tooltip-id="temp-tooltip"
                                data-tooltip-content="Temperature values must be between 0 to 80°C."
                              />
                            </label>
                            <Tooltip id="temp-tooltip" />
                            <input
                              type="text"
                              className="form-control"
                              name="temperatureThreshold"
                              value={eventData.temperatureThreshold}
                              onChange={handleEventInputChange}
                            />
                          </div>

                          <div className="col-lg-4">
                            <label
                              htmlFor="ethernetErrorThreshold"
                              className="col-form-label d-flex"
                            >
                              Ethernet Error Threshold
                              <i
                                className="fa fa-info-circle ms-2"
                                data-tooltip-id="ethernet-tooltip"
                                data-tooltip-content="Values must be between 0.01 to 100 %."
                              />
                            </label>
                            <Tooltip id="ethernet-tooltip" />
                            <input
                              type="text"
                              className="form-control"
                              name="ethernetErrorThreshold"
                              value={eventData.ethernetErrorThreshold}
                              onChange={handleEventInputChange}
                            />
                          </div>

                          <div className="col-lg-4">
                            <label
                              htmlFor="wirelessErrorThreshold"
                              className="col-form-label d-flex"
                            >
                              Wireless Error Threshold
                              <i
                                className="fa fa-info-circle ms-2"
                                data-tooltip-id="wireless-tooltip"
                                data-tooltip-content="Values must be between 1 to 100 %."
                              />
                            </label>
                            <Tooltip id="wireless-tooltip" />
                            <input
                              type="text"
                              className="form-control"
                              name="wirelessErrorThreshold"
                              value={eventData.wirelessErrorThreshold}
                              onChange={handleEventInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {eventsLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div class="wifi-footer">
                        <button type="button" class="btn btn-outline">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="text-btn primary-btn"
                          onClick={postEvents}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>

                  {/* ===SIXTH TAB END=== */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Chat />
      </div>

      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>General Configuration updated successfully</p>
        <Button onClick={handleCancelPopUp}>OK</Button>
      </Modal>
      <Modal
        show={showAtpcSuccessPopup}
        onHide={handleAtpcCancelPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>ATPC Configuration updated successfully</p>
        <Button onClick={handleAtpcCancelPopup}>OK</Button>
      </Modal>
      <Modal
        show={showPasswordSuccessPopup}
        onHide={handlePasswordCancelPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Device Password updated successfully</p>
        <Button onClick={handlePasswordCancelPopup}>OK</Button>
      </Modal>
      <Modal
        show={showEventSuccessPopup}
        onHide={handleEventCancelPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Events updated successfully</p>
        <Button onClick={handleEventCancelPopup}>OK</Button>
      </Modal>
      <Modal
        show={showBackhaulSuccessPopup}
        onHide={handleBackhaulCancelPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Backhaul Configuration updated successfully</p>
        <Button onClick={handleBackhaulCancelPopup}>OK</Button>
      </Modal>
      <Modal
        show={showUbrSettingsSuccessPopup}
        onHide={handleUbrSettingsCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Ubr Settings updated successfully</p>
        <Button onClick={handleUbrSettingsCancelPopUp}>OK</Button>
      </Modal>
    </div>
  );
}

export default DeviceSettings;
