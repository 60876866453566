import React, { useState, useEffect } from "react";
// import dummyGraph from "../../../assets/images/data-usage.png";
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import allApi from "../../../../api/allApi";
import ApexCharts from "react-apexcharts";
import noSignal from "../../../../assets/images/no-signal.svg";
import dotSignal from "../../../../assets/images/dot-signal.svg";
import oneSignal from "../../../../assets/images/one-signal.svg";
import twoSignal from "../../../../assets/images/two-signal.svg";
import fullSignal from "../../../../assets/images/full-signal.svg";
import Chat from "../../common/chat";

function WirelessDashboard() {
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [ClientSeries, setClientSeries] = useState([]);
  const [ClientDataCategories, setClientDataCategories] = useState([]);
  const [ChannelSeries, setChannelSeries] = useState([]);
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [trafficSeries, setTrafficSeries] = useState([]);
  const [trafficCategories, setTrafficCategories] = useState([]);
  const [wirelessDataSeries, setWirelessDataSeries] = useState([]);
  const [wirelessDataCategories, setWirelessDataCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [devices, setDevices] = useState([]);
  const [clients, setClients] = useState([]);
  const [networkId, setNetworkId] = useState("all");
  const [duration, setDuration] = useState("24h");
  const [trafficDuration, setTrafficDuration] = useState("24h");
  const [wirelessDataDuration, setWirelessDataDuration] = useState("24h");
  const [clientsDuration, setClientsDuration] = useState("24h");
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");
  const [ClientDataConsumptionDuration, setClientDataConsumptionDuration] =
    useState("24h");
  const [channelUtilizationDuration, setChannelUtilizationDuration] =
    useState("24h");
  const [newDuration, setNewDuration] = useState("24h");
  const [license, setLicense] = useState("");

  useEffect(() => {
    getDeviceDetails();
    getClientList();
    fetchLicense();
  }, []);

  const fetchLicense = () => {
    const storedLicense = localStorage.getItem("license");
    if (storedLicense) {
      setLicense(storedLicense);
    }
  };
  useEffect(() => {
    getClientDetail(clientsDuration);
  }, [clientsDuration]);

  useEffect(() => {
    getConnectedClientPerSSID(connectedClientsDuration);
  }, [connectedClientsDuration]);

  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);
  useEffect(() => {
    getTrafficBandwidthData(trafficDuration);
  }, [trafficDuration]);

  useEffect(() => {
    getWirelessDataConsumption(networkId, wirelessDataDuration);
  }, [networkId, wirelessDataDuration]);

  useEffect(() => {
    getClientDataConsumption(ClientDataConsumptionDuration);
  }, [ClientDataConsumptionDuration]);

  const handleDurationChange = (dur) => {
    setDuration(dur);
  };

  const handleTrafficDurationChange = (trafficDuration) => {
    setTrafficDuration(trafficDuration);
  };

  const handleWirelessDataDurationChange = (wirelessDataDuration) => {
    setWirelessDataDuration(wirelessDataDuration);
  };
  const handleClientsDurationChange = (clientsDuration) => {
    setClientsDuration(clientsDuration);
  };

  const handleConnectedClientsDurationChange = (connectedClientsDuration) => {
    setConnectedClientsDuration(connectedClientsDuration);
  };

  const handleChannelUtilizationDurationChange = (
    channelUtilizationDuration
  ) => {
    setChannelUtilizationDuration(channelUtilizationDuration);
  };

  const handleClientDataConsumptionDurationChange = (newDuration) => {
    setClientDataConsumptionDuration(newDuration);
  };

  const handleNetworkTypeChange = (type) => {
    setNetworkId(type);
  };

  async function getDeviceDetails() {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/deviceList?networkId=6001"
      );
      if (response.response) {
        setDevices(response.response);
      }
    } catch (error) {
      console.error("Error fetching device data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  const getClientList = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/clientList?networkId=6001"
      );
      if (response.response) {
        setClients(response.response);
      } else {
        setClients([]);
      }
    } catch (error) {
      setClients([]);
    } finally {
      setLoading(false);
    }
  };

  const getTrafficBandwidthData = async (trafficDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalBandWidth?commandType=5&duration=${trafficDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.response;
        const seriesData = data.map((item) => ({
          name: item.serialNumber,
          data: item.data.map((trafficItem) => ({
            x: new Date(trafficItem.interval1).getTime(),
            y: trafficItem.totalUtilization,
          })),
        }));

        const timestamps = seriesData.flatMap((series) =>
          series.data.map((point) => point.x)
        );
        const uniqueTimestamps = Array.from(new Set(timestamps)).sort(
          (a, b) => a - b
        );
        setTrafficCategories(uniqueTimestamps);
        setTrafficSeries(seriesData);
      } else {
        console.error("Failed to fetch traffic data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching traffic data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChannelUtilizationData = async (channelUtilizationDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalChannelUtilization?commandType=5&duration=${channelUtilizationDuration}&networkId=6001`
      );
      if (response.status === "OK" && response.response) {
        const data = response.response;
        const seriesData = [];
        const categories = new Set();
        data.forEach((device) => {
          const deviceSeries = {
            name: device.serialNumber,
            data: [],
          };

          device.data.forEach((entry) => {
            const timestamp = new Date(entry.interval1).getTime();
            const totalUtilization = entry.totalUtilization;

            deviceSeries.data.push({
              x: timestamp,
              y: totalUtilization,
            });

            categories.add(timestamp);
          });

          seriesData.push(deviceSeries);
        });

        const sortedCategories = Array.from(categories).sort((a, b) => a - b);

        setChannelCategories(sortedCategories);
        setChannelSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching channel utilization data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getWirelessDataConsumption = async (
    networkId,
    wirelessDataDuration
  ) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/wirelessDataConsumption?commandType=5&duration=${wirelessDataDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.response;
        const allTimestamps = new Set();
        data.forEach((device) => {
          device.data.forEach((entry) => {
            allTimestamps.add(new Date(entry.interval1).getTime());
          });
        });
        const uniqueTimestamps = Array.from(allTimestamps).sort(
          (a, b) => a - b
        );

        // Prepare series data for each serial number
        const seriesData = data.map((device) => {
          const deviceData = uniqueTimestamps.map((timestamp) => {
            // Find the corresponding data entry for the current timestamp
            const entry = device.data.find(
              (e) => new Date(e.interval1).getTime() === timestamp
            );
            return {
              x: timestamp, // Use the timestamp for x-axis
              y: entry ? entry.totalUtilization : 0,
            };
          });

          return {
            name: device.serialNumber,
            data: deviceData,
          };
        });

        setWirelessDataCategories(uniqueTimestamps);
        setWirelessDataSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching wireless data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getConnectedClientPerSSID = async (connectedClientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();

      const response = await api.getRequest(
        `api/wireless/connectedClientsPerSsid?commandType=5&duration=${connectedClientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.response;

        const seriesData = data.map((item) => ({
          name: item.ssidName,
          data: item.data.map((client) => {
            const date = new Date(client.interval1);
            if (
              connectedClientsDuration === "8h" ||
              connectedClientsDuration === "24h"
            ) {
              date.setMinutes(0, 0, 0);
            } else if (
              connectedClientsDuration === "7d" ||
              connectedClientsDuration === "30d"
            ) {
              date.setHours(0, 0, 0, 0);
            }

            return {
              x: date.getTime(),
              y: client.totalClientSum,
            };
          }),
        }));

        const timeStamps = [
          ...new Set(
            seriesData.flatMap((series) => series.data.map((point) => point.x))
          ),
        ];

        setConnectedClientCategories(timeStamps);
        setConnectedClientSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching connected client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getClientDetail = async (clientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalClients?commandType=5&duration=${clientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.response;
        const seriesData = {};

        // Aggregation logic for 7d or 30d
        const isAggregatedByDay =
          clientsDuration === "7d" || clientsDuration === "30d";

        data.forEach((item) => {
          const serialNo = item.serialNumber;

          item.data.forEach((client) => {
            let timestamp = new Date(client.interval1).getTime();
            const totalClients = client.totalClients || 0;

            if (isAggregatedByDay) {
              const date = new Date(timestamp);
              date.setHours(0, 0, 0, 0);
              timestamp = date.getTime();
            }

            if (!seriesData[serialNo]) {
              seriesData[serialNo] = { name: serialNo, data: [] };
            }

            // Push aggregated data or individual points
            const existingPoint = seriesData[serialNo].data.find(
              (point) => point.x === timestamp
            );
            if (existingPoint) {
              existingPoint.y += totalClients; // Aggregate client count for the same day
            } else {
              seriesData[serialNo].data.push({ x: timestamp, y: totalClients });
            }
          });
        });

        const series = Object.values(seriesData);
        setSeries(series); // Make sure this updates your chart's series
        const categories = series[0]?.data.map((point) => point.x); // Example to extract categories
        setCategories(categories);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client detail data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getClientDataConsumption = async (ClientDataConsumptionDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/clientDataConsumption?commandType=5&duration=${ClientDataConsumptionDuration}&networkId=6001`
      );

      if (response.status === "OK" && response.response) {
        const data = response.response;
        const series = [];

        data.forEach((client) => {
          const consumptionData = {
            name: `SN: ${client.serialNumber || "Unknown"}`, // Fallback if serialNumber is undefined
            data: [],
          };

          // Iterate through the data entries
          if (client.data && Array.isArray(client.data)) {
            client.data.forEach((entry) => {
              const timestamp = entry.interval1 || "";
              const totalConsumption = entry.totalConsumption || 0;
              if (timestamp) {
                consumptionData.data.push({
                  x: new Date(timestamp),
                  y: totalConsumption,
                });
              }
            });
          }

          if (consumptionData.data.length > 0) {
            series.push(consumptionData);
          }
        });

        series.forEach((clientSeries) => {
          clientSeries.data.sort((a, b) => new Date(a.x) - new Date(b.x));
        });

        if (series.length > 0) {
          setClientDataCategories(series[0]?.data.map((point) => point.x));
          setClientSeries(series);
        }
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const clientDataConsumptionOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: ClientDataCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          if (
            ClientDataConsumptionDuration === "7d" ||
            ClientDataConsumptionDuration === "30d"
          ) {
            const options = {
              day: "2-digit",
              month: "short",
              timeZone: "Asia/Kolkata",
            };
            return date.toLocaleString("en-IN", options);
          } else {
            const options = {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Asia/Kolkata",
              hour12: false,
            };
            return date.toLocaleString("en-IN", options);
          }
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined && val !== null ? val.toFixed(0) : "0 bytes";
        },
      },
      title: {
        text: "Data Consumption (bytes)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      }, // Display legend for each series
    },
    colors: ClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };

  const TrafficOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (trafficDuration === "7d" || trafficDuration === "30d") {
            const options = {
              day: "2-digit",
              month: "short",
              timeZone: "Asia/Kolkata",
            };
            return date.toLocaleString("en-IN", options);
          } else {
            const options = {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Asia/Kolkata",
              hour12: false,
            };
            return date.toLocaleString("en-IN", options);
          }
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#d6d6d6",
      },
      axisTicks: {
        show: false,
        color: "#d6d6d6",
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);
          const options = {
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
            hour12: false,
          };
          return date.toLocaleString("en-IN", options);
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
      },
      title: {
        text: "Traffic (KBPS)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      },
    },
    colors: ["#fc7594", "#00aaff", "#ffc107", "#28a745"], // Colors for different series
    toolbar: {
      show: false,
    },
  };

  const ChannelOptions = {
    chart: {
      id: "channel-utilization-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime",
      categories: ChannelCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          if (
            channelUtilizationDuration === "7d" ||
            channelUtilizationDuration === "30d"
          ) {
            const options = {
              day: "2-digit",
              month: "short",
              timeZone: "Asia/Kolkata",
            };
            return date.toLocaleString("en-IN", options);
          } else {
            const options = {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Asia/Kolkata",
              hour12: false,
            };
            return date.toLocaleString("en-IN", options);
          }
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? Math.round(val) : "N/A";
        },
      },
      title: {
        text: "Channel Utilization (%)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      },
    },
    colors: ChannelSeries.map((_, index) => {
      const colorArray = ["#fc7594", "#00aaff", "#4caf50", "#ff9800"];
      return colorArray[index % colorArray.length];
    }),
    toolbar: {
      show: false,
    },
  };

  const WirelessDataOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar (removes home, zoom in/out icons)
      },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime",
      categories: wirelessDataCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          const options =
            wirelessDataDuration === "7d" || wirelessDataDuration === "30d"
              ? { day: "2-digit", month: "short", timeZone: "Asia/Kolkata" }
              : {
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                };

          return date.toLocaleString("en-IN", options);
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      title: {
        text: "Total Utilization",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  };

  const connectedClientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime",
      categories: ConnectedClientCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          if (
            connectedClientsDuration === "7d" ||
            connectedClientsDuration === "30d"
          ) {
            return date.toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "short",
            });
          } else {
            return date.toLocaleTimeString("en-IN", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
          }
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      title: { text: "Client Count" },
      labels: { formatter: (val) => val.toFixed(0) },
    },
    stroke: { width: 1 },
    dataLabels: { enabled: false },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      },
    },
    zoom: { enabled: false },
  };

  const allTimestamps = series.flatMap((s) => s.data.map((point) => point.x));
  const minTimestamp = Math.min(...allTimestamps);
  const maxTimestamp = Math.max(...allTimestamps);

  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999);
  const currentTimestamp = currentDate.getTime();

  const xaxisMin = minTimestamp;
  const xaxisMax = Math.max(maxTimestamp, currentTimestamp);

  const clientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Enables zooming on the chart
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          const options =
            clientsDuration === "7d" || clientsDuration === "30d"
              ? { day: "2-digit", month: "short", timeZone: "Asia/Kolkata" }
              : {
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                };

          return date.toLocaleString("en-IN", options);
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#d6d6d6",
      },
      axisTicks: {
        show: false,
        color: "#d6d6d6",
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);
          const options =
            clientsDuration === "7d" || clientsDuration === "30d"
              ? { day: "2-digit", month: "short" }
              : {
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                };

          return date.toLocaleString("en-IN", options);
        },
      },
    },
    yaxis: {
      min: 0, // Minimum value for y-axis
      labels: {
        formatter: (val) => (val !== undefined ? val.toFixed(0) : "N/A"), // Display integers or "N/A"
      },
      title: {
        text: "Number of Clients", // Y-axis title
      },
    },
    stroke: {
      width: 1, // Increased width for better visibility
    },
    dataLabels: {
      enabled: false, // Disable data labels for cleaner look
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      }, // Display legend for each series
    },
    colors: ["#fc7594", "#00aaff", "#ffc107", "#28a745"],
    tooltip: {
      shared: true,
      intersect: false,
    },
    grid: {
      borderColor: "#e7e7e7", // Color of grid lines
    },
  };

  return (
    <div>
      <div className="right-button-group">
        <div>
          <button
            className="text-btn primary-btn"
            onClick={() => {
              navigate("/wifi-config");
            }}
          >
            Wifi Configuration
          </button>
        </div>
      </div>
      <div className="two-column mb-4">
        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Clients
              <span
                onClick={() => {
                  navigate("/client-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {clientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : clientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {series.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <ApexCharts
                    options={clientsOptions}
                    series={series}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {license !== "Standard" && (
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>
                Traffic Bandwidth
                <span
                  onClick={() => {
                    navigate("/trafficBandwidth-expandation");
                  }}
                >
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                </span>
              </h3>
              <div className="card-title-dropdown">
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      All{" "}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          2.4 HHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          5 GHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          6 GHz
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {trafficDuration === "24h"
                        ? "Last 24 Hrs"
                        : trafficDuration}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleTrafficDurationChange("8h")}
                        >
                          Last 8 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleTrafficDurationChange("24h")}
                        >
                          Last 24 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleTrafficDurationChange("7d")}
                        >
                          Last 7 Days
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleTrafficDurationChange("30d")}
                        >
                          Last 30 Days
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div>
                  {trafficSeries.length === 0 ? (
                    <div className="no-data-box">
                      <div className="no-data-text">No Data Available</div>
                    </div>
                  ) : (
                    <ApexCharts
                      options={TrafficOptions}
                      series={trafficSeries}
                      type="line"
                      height={170}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {license !== "Standard" && (
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>
                Connected Clients per SSID
                <span
                  onClick={() => {
                    navigate("/ConnectedClients-expandation");
                  }}
                >
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                </span>
              </h3>
              <div className="card-title-dropdown">
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      All{" "}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          2.4 GHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          5 GHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          6 GHz
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {connectedClientsDuration === "24h"
                        ? "Last 24 Hrs"
                        : connectedClientsDuration}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleConnectedClientsDurationChange("8h")
                          }
                        >
                          Last 8 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleConnectedClientsDurationChange("24h")
                          }
                        >
                          Last 24 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleConnectedClientsDurationChange("7d")
                          }
                        >
                          Last 7 Days
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleConnectedClientsDurationChange("30d")
                          }
                        >
                          Last 30 Days
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div>
                  {ConnectedClientSeries.length === 0 ? (
                    <div className="no-data-box">
                      <div className="no-data-text">No Data Available</div>
                    </div>
                  ) : (
                    <Chart
                      options={connectedClientsOptions}
                      series={ConnectedClientSeries}
                      type="line"
                      height={170}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Wireless Data Consumption
              <span
                onClick={() => {
                  navigate("/wirelessDataGraph-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {networkId === "all" ? "All" : networkId}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="clientDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("2.4GHz")}
                      >
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("5GHz")}
                      >
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("6GHz")}
                      >
                        6 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("all")}
                      >
                        All
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {wirelessDataDuration === "24h"
                      ? "Last 24 Hrs"
                      : wirelessDataDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {wirelessDataSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={WirelessDataOptions}
                    series={wirelessDataSeries}
                    type="bar"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {license !== "Standard" && license !== "Business" && (
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>
                Client Data Consumption
                <span
                  onClick={() => {
                    navigate("/ClientData-expandation");
                  }}
                >
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                </span>
              </h3>
              <div className="card-title-dropdown">
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      All{" "}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          2.4 HHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          5 GHz
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          6 GHz
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="btn-group">
                  <span
                    className="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {ClientDataConsumptionDuration === "24h"
                        ? "Last 24 Hrs"
                        : ClientDataConsumptionDuration}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleClientDataConsumptionDurationChange("8h")
                          }
                        >
                          Last 8 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleClientDataConsumptionDurationChange("24h")
                          }
                        >
                          Last 24 Hrs
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleClientDataConsumptionDurationChange("7d")
                          }
                        >
                          Last 7 Days
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            handleClientDataConsumptionDurationChange("30d")
                          }
                        >
                          Last 30 Days
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div>
                  {ClientSeries.length === 0 ? (
                    <div className="no-data-box">
                      <div className="no-data-text">No Data Available</div>
                    </div>
                  ) : (
                    <Chart
                      options={clientDataConsumptionOptions}
                      series={ClientSeries}
                      type="line"
                      height={170}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Channel Utilization
              <span
                onClick={() => {
                  navigate("/ChannelUtilization-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {channelUtilizationDuration === "24h"
                      ? "Last 24 Hrs"
                      : channelUtilizationDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {ChannelSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={ChannelOptions}
                    series={ChannelSeries}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Client List ({clients?.length})</h3>
                <div className="inc-card-button">
                  <button
                    className="icon-btn"
                    title="Refresh"
                    onClick={getClientList}
                  >
                    {refreshing ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    )}
                  </button>
                  {/* <button className="icon-btn" title="Add">
                    <i className="fa fa-filter" aria-hidden="true"></i>
                  </button> */}
                </div>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Clients</th>
                        <th>Connection</th>

                        <th>Device Name</th>
                        <th>SSID</th>
                        <th>Model</th>
                        <th>OS</th>
                        <th>Mac Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : clients && clients.length > 0 ? (
                        clients.map((client, index) => (
                          <tr key={index}>
                            <td>{client.serialNo || "N/A"}</td>
                            <td>{client.connDeviceType || "N/A"}</td>

                            <td>{client.deviceName || "N/A"}</td>
                            <td>{client.ssid || "N/A"}</td>
                            <td>{client.connDeviceModel || "N/A"}</td>
                            <td>{client.platform || "N/A"}</td>
                            <td>{client.mac || "N/A"}</td>
                            <td>{client.ipAddr || "N/A"}</td>
                            <td className="rssi-icon">
                              {/* {client.rssi || "N/A"} */}
                              {client.rssi >= 80 ? (
                                <img src={fullSignal} />
                              ) : client.rssi >= 60 ? (
                                <img src={twoSignal} />
                              ) : client.rssi >= 40 ? (
                                <img src={oneSignal} />
                              ) : client.rssi >= 20 ? (
                                <img src={dotSignal} />
                              ) : (
                                <img src={noSignal} />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">No Clients found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Devices ({devices?.length})</h3>
                <div className="inc-card-button">
                  <button
                    className="icon-btn"
                    title="Refresh"
                    onClick={getDeviceDetails}
                  >
                    {refreshing ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    )}
                  </button>
                  {/* <button className="icon-btn" title="Add">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button> */}
                </div>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Device Name</th>
                        <th>HW Version</th>
                        <th>FW Version</th>
                        <th>IP</th>
                        <th>Mac Address</th>
                        <th>UP Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : devices?.length > 0 ? (
                        devices.map((device, index) => (
                          <tr key={index}>
                            <td>{device.serialNumber || "N/A"}</td>
                            <td>{device.deviceName || "N/A"}</td>
                            <td>{device.hardwareVersion || "N/A"}</td>
                            <td>{device.firmwareVersion || "N/A"}</td>
                            <td>{device.ipAddress || "N/A"}</td>
                            <td>{device.macAddress || "N/A"}</td>
                            <td>{device.created_at || "N/A"}</td>
                            <td>
                              <span
                                className={
                                  "d-status " +
                                  (device?.health?.toLowerCase() === "offline"
                                    ? "offline"
                                    : "online")
                                }
                              >
                                {device.health}
                              </span>{" "}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">No devices found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chat />
    </div>
  );
}

export default WirelessDashboard;
