import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate, useLocation } from "react-router-dom";

function OverviewTrends() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [trendLoading, setTrendLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);
  const [max, setMax] = useState(0);
  const [selectedInterval, setSelectedInterval] = useState("hourly");
  const [trendData, setTrendData] = useState([]);
  const license = localStorage.getItem("license");

  useEffect(() => {
    handleDeviceTrends();
  }, [selectedInterval]);

  const handleDeviceTrends = async () => {
    setTrendLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/deviceTrend/${selectedInterval}`
      );
      if (response) {
        const transformedData = {};
        let maxV = 0;

        response.forEach(({ interval, device_type, net_count }) => {
          const formattedInterval = formatInterval(interval);
          if (!transformedData[formattedInterval]) {
            transformedData[formattedInterval] = {
              interval: formattedInterval,
              SNMP: 0,
              "TR-069": 0,
              MQTT: 0,
              HTTP: 0,
            };
          }
          if (device_type) {
            const type = device_type.toUpperCase();
            if (type === "AP" || type === "HTTP") {
              transformedData[formattedInterval]["HTTP"] += net_count;
            } else {
              transformedData[formattedInterval][type] += net_count;
            }
          } else {
            transformedData[formattedInterval]["HTTP"] += net_count;
          }

          // Update maxV to ensure we are accounting for the highest value
          maxV = Math.max(maxV, net_count);
        });

        setTrendData(Object.values(transformedData));
        setMax(maxV);
      }
      setTrendLoading(false);
    } catch (error) {
      console.error("Error fetching trend data:", error);
      setTrendLoading(false);
    }
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  const formatInterval = (interval) => {
    // Normalize the date format if needed
    const date = new Date(interval);

    // Check for invalid date
    if (isNaN(date.getTime())) {
      console.error("Invalid date format:", interval);
      return interval; // Return the original interval if date is invalid
    }

    if (selectedInterval === "yearly") {
      return date.toLocaleDateString("en-GB", {
        year: "numeric",
      });
    } else if (selectedInterval === "monthly") {
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
    } else if (selectedInterval === "weekly") {
      // Assuming weekly returns a single date, otherwise adjust as needed
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
    } else {
      return interval.split(" ")[0]; // Handle other intervals
    }
  };

  const trendsColor = (index) => {
    const colors = ["#33A02C", "#1F78B4", "#FB9A99", "#B2DF8A", "#A6CEE3"];
    return colors[index % colors.length];
  };

  const renderIntervalOptions = () => {
    const options = [];

    if (license === "Standard") {
      options.push("hourly");
    } else if (license === "Business") {
      options.push("hourly", "weekly");
    } else if (license === "Enterprise") {
      options.push("hourly", "weekly", "monthly", "yearly");
    }

    return options.map((interval) => (
      <li key={interval}>
        <a
          className="dropdown-item"
          href="#"
          onClick={() => handleIntervalChange(interval)}
        >
          {interval.charAt(0).toUpperCase() + interval.slice(1)}
        </a>
      </li>
    ));
  };

  return (
    <div>
      <div className="inc-card-title">
        <h3>Device Onboarding Trends</h3>
        <span
          className="dropdown"
          id="trendDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span>
            {selectedInterval.charAt(0).toUpperCase() +
              selectedInterval.slice(1)}{" "}
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </span>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="trendDropdown"
          >
            {renderIntervalOptions()}
          </ul>
        </span>
      </div>
      <div className="inc-card-body">
        {trendLoading ? (
          <Loader />
        ) : (
          <div className="chart-wrapper">
            <ResponsiveContainer aspect={3.2} className="on-trend">
              <LineChart
                data={trendData}
                margin={{ top: 0, right: 30, left: -20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="interval" tickFormatter={formatInterval} />
                <YAxis domain={[0, max + 10]} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="SNMP"
                  name="SNMP"
                  stroke="#33A02C"
                />
                <Line
                  type="monotone"
                  dataKey="TR-069"
                  name="TR-069"
                  stroke="#1F78B4"
                />
                <Line
                  type="monotone"
                  dataKey="MQTT"
                  name="MQTT"
                  stroke="#FB9A99"
                />
                <Line
                  type="monotone"
                  dataKey="HTTP"
                  name="HTTP"
                  stroke="#f1a92d"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
        <div className="trends-legends">
          <ul>
            <li>
              <span className="d1"></span>SNMP
            </li>
            <li>
              <span className="d2"></span>TR-069
            </li>
            <li>
              <span className="d3"></span>MQTT
            </li>
            <li>
              <span className="d4"></span>HTTP
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OverviewTrends;
