import React, { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import "bootstrap/dist/css/bootstrap.min.css";
import PopupTagDevice from "../common/popupTagDevice";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Chat from "../common/chat";
import Loader from "../common/Loader";

const DeviceDetail = () => {
  const [genieInfo, setDeviceInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  // const { deviceName } = useParams();
  const location = useLocation();
  const initialDeviceData = location.state?.deviceInfo || {};
  // const [alarms, setAlarms] = useState([]);
  const [error, setError] = useState(null);
  const [deviceId, setDeviceId] = useState(initialDeviceData.deviceId || null);
  const [protocolType, setProtocolType] = useState(
    initialDeviceData.protocolType || null
  );
  const deviceData = location.state?.deviceInfo || {};

  useEffect(() => {
    if (location.state?.protocolType) {
    }
  }, [location?.state?.protocolType]);

  // const handleSaveTagApiCall = async () => {
  //   try {
  //     const api = new AllApi();
  //     const deviceIDD = sessionStorage.getItem("deviceID");
  //     const payload = { deviceId: [deviceIDD], tag: tagInput };
  //     const response = await api.postRequest("api/genie/addTag", "", payload);
  //     if (response.ok) {
  //       setTags([...tags, tagInput]);
  //     } else {
  //       console.error("Failed to add tag");
  //     }
  //   } catch (error) {
  //     console.error("Error adding tag:", error);
  //   }
  //   setTagInput("");
  // };

  // const handleCloseTagModal = () => {
  //   setShowTagPopUp(false);
  // };

  // const getTagValueFromPopUp = (value) => {
  //   setTagInput(value);
  // };

  // const openTagPopUp = () => {
  //   const deviceIDD = sessionStorage.getItem("deviceID");
  //   setHeaderMessage(`${deviceIDD} says`);
  //   setShowTagPopUp(true);
  // };

  return (
    <div>
      <div className="device-info-wrap mb-4">
        <div className="inc-card info-card-4">
          <div className="inc-card-title">
            <h3>
              <strong>{deviceData.genieInfo.deviceID.oui || "N/A"}</strong>
            </h3>
            {/* <div className="tags">
              {tags?.map((tag, index) => (
                <span className="tag" key={index}>
                  {tag}
                </span>
              ))}
              <button onClick={openTagPopUp} className="text-btn">
                + Tag
              </button>
            </div> */}
          </div>

          <div className="inc-card-body">
            <div className="device-info">
              <div>
                <strong>Device ID:</strong>
                <span>{deviceData.genieInfo.id || "N/A"}</span>
              </div>
              <div>
                <strong>Product Class:</strong>
                <span>
                  {deviceData.genieInfo.deviceID.productClass || "N/A"}
                </span>
              </div>
              <div>
                <strong>Serial Number:</strong>
                <span>
                  {deviceData.genieInfo.deviceID.serialNumber || "N/A"}
                </span>
              </div>
              <div>
                <strong>Software Version:</strong>
                <span>
                  {deviceData.genieInfo.internetGatewayDevice.deviceInfo
                    .softwareVersion.value || "N/A"}
                </span>
              </div>
              <div>
                <strong>Hardware Version:</strong>
                <span>
                  {deviceData.genieInfo.internetGatewayDevice.deviceInfo
                    .hardwareVersion.value || "N/A"}
                </span>
              </div>
              <div>
                <strong>IP Address:</strong>
                <span>
                  {deviceData.genieInfo.internetGatewayDevice.lanDevice
                    .lanDeviceConfig.hosts.host.host_1.ipAddress.value || "N/A"}
                </span>
              </div>
              <div>
                <strong>Last Inform:</strong>
                <span>{deviceData?.genieInfo.lastInform || "N/A"}</span>
              </div>
              <div>
                <strong>Health:</strong>
                <span>{deviceData?.health || "N/A"}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="inc-card info-card-8">
          <div>
            <div className="inc-card-title">
              <h3>Lan Host</h3>
            </div>
          </div>
          <div className="accordion mt-4" id="accordionWidgets">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  INC Device
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionWidgets"
              >
                <div className="accordion-body">
                  {/* {error ? (
                    <p className="text-danger">Error: {error}</p>
                  ) : loading ? (
                    <Loader />
                  ) : ( */}
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                // checked={isSelectAllChecked}
                                // onChange={handleSelectAll}
                                // disabled={widgets.length === 0}
                              />
                              <span className="indicator"></span>
                            </label>
                          </th>
                          <th>Widget Name</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {widgets.map((widget) => (
                            <tr key={widget.widget_id}>
                              <td>
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    checked={isWidgetChecked(widget.widget_id)}
                                    onChange={() =>
                                      handleSelectIndividual(widget.widget_id)
                                    }
                                  />
                                  <span className="indicator"></span>
                                </label>
                              </td>
                            </tr>
                          ))} */}
                      </tbody>
                    </table>
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div>
              <div className="inc-card-title">
                <h3>Device Alerts</h3>{" "}
                <div className="inc-card-button">
                  <button className="icon-btn">
                    <i className="bin icon"></i>
                  </button>
                  <button className="icon-btn">
                    <i className="refresh icon"></i>
                  </button>
                  <button className="icon-btn">
                    <i className="download icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="inc-card-table">
              <table>
                {/* <thead>
                  <tr>
                    <th>Severity Level</th>
                    <th>Alarm Type</th>
                    <th>Is Acknowledged</th>
                    <th>Is Autoclear</th>
                    <th>Notification</th>
                    <th>Alarm Behaviour</th>
                    <th>Alarm Description</th>
                    <th>Alarm Time</th>
                    <th>Acked Time</th>
                    <th>Cleared time</th>
                    <th>Is Read</th>
                    <th>Event Name</th>
                    <th>Device Ipv6</th>
                  </tr>
                </thead> */}
                <tbody>
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No Alerts found
                    </td>
                  </tr>
                  {/* {alarms.map((alarm) => (
                    <tr key={alarm.alarm_id}>
                      <td>{alarm.severityLevel || "--"}</td>
                      <td>{alarm.alarmType || "--"}</td>
                      <td>{alarm.is_acknowledged ? "Yes" : "No"}</td>
                      <td>{alarm.is_autoclear}</td>
                      <td>{alarm.notification || "--"}</td>
                      <td>{alarm.alarm_behaviour || "--"}</td>
                      <td>{alarm.alarm_description || "--"}</td>
                      <td>{alarm.alarm_time || "--"}</td>
                      <td>{alarm.acked_time || "--"}</td>
                      <td>{alarm.cleared_time || "--"}</td>
                      <td>{alarm.is_read || "--"}</td>
                      <td>{alarm.event_name || "--"}</td>
                      <td>{alarm.device_ipv6 || "--"}</td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div>
              <div className="inc-card-title">
                {" "}
                <h3>All Parameters</h3>{" "}
                <div className="inc-card-button">
                  <button className="icon-btn">
                    <i className="upload icon"></i>
                  </button>
                  <button className="icon-btn">
                    <i className="bin icon"></i>
                  </button>
                  <button className="icon-btn">
                    <i className="refresh icon"></i>
                  </button>
                  <button className="icon-btn">
                    <i className="download icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="inc-card-table">
              <table className="parameter-table">
                <tbody>
                  {parameters.map((parameter, index) => (
                    <tr key={index}>
                      <th>{parameter.name}</th>
                      <td>{parameter.value}</td>
                      <td>
                        <i className="icon edit"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      {/* <PopupTagDevice
        showPopUp={showTagPopUp}
        handleClose={handleCloseTagModal}
        getTagValue={getTagValueFromPopUp}
        handleSaveTag={handleSaveTagApiCall}
        headerMessage={headerMessage}
      /> */}
      <Chat />
    </div>
  );
};

export default DeviceDetail;
