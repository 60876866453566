import React from "react";
import incLogo from "../../../../assets/images/inc-name-logo.png";

function ReportsPDF() {
  return (
   <div style={{margin:"50px"}}>
        <div style={{background: "#FFFFFF", maxWidth:"950px", width:"100%", margin:"auto"}}>

            <div style={{borderBottom:"solid 1px #E4E4E4", padding:"20px", display:"flex", alignItems:"center"}}>
                <div style={{flex:"1", fontSize:"14px",}}>
                    <h3 style={{fontSize:"18px", fontWeight:"600"}}>User Name</h3>
                    <p style={{margin:"0px"}}>
                        <i className="fa fa-envelope-o" aria-hidden="true"></i> &nbsp;
                        rakesh.aggarwal@vvdntech.com
                    </p>
                </div>
                <div style={{flex:"1"}}>
                    <div style={{fontSize:"14px", textAlign:"right", marginBottom:"15px"}}>
                        <img src={incLogo} alt="Inc Logo" style={{height:"40px"}} />
                    </div>
                    <div style={{fontSize:"14px", textAlign:"right"}}>
                        <p style={{margin:"0px"}}>Organization Report: #1</p>
                        <p style={{margin:"0px"}}>Date Range: Jun 19, 2025 to Jun 20, 2025</p>
                    </div>
                </div>
            </div>
            <div style={{padding:"20px"}}>
                <div style={{marginBottom:"30px"}}>
                    <h3 style={{fontSize:"16px", fontWeight:"600"}}>Report Name</h3>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-12" style={{display:"flex", gap:"15px"}}>
                        <div style={{backgroundColor:"#F4F4F4", borderRadius:"10px", padding:"15px", flex:"1"}}>
                            <h2 style={{fontWeight:"700"}}>10</h2>
                            <p style={{margin:"0px"}}>Total Devices</p>
                        </div>
                        <div style={{background:"#F4F4F4", borderRadius:"10px", padding:"15px", flex:"1"}}>
                            <h2 style={{fontWeight:"700"}}>10</h2>
                            <p style={{margin:"0px"}}>Total Devices</p>
                        </div>
                        <div style={{background:"#F4F4F4", borderRadius:"10px", padding:"15px", flex:"1"}}>
                            <h2 style={{fontWeight:"700"}}>10</h2>
                            <p style={{margin:"0px"}}>Total Devices</p>
                        </div>
                        <div style={{background:"#F4F4F4", borderRadius:"10px", padding:"15px", flex:"1"}}>
                            <h2 style={{fontWeight:"700"}}>10</h2>
                            <p style={{margin:"0px"}}>Total Devices</p>
                        </div>
                        <div style={{background:"#F4F4F4", borderRadius:"10px", padding:"15px", flex:"1"}}>
                            <h2 style={{fontWeight:"700"}}>10</h2>
                            <p style={{margin:"0px"}}>Total Devices</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="inc-card" style={{border:"solid 1px #E4E4E4"}}>
                            <div className="inc-card-title">
                                <h3>Heading</h3>
                            </div>
                            <div className="inc-card-body">
                                Graph
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4" style={{display:"flex", gap:"20px"}}>
                    <div style={{flex:"1"}}>
                        <div className="inc-card" style={{border:"solid 1px #E4E4E4"}}>
                            <div className="inc-card-title">
                                <h3>Heading</h3>
                            </div>
                            <div className="inc-card-body">
                                Graph
                            </div>
                        </div>
                    </div>
                    <div style={{flex:"1"}}>
                        <div className="inc-card" style={{border:"solid 1px #E4E4E4"}}>
                            <div className="inc-card-title">
                                <h3>Heading</h3>
                            </div>
                            <div className="inc-card-body">
                                Graph
                            </div>
                        </div>
                    </div>
                </div>  

                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="inc-card" style={{border:"solid 1px #E4E4E4"}}>
                            <div className="inc-card-title">
                                <h3>Heading</h3>
                            </div>
                            <div className="inc-card-body">
                                Graph
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="inc-card" style={{border:"solid 1px #E4E4E4"}}>
                            <div className="inc-card-title">
                                <h3>Heading</h3>
                            </div>
                            <div className="inc-card-body">
                            <div className="inc-card-table">
                    <table className="full-table m-head action150">
                        <thead>
                            <tr>
                                <th>Report Name</th>
                                <th>Description</th>
                                <th>Schedule</th>
                                <th>Created On</th>
                                <th>Last Report</th>
                                <th>Next Report</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>                
                            <tr>
                                <td>CelestialCipher</td>
                                <td>Brwosing_Test</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>                  
                            </tr>
                            <tr>
                                <td>CelestialCipher</td>
                                <td>Brwosing_Test</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>                  
                            </tr>
                            <tr>
                                <td>CelestialCipher</td>
                                <td>Brwosing_Test</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>
                                    <td>N/A</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>                  
                            </tr>
                            <tr>
                                <td>CelestialCipher</td>
                                <td>Brwosing_Test</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>                  
                            </tr>
                            <tr>
                                <td>CelestialCipher</td>
                                <td>Brwosing_Test</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>30 Aug, 2025</td>                  
                            </tr>
                        </tbody>
                    </table>    
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{borderTop:"solid 1px #E4E4E4", padding:"20px", display:"flex", alignItems:"center", fontSize:"14px"}}>
                <div style={{width:"150px"}}>
                    {/* <p style={{margin:"0px"}}>Prepared By</p> */}
                    <img src={incLogo} alt="Inc Logo" style={{height:"30px"}} />
                </div>
                <div style={{flex:"1", justifyContent:"center"}}>
                    <p style={{margin:"0px", textAlign:"center"}}>Copyright &copy; 2025 - INC</p>
                </div>
                <div style={{display:"flex", justifyContent:"end", width:"150px"}}>
                    <span style={{display:"inline-block", width:"30px", height:"30px", borderRadius:"20px", background:"#E4E4E4", display:"flex", justifyContent:"center", alignItems:"center"}}>1</span>
                </div>
            </div>

        </div>
   </div>
  );
}

export default ReportsPDF;
