import React from 'react'
import Loader from '../common/Loader'
import Chat from '../common/chat'

function TenantManagement() {
 

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>Tenant</h3>
          <div className="inc-card-button">
            <button className="text-btn primary-btn" data-bs-toggle="modal" href="#tenantModalToggle" role="button">
              <i className="fa fa-plus" aria-hidden="true"></i> Add
            </button>
            <button
              className="icon-btn"
              title="Refresh"
              
            >
               <i className="fa fa-refresh" aria-hidden="true"></i>
            
            </button>
            <button className="icon-btn" title="Delete">
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
           
          </div>          
        </div>
        <div className="inc-card-body">

        <div className="inc-card-table">
          <table className='full-table m-head'>
            <thead>
              <tr>
                <th>
                  <label className="checkbox">
                    <input type="checkbox"/>
                    <span className="indicator"></span>
                  </label>
                </th>
                <th>Username</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Email</th>
                <th>Managed By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>StarLoom</td>
                  <td>Aurora</td>
                  <td>Lane</td>
                  <td>aurora@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>QuantumQuasar</td>
                  <td>Elias </td>
                  <td>Flynn</td>
                  <td>elias@example.com</td>
                  <td>Admin</td>
                  <td>Active</td> 
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td> 
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>MysticMuse</td>
                  <td>Serena </td>
                  <td>Morgan</td>
                  <td>serena@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>  
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>LunaLattice</td>
                  <td>Asher</td>
                  <td>Reid</td>
                  <td>asher@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td> 
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>EchoEnigma</td>
                  <td>Maya </td>
                  <td>Bennett</td>
                  <td>maya@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>  
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>CelestialCipher</td>
                  <td>Oliver </td>
                  <td>Hayes</td>
                  <td>oliver@example.com</td>
                  <td>Admin</td>
                  <td>Active</td> 
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>NovaNexus</td>
                  <td>Isla </td>
                  <td>Blake</td>
                  <td>isla@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>  
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>SereneSpectrum</td>
                  <td>Declan </td>
                  <td>Pierce</td>
                  <td>declan@example.com</td>
                  <td>Admin</td>
                  <td>Active</td> 
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>PhoenixPulse</td>
                  <td>Harper </td>
                  <td>Monroe</td>
                  <td>harper@example.com</td>
                  <td>Admin</td>
                  <td>Active</td>  
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="indicator"></span>
                    </label>
                  </td>
                  <td>EtherealEcho</td>
                  <td>Lucas </td>
                  <td>Rivers</td>
                  <td>lucas@example.com</td>
                  <td>Admin</td>
                  <td>Active</td> 
                  <td>
                    <div className="button-group">                              
                      <button title="Edit">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button title="Delete">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td> 
              </tr>
            </tbody>
          </table>
        </div>          
        
        </div>
        <div className="pagination-container">
           <div></div>
            <div className="pagination">
              <button>
                Previous
              </button>                
              <button>
                Next
              </button>
              <button>
                Last Page
              </button>
            </div>
        </div>
      </div>
      
      <div className="modal fade" id="tenantModalToggle" aria-hidden="true" aria-labelledby="tenantModalToggleLabel" tabindex="-1">
        <div className="modal-dialog modal-dialog-centered create-usr-pop">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Tenant</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

            <form>
              <div className="row">
                <div className="col-lg-6">
                  <label for="organization" className="col-form-label">Organization Name <span className="text-danger">*</span></label>
                  <input type="text" className="form-control " id="organization" name="organization" value="" />
                </div>
                <div className="col-lg-6">
                  <label for="address" className="col-form-label">Address<span className="text-danger">*</span></label>
                  <input type="text" className="form-control " id="address" name="address" value="" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label for="firstname" className="col-form-label">First Name<span className="text-danger">*</span></label>
                  <input type="text" className="form-control " id="firstname" name="firstname" value="" />
                </div>
                <div className="col-lg-6">
                  <label for="lastname" className="col-form-label">Last Name<span className="text-danger">*</span></label>
                  <input type="text" className="form-control " id="lastname" name="lastname" value="" />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <label for="phonenumber" className="col-form-label">Phone No<span className="text-danger">*</span></label>
                  <input type="text" className="form-control " id="phonenumber" name="phonenumber" value="" />
                </div>
                <div className="col-lg-3">
                  <label for="lastname" className="col-form-label">EDGE Support<span className="text-danger">*</span></label>
                  <select className='form-select'>
                    <option>
                      Yes
                    </option>
                    <option>
                      No
                    </option>
                  </select>
                </div>
                <div className="col-lg-3">
                  <label for="lastname" className="col-form-label">Status<span className="text-danger">*</span></label>
                  <select className='form-select'>
                    <option>
                      Active
                    </option>
                    <option>
                      Inactive
                    </option>
                  </select>
                </div>
              </div>
            </form>

              <div className="modal-footer pb-0">
                <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                <button type="button" className="btn btn-fill">Create</button>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
<Chat/>
    </div>
  )
}

export default TenantManagement
