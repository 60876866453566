import React, { useEffect, useState } from "react";
import OTAs from "../../../assets/images/ota-sc.png";
import OTAupi from "../../../assets/images/ota-upi.png";
import OTAupd from "../../../assets/images/ota-upd.png";
import OTAf from "../../../assets/images/ota-fl.png";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import errorIcon from "../../../assets/images/error-2.png";
import successIcon from "../../../assets/images/success_lg.png";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { all } from "axios";
import Chat from "../common/chat";

function OTAManagement() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [time, setTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [originalDetails, setOriginalDetails] = useState([]);
  const [originalListing, setOriginalListing] = useState([]);
  const [listing, setListing] = useState([]);
  const [originalScheduleList, setOriginalScheduleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firmwareFields, setFirmwareFields] = useState({
    protocolType: "",
    deviceType: "",
    modelName: "",
    version: "",
    firmware: "",
    modelFamily: "",
  });
  const [models, setModels] = useState([]);
  const [httpDevices, setHttpDevices] = useState([]);
  const [snmpDevices, setSnmpDevices] = useState([]);
  const [firmwareVersions, setFirmwareVersions] = useState([]);
  const [httpModelDetails, setHttpModelDetails] = useState(null);
  const [firmwareId, setFirmwareId] = useState("");
  const [snmpFamilies, setSnmpFamilies] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [scheduledHttpDevices, setScheduledHttpDevices] = useState(0);
  const [scheduledSnmpDevices, setScheduledSnmpDevices] = useState(0);
  const [pendingDevices, setPendingDevices] = useState(0);
  const [availableDevices, setAvailableDevices] = useState(0);
  const [updatedDevices, setupdatedDevices] = useState(0);
  const [failedDevices, setFailedDevices] = useState(0);
  const [snmpDeviceType, setSnmpDeviceType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [firmwaresPerPage] = useState(10);
  const [listingsPerPage] = useState(10);
  const [currentListPage, setCurrentListPage] = useState(1);
  const [currentSchedulePage, setCurrentSchedulePage] = useState(1);
  const [schedulesPerPage] = useState(10);
  const [scheduleModel, setScheduleModel] = useState(false);
  const [showFailurePopup, setShowFailurePopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showScheduleSuccessModal, setShowScheduleSuccessModal] =
    useState(false);
  const [showSuccessAddModal, setShowSuccessAddModal] = useState(false);
  const [showFirmwareFailurePopup, setShowFirmwareFailurePopup] =
    useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isOverviewActive, setIsOverviewActive] = useState("overview-tab");
  // const [scheduleModel, setScheduleModel] = useState(false);
  const [protocol, setProtocol] = useState("SNMP"); // Default protocol
  const [deviceFields, setDeviceFields] = useState({
    deviceType: "",
    deviceId: "",
    firmwareVersion: "",
    deviceName: "",
    startDate: null,
    startTime: null,
  });
  const [enableSchedule, setEnableSchedule] = useState(false);
  const [firmwareList, setFirmwareList] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduleOverview, setScheduleOverview] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    firmwareName: "asc",
    protocolType: "asc",
    deviceType: "asc",
    modelName: "asc",
    firmwareReleaseDate: "asc",
  });
  const [sortScheduleOrder, setSortScheduleOrder] = useState({
    deviceName: "asc",
    serialNumber: "asc",
    deviceType: "asc",
    currentFirmware: "asc",
    newFirmware: "asc",
    updateStatus: "asc",
  });
  const [sortOverviewOrder, setSortOverviewOrder] = useState({
    deviceName: "asc",
    deviceModel: "asc",
    protocol: "asc",
    firmwareCurrentVersion: "asc",
    firmwareNewVersion: "asc",
    updateStatus: "asc",
    health: "asc",
    nextStep: "asc",
  });

  useEffect(() => {
    firmwareDetails();
    overviewDetails();
    fetchModels();
    getSnmpFamily();
    getSnmpDeviceType();
    getScheduleList();
  }, []);

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
  };

  const handleAddFirmwareClick = () => {
    setIsModalOpen(true);
  };
  const handleSave = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEnableSchedule = () => {
    setEnableSchedule((prev) => !prev);
  };

  // const handlestartDateTimeChange = (field, value) => {
  //   setStartDateTimeRange((prev) => ({ ...prev, [field]: value }));
  // };
  const handleProtocolTypeChange = (e) => {
    const selectedProtocol = e.target.value;
    console.log("Protocol changed to:", selectedProtocol);
    setProtocol(selectedProtocol);

    // Reset device fields
    setDeviceFields({
      deviceType: "",
      deviceId: "",
      firmwareVersion: "",
      deviceName: "",
    });
  };

  const handleDeviceFieldChange = (field, value) => {
    setDeviceFields((prev) => ({
      ...prev,
      [field]: value,
    }));
    console.log(`Field ${field} updated to:`, value);
  };

  async function firmwareDetails() {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest("api/firmware/list");
      if (response) {
        const filteredFirmware = response.filter(
          (firmware) => firmware.protocolType === "SNMP"
        );
        setFirmwareVersions(filteredFirmware);
        setDetails(response);
        setOriginalDetails(response);
        console.log("details", response[0].firmwareId);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching firmware data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function overviewDetails() {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/firmware/firmwareUpdateStatus"
      );
      if (response) {
        // Filter by protocol type
        const filteredHttpDevices = response.filter(
          (item) => item.protocol === "HTTP"
        );
        const filteredSnmpDevices = response.filter(
          (item) => item.protocol === "SNMP"
        );

        // Filter for scheduled and pending statuses
        const scheduledHttpDevices = response.filter(
          (item) => item.updateStatus === "Success"
        ).length;
        const scheduledSnmpDevices = response.filter((item) =>
          item.updateStatus.includes("Scheduled")
        ).length;

        const pendingDevices = response.filter(
          (item) => item.updateStatus === "Pending"
        ).length;
        const availableDevices = response.filter(
          (item) => item.updateStatus === "Available"
        ).length;

        const failedDevices = response.filter(
          (item) => item.updateStatus === "Failed"
        ).length;

        const updatedDevices = response.filter(
          (item) => item.updateStatus === "Up-to-date"
        ).length;
        // Set the filtered data in state updatedDevices
        setHttpDevices(filteredHttpDevices);
        setSnmpDevices(filteredSnmpDevices);

        // Set the complete listing if needed
        setOriginalListing(response);
        setListing(response);
        setScheduledHttpDevices(scheduledHttpDevices);
        setScheduledSnmpDevices(scheduledSnmpDevices);
        setPendingDevices(pendingDevices);
        setAvailableDevices(availableDevices);
        setupdatedDevices(updatedDevices);
        setFailedDevices(failedDevices);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching overview data:", error);
    } finally {
      setLoading(false);
    }
  }

  const fetchModels = async (deviceType) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/model-list?deviceType=${deviceType}`
      );
      if (response) {
        const modelsList = response.map((model) => ({
          value: model,
          label: model,
        }));
        setModels(modelsList);
      }
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  };

  const fetchModelDetails = async (deviceType, modelName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/getHttpModel?deviceType=${deviceType}&modelName=${modelName}`
      );
      setHttpModelDetails(response);
    } catch (error) {
      console.error("Error fetching model details:", error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Check file size (e.g., limit to 100MB)
      if (file.size > 100 * 1024 * 1024) {
        alert("File size exceeds the limit of 100MB!");
        return;
      }
      setFirmwareFields({
        ...firmwareFields,
        firmware: file,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFirmwareFields({ ...firmwareFields, [name]: value });
  };

  const handleModelChange = (e) => {
    const { name, value } = e.target;

    setFirmwareFields({
      ...firmwareFields,
      [name]: value,
    });

    const selectedProtocol = firmwareFields.protocolType;

    if (name === "deviceType") {
      fetchModels(value, selectedProtocol);
    } else if (name === "modelName" && firmwareFields.deviceType) {
      fetchModelDetails(firmwareFields.deviceType, value, selectedProtocol);
    }
  };

  const handleProtocolChange = (e) => {
    const { name, value } = e.target;
    setFirmwareFields({ ...firmwareFields, [name]: value });
  };

  const addFirmware = async () => {
    if (!firmwareFields.modelName || !firmwareFields.firmware) {
      alert("Please fill in all required fields and select a file!");
      return;
    }

    try {
      const api = new allApi();
      const formData = new FormData();
      formData.append("firmware", firmwareFields.firmware);
      formData.append("modelName", firmwareFields.modelName);

      if (firmwareFields.protocolType === "HTTP") {
        if (!firmwareFields.version || !firmwareFields.deviceType) {
          alert("Please fill in all fields for HTTP protocol!");
          return;
        }
        formData.append("version", firmwareFields.version);
        formData.append("deviceType", firmwareFields.deviceType);
        console.log("formdata", formData);
        const response = await api.postMultiRequest(
          "api/firmware/addHttpFirmware",
          formData
        );

        if (response && response.success) {
          setSuccessMessage("HTTP Firmware uploaded successfully!");
          setIsModalOpen(false);
          setTimeout(() => setSuccessMessage(""), 3000);
          setShowSuccessAddModal(true);
        }
      } else if (firmwareFields.protocolType === "SNMP") {
        formData.append("version", firmwareFields.version);
        formData.append("deviceType", firmwareFields.deviceType);
        console.log("formData", formData);
        const response = await api.postMultiRequest(
          "api/firmware/addSnmpFirmware",
          formData
        );

        if (response && response.success) {
          setIsModalOpen(false);
          setShowSuccessAddModal(true);
          setSuccessMessage("SNMP Firmware uploaded successfully!");
          setTimeout(() => setSuccessMessage(""), 3000);
        }
      }
      setFirmwareFields({
        protocolType: "",
        modelName: "",
        version: "",
        firmware: null,
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding firmware details", error);
      setShowFirmwareFailurePopup(true);
    }
  };

  const fetchFirmwareVersions = async (modelName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/firmware/snmpVersions?modelName=${modelName}`
      );
      if (response) {
        console.log(`Fetched firmware versions for ${modelName}:`, response);
        // setFirmwareVersions((prev) => ({
        //   ...prev,
        //   [modelName]: response,
        // }));
        setFirmwareVersions(response);
      }
    } catch (error) {
      console.error("Error fetching firmware versions:", error);
    }
  };

  const handleVersionChange = (deviceId, selectedFirmwareId) => {
    setListing((prevListing) =>
      prevListing.map((device) =>
        device.deviceId === deviceId
          ? {
              ...device,
              firmware_id: selectedFirmwareId,
            }
          : device
      )
    );
  };

  const handleupdateClick = async (device) => {
    const api = new allApi();
    console.log(device, "device");
    try {
      const scheduleTime = new Date().toISOString();

      if (device.protocol === "http") {
        const payload = {
          deviceId: device.deviceId,
        };

        const response = await api.postRequest(
          "api/firmware/httpFirmwareUpgrade",
          "",
          payload
        );

        if (response) {
          console.log("HTTP Device Firmware Upgraded Successfully!");
          // Open the modal upon successful response
          setShowSuccessModal(true);
        } else {
          console.log("Failed to upgrade HTTP firmware. Please try again.");
          console.log("showFailurePopup:", showFailurePopup);
          setShowFailurePopup(true);
        }
      } else if (device.protocol === "SNMP") {
        console.log(firmwareVersions, "kkk");
        let selectedFirmware = firmwareVersions.find(
          (item) => item.deviceId === device.deviceId
        )?.firmwareId; // Using find to get the firmwareId

        const payload = {
          device_id: device.deviceId,
          firmware_id: selectedFirmware,
          schedule_time: scheduleTime,
        };

        const response = await api.postRequest(
          "api/firmware/snmpFirmwareUpgrade",
          "",
          payload
        );

        if (response) {
          console.log("SNMP Device Firmware Upgraded Successfully!");
          // Open the modal upon successful response
          setShowSuccessModal(true);
        } else {
          console.log(
            `SNMP Firmware Upgrade Failed: ${
              response.message || "Unknown error"
            }`
          );
          console.log("showFailurePopup:", showFailurePopup);
          setShowFailurePopup(true);
        }
      } else {
        console.log("Unsupported protocol type for firmware upgrade.");
      }
    } catch (error) {
      console.error("Error upgrading firmware:", error);
      setShowFailurePopup(true);
    }
  };

  const handleDownloadClick = async (remoteFilePath) => {
    try {
      const api = new allApi();
      const downloadUrl = `api/firmware/downloadFile?remoteFilePath=features.csv&downloadPath=/home/siddhartha/Downloads/file1001 `;
      const response = await api.getRequest(downloadUrl);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", remoteFilePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading firmware file:", error);
    }
  };

  const getSnmpFamily = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/model/snmpFamilyModels");
      if (response) {
        setSnmpFamilies(response);
      }
    } catch (error) {
      console.error("Failed to get SNMP family", error);
    }
  };

  const getSnmpDeviceType = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/firmware/snmpDeviceType");
      console.log("API response:", response); // Log response to see the data
      if (Array.isArray(response)) {
        setSnmpDeviceType(response); // Set response array to state
      }
    } catch (error) {
      console.log("Error fetching device type", error);
    }
  };

  // Handle dropdown value change
  const handleDeviceType = (e) => {
    const { name, value } = e.target;
    setFirmwareFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handlesnmpFamily = (e) => {
    const familyName = e.target.value;
    setFirmwareFields({
      ...firmwareFields,
      modelFamily: familyName,
    });
    if (familyName) {
      fetchSnmpModelsForFamily(familyName);
    }
  };

  const fetchSnmpModelsForFamily = async (familyName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/modelsForFamily?family_name=${familyName}`
      );
      if (response) {
        setModels(response);
      }
    } catch (error) {
      console.error("Error fetching SNMP models for family:", error);
    }
  };

  const httpFWSchedule = (device) => {
    try {
      const api = new allApi();
      // const scheduleTime = startDate
      //   ? startDate.toISOString().slice(0, 16)
      //   : null;
      const selectedDevice = getDevices().find(
        (item) => item.deviceId === device.deviceId
      );
      const payload = {
        deviceId: selectedDevice.deviceId,
        scheduleTime: scheduleTime || null,
      };
      const response = api.postRequest(
        "api/firmware/httpFirmwareUpgrade",
        "",
        payload
      );
      if (response) {
        handleScheduleCancel();
        setEnableSchedule(false);
        setScheduleModel(false);
        setShowScheduleSuccessModal(true);
      } else {
        setShowFailurePopup(true);
      }
    } catch (error) {
      console.error("error scheduling firmware", error);
    }
  };

  const snmpFWSchedule = async (device) => {
    try {
      const api = new allApi();
      const selectedDevice = getDevices().find(
        (item) => item.deviceId === device.deviceId
      );
      const firmwareId = firmwareVersions.find(
        (fw) => fw.firmwareVersion === device.firmwareVersion
      )?.firmwareId;
      console.log("firmwareId", firmwareId);
      console.log("scheduleTime", scheduleTime);
      const payload = {
        device_id: selectedDevice.deviceId,
        firmware_id: firmwareId,
        schedule_time: scheduleTime || null,
      };
      const response = await api.postRequest(
        "api/firmware/snmpFirmwareUpgrade",
        "",
        payload
      );
      if (response) {
        handleScheduleCancel();
        setEnableSchedule(false);
        setScheduleModel(false);
        setShowScheduleSuccessModal(true);
      } else {
        setShowFailurePopup(true);
      }
    } catch (error) {
      console.error("Error scheduling Snmp Firmware", error);
    }
  };

  // State to store the combined schedule time

  // Handler to combine date and time
  const handleDateChange = (newStartDate) => {
    setStartDate(newStartDate); // Update selected date
    if (startTime) {
      combineDateTime(newStartDate, startTime); // Combine if time is already selected
    }
  };

  // Handler for time selection
  const handleTimeChange = (newStartTime) => {
    setStartTime(newStartTime); // Update selected time
    if (startDate) {
      combineDateTime(startDate, newStartTime); // Combine if date is already selected
    }
  };

  // Function to combine date and time into a single ISO string
  const combineDateTime = (date, time) => {
    // Ensure both date and time are selected
    if (!date || !time) return;

    // Combine date and time using dayjs
    const combinedDateTime = dayjs()
      .year(date.year())
      .month(date.month())
      .date(date.date())
      .hour(time.hour())
      .minute(time.minute());

    // Format the combined date-time as a string for the payload
    const formattedScheduleTime = combinedDateTime.format("YYYY-MM-DDTHH:mm");

    setScheduleTime(formattedScheduleTime); // Update the state
    console.log("Schedule Time:", formattedScheduleTime); // Debug
  };

  const getDeviceTypes = () => {
    const devices = protocol === "SNMP" ? snmpDevices : httpDevices;
    console.log("Device types for protocol:", protocol, devices);
    return [...new Set(devices.map((device) => device.deviceType))];
  };

  const getDevices = () => {
    const devices = protocol === "SNMP" ? snmpDevices : httpDevices;
    const filteredDevices = devices.filter(
      (device) => device.deviceType === deviceFields.deviceType
    );
    console.log("Filtered devices:", filteredDevices);
    return filteredDevices;
  };

  const handleDeviceSelection = (deviceId) => {
    const selectedDevice = getDevices().find(
      (device) => device.deviceId === deviceId
    );
    if (selectedDevice) {
      handleDeviceFieldChange("deviceId", deviceId);
      handleDeviceFieldChange("deviceName", selectedDevice.deviceName);
    }
  };

  const handleScheduleCancel = () => {
    setDeviceFields({
      deviceType: "",
      deviceId: "",
      firmwareVersion: "",
      deviceName: "",
    });
    setEnableSchedule(false);
    setScheduleModel(false);
  };

  const getScheduleList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/firmware/firmwareScheduleList"
      );
      if (response && Array.isArray(response)) {
        setOriginalScheduleList(response);
        setScheduleList(response);
      }
    } catch (error) {
      console.error("Error fetching schedule list", error);
    }
  };

  const indexOfLastListing = currentListPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const totalFilteredListings = listing.length;
  const currentListings = listing.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  const indexOfLastFirmware = currentPage * firmwaresPerPage;
  const indexOfFirstFirmwares = indexOfLastFirmware - firmwaresPerPage;
  const totalFilteredFirmwares = details.length;
  const currentDetails = details.slice(
    indexOfFirstFirmwares,
    indexOfLastFirmware
  );
  const indexOfLastSchedule = currentSchedulePage * schedulesPerPage;
  const indexOfFirstSchedule = indexOfLastSchedule - schedulesPerPage;
  const totalFilteredSchedules = scheduleList.length;
  const currentScheduleList = scheduleList.slice(
    indexOfFirstSchedule,
    indexOfLastSchedule
  );
  const totalSchedulePages = Math.ceil(
    totalFilteredSchedules / schedulesPerPage
  );
  const totalPages = Math.ceil(totalFilteredFirmwares / firmwaresPerPage);
  const totalListPage = Math.ceil(totalFilteredListings / listingsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleListNextPage = () => {
    if (currentListPage < totalListPage) {
      setCurrentListPage(currentListPage + 1);
    }
  };
  const handleScheduleNextPage = () => {
    if (currentSchedulePage < totalSchedulePages) {
      setCurrentSchedulePage(currentSchedulePage + 1);
    }
  };
  const handleFirstPage = () =>{
    setCurrentPage(1);
  };
  const handleScheduleFirstPage = () => {
    setCurrentSchedulePage(1);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleListFirstPage = () =>{
    setCurrentListPage(1);
  }
  const handleListPrevPage = () => {
    if (currentListPage > 1) {
      setCurrentListPage(currentListPage - 1);
    }
  };
  const handleSchedulePrevPage = () => {
    if (currentSchedulePage > 1) {
      setCurrentSchedulePage(currentSchedulePage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handleListLastPage = () => {
    setCurrentListPage(totalListPage);
  };
  const handleScheduleLastPage = () => {
    setCurrentSchedulePage(totalSchedulePages);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handleListPageClick = (page) => {
    setCurrentListPage(page);
  };
  const handleSchedulePageClick = (page) => {
    setCurrentSchedulePage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };
  const getPageNum = () => {
    const range = 1;
    const start = Math.max(1, currentListPage - range);
    const end = Math.min(totalListPage, currentListPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };
  const getPage = () => {
    const range = 1;
    const start = Math.max(1, currentSchedulePage - range);
    const end = Math.min(totalSchedulePages, currentSchedulePage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const handleSortChange = (field) => {
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";
    const sortedFirmwares = [...details].sort((a, b) => {
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setDetails(sortedFirmwares);
    setSortOrder((prevState) => ({
      ...prevState,
      [field]: newOrder,
    }));
  };
  const handleSortScheduleChange = (field) => {
    const newOrder = sortScheduleOrder[field] === "asc" ? "desc" : "asc";
    const sortedSchedules = [...scheduleList].sort((a, b) => {
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setScheduleList(sortedSchedules);
    setSortScheduleOrder((prevState) => ({
      ...prevState,
      [field]: newOrder,
    }));
  };

  const handleSortOverviewChange = (field) => {
    const newOrder = sortOverviewOrder[field] === "asc" ? "desc" : "asc";
    const sortedOverview = [...listing].sort((a, b) => {
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setListing(sortedOverview);
    setSortOverviewOrder((prevState) => ({
      ...prevState,
      [field]: newOrder,
    }));
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setDetails(originalDetails);
    } else {
      const filtered = originalDetails.filter((detail) =>
        detail[name]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setDetails(filtered);
    }
    setCurrentPage(1);
  };
  const handleScheduleSearch = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setScheduleList(originalScheduleList);
    } else {
      const filtered = originalScheduleList.filter((schedule) =>
        schedule[name]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setScheduleList(filtered);
    }
    setCurrentSchedulePage(1);
  };
  const handleOverviewSearch = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setListing(originalListing);
    } else {
      const filtered = originalListing.filter((device) =>
        device[name]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setListing(filtered);
    }
    setCurrentListPage(1);
  };

  const handleTabClick = (tabId) => {
    setIsOverviewActive(tabId);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    isOverviewActive === "overview-tab" ? "active" : ""
                  }`}
                  id="overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#overview"
                  type="button"
                  role="tab"
                  aria-controls="overview"
                  aria-selected={isOverviewActive}
                  onClick={() => handleTabClick("overview-tab")}
                >
                  Overview
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    isOverviewActive === "firmware-tab" ? "active" : ""
                  }`}
                  id="firmware-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#firmware"
                  type="button"
                  role="tab"
                  aria-controls="firmware"
                  aria-selected={isOverviewActive === "firmware-tab"}
                  onClick={() => handleTabClick("firmware-tab")}
                >
                  Firmware
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    isOverviewActive === "scheduled-tab" ? "active" : ""
                  }`}
                  id="scheduled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduled"
                  type="button"
                  role="tab"
                  aria-controls="scheduled"
                  aria-selected={isOverviewActive === "scheduled-tab"}
                  onClick={() => handleTabClick("scheduled-tab")}
                >
                  Scheduled
                </button>
              </li>
            </ul>
            {isOverviewActive === "overview-tab" && (
              <div className="inc-card-button">
                {/* <button className="text-btn primary-btn">updates</button> */}
                <button
                  className="text-btn primary-btn"
                  onClick={() => setScheduleModel(true)}
                >
                  Schedule
                </button>
                <button
                  className="icon-btn"
                  title="Refresh"
                  onClick={() => overviewDetails()}
                >
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                <div className="ota-graphics">
                  <div className="ota-card dtype-count">
                    <div>
                      <strong>{snmpDevices.length}</strong>
                      <span className="snmp">SNMP</span>
                    </div>
                    <div>
                      <strong>{httpDevices.length}</strong>
                      <span className="https">HTTPS</span>
                    </div>
                    <div>
                      <strong>0</strong>
                      <span className="mqtt">MQTT</span>
                    </div>
                    <div>
                      <strong>0</strong>
                      <span className="tr069">TR-069</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="sc">
                        <img src={OTAs} alt="Device Schedule" />
                      </span>
                    </div>
                    <div>
                      <strong>
                        {scheduledHttpDevices + scheduledSnmpDevices}
                      </strong>
                      <span>Schedule</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="upi">
                        <img src={OTAupi} alt="Device Updating" />
                      </span>
                    </div>
                    <div>
                      <strong>{pendingDevices}</strong>
                      <span>Pending</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="upi">
                        <img src={OTAupi} alt="Device Updating" />
                      </span>
                    </div>
                    <div>
                      <strong>{availableDevices}</strong>
                      <span>Available</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="fl">
                        <img src={OTAf} alt="Device Failed" />
                      </span>
                    </div>
                    <div>
                      <strong>{failedDevices}</strong>
                      <span>Failed</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="upd">
                        <img src={OTAupd} alt="Device updated" />
                      </span>
                    </div>
                    <div>
                      <strong>{updatedDevices}</strong>
                      <span>Up-to-date</span>
                    </div>
                  </div>
                </div>
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>Device Name</th>
                          <th>Model</th>
                          <th>Protocol Type</th>
                          <th>Current Firmware</th>
                          <th>Available Firmware</th>
                          <th>update Status</th>
                          <th>Health</th>
                          <th>Next Steps</th>
                          {/* <th>Action</th> */}
                        </tr>
                        <tr className="search-border">
                          {/* <td></td> */}
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              onKeyUp={handleOverviewSearch}
                            />
                            <i
                              className={`fa ${
                                sortOverviewOrder.deviceName === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("deviceName")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceModel"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOverviewOrder.deviceModel === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("deviceModel")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="protocol"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOverviewOrder.protocol === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("protocol")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="firmwareCurrentVersion"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOverviewOrder.firmwareCurrentVersion === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange(
                                  "firmwareCurrentVersion"
                                )
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="firmwareNewVersion"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortOverviewOrder.firmwareNewVersion === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("firmwareNewVersion")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="updateStatus"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortOverviewOrder.updateStatus === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("updateStatus")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="health"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortOverviewOrder.health === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() => handleSortOverviewChange("health")}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="nextStep"
                              onKeyUp={(e) => handleOverviewSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortOverviewOrder.nextStep === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortOverviewChange("nextStep")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      {loading ? (
                        <Loader />
                      ) : (
                        <tbody>
                          {currentListings.map((device, index) => (
                            <tr key={index}>
                              <td>{device.deviceName || "N/A"}</td>
                              <td>{device.deviceModel || "N/A"}</td>
                              <td>
                                {(device.protocol || "N/A").toUpperCase()}
                              </td>
                              <td>{device.firmwareCurrentVersion || "N/A"}</td>
                              <td>
                                {device.protocol === "SNMP" ? (
                                  <select
                                    className="form-select"
                                    style={{ width: "100px" }}
                                    value={device.firmware_id || "N/A"}
                                    // onChange={(e) =>
                                    //   handleVersionChange(
                                    //     device.deviceId,
                                    //     e.target.value
                                    //   )
                                    // }
                                    onFocus={() =>
                                      !firmwareVersions[device.deviceModel] &&
                                      fetchFirmwareVersions(device.deviceModel)
                                    }
                                  >
                                    <option value="N/A" disabled>
                                      {device.firmwareNewVersion || "N/A"}
                                    </option>
                                    {firmwareVersions[device.deviceModel]?.map(
                                      (version) => (
                                        <option
                                          key={version.firmwareId}
                                          value={version.firmwareId}
                                        >
                                          {version.firmwareVersion}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : (
                                  device.firmwareNewVersion || "N/A"
                                )}
                              </td>
                              <td>{device.updateStatus || "N/A"}</td>
                              <td>
                                <span
                                  className={
                                    "d-status " +
                                    (device?.health?.toLowerCase() === "offline"
                                      ? "offline"
                                      : device?.health?.toLowerCase() ===
                                        "online"
                                      ? "online"
                                      : "")
                                  }
                                >
                                  {device.health}
                                </span>
                              </td>
                              <td>
                                {device.nextStep === "Update Now" ? (
                                  <button
                                    className="text-btn primary-btn"
                                    title="Update Now"
                                    onClick={() => handleupdateClick(device)}
                                  >
                                    update Now
                                  </button>
                                ) : (
                                  <button
                                    className="text-btn primary-btn"
                                    disabled
                                  >
                                    update Now
                                  </button>
                                )}
                              </td>
                              {/* <td>
                              <button
                                className="icon-btn"
                                title="Download"
                                onClick={() =>
                                  handleDownloadClick(device.remoteFilePath)
                                }
                              >
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className="pagination-container">
                {totalFilteredListings > 0 ? (
                  <>
                  <div className="pagination-info">
                    
                      <p>
                        Showing {indexOfFirstListing + 1}
                        {"-"}
                        {Math.min(
                          currentListPage * listingsPerPage,
                          totalFilteredListings
                        )}{" "}
                        of {totalFilteredListings} records
                      </p>
                   
                  </div>
                  <div className="pagination">
                  <button
                      onClick={handleListFirstPage}
                      disabled={currentListPage === 1}
                    >
                      First Page
                    </button>
                    <button
                      onClick={handleListPrevPage}
                      disabled={currentListPage === 1}
                    >
                      Previous
                    </button>
                    {getPageNum().map((page) => (
                      <button
                        key={page}
                        onClick={() => handleListPageClick(page)}
                        className={currentListPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleListNextPage}
                      disabled={currentListPage === totalListPage}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleListLastPage}
                      disabled={currentListPage === totalListPage}
                    >
                      Last Page
                    </button>
                  </div>
                  </>
                   ) : (
                    <div>
                    <p>No Records Available</p>
                    </div>
                  )}
                </div>

                {/* Schedule Modal */}
                {scheduleModel && (
                  <div className="customModal">
                    <div className="c-modal-dialog modal-dialog-centered small">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Firmware Schedule</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleScheduleCancel}
                          ></button>
                        </div>
                        <div className="modal-body add-ssid-body">
                          {/* Protocol Type Selection */}
                          <div className="row mb-2">
                            <div className="col-lg-6">
                              <label
                                htmlFor="ProtocolType"
                                className="col-form-label"
                              >
                                Protocol Type
                              </label>
                              <select
                                className="form-select"
                                name="protocol"
                                value={protocol}
                                onChange={handleProtocolTypeChange}
                              >
                                <option value="SNMP">SNMP</option>
                                <option value="http">HTTP</option>
                                <option value="MQTT">MQTT</option>
                              </select>
                            </div>
                          </div>

                          <div className="border-line"></div>

                          {/* Dynamic Fields Based on Protocol */}
                          {protocol === "SNMP" && (
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                <label
                                  htmlFor="DeviceType"
                                  className="col-form-label"
                                >
                                  Device Type
                                </label>
                                <select
                                  className="form-select"
                                  value={deviceFields.deviceType}
                                  onChange={(e) =>
                                    handleDeviceFieldChange(
                                      "deviceType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Type</option>
                                  {getDeviceTypes().map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="DeviceId"
                                  className="col-form-label"
                                >
                                  Devices
                                </label>
                                <select
                                  className="form-select"
                                  value={deviceFields.deviceId}
                                  onChange={(e) =>
                                    handleDeviceFieldChange(
                                      "deviceId",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Device</option>
                                  {getDevices().map((device) => (
                                    <option
                                      key={device.deviceId}
                                      value={device.deviceId}
                                    >
                                      {device.deviceName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="FirmwareVersion"
                                  className="col-form-label"
                                >
                                  Select Version
                                </label>
                                <select
                                  className="form-select"
                                  value={deviceFields.firmwareVersion}
                                  onChange={(e) =>
                                    handleDeviceFieldChange(
                                      "firmwareVersion",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Version</option>
                                  {firmwareVersions.map((firmware) => (
                                    <option
                                      key={firmware.firmwareId}
                                      value={firmware.firmwareVersion}
                                    >
                                      {firmware.firmwareVersion}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          {protocol === "http" && (
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                <label
                                  htmlFor="DeviceType"
                                  className="col-form-label"
                                >
                                  Device Type
                                </label>
                                <select
                                  className="form-select"
                                  value={deviceFields.deviceType}
                                  onChange={(e) =>
                                    handleDeviceFieldChange(
                                      "deviceType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Type</option>
                                  {getDeviceTypes().map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="DeviceId"
                                  className="col-form-label"
                                >
                                  Devices
                                </label>
                                <select
                                  className="form-select"
                                  value={deviceFields.deviceId}
                                  onChange={(e) =>
                                    handleDeviceSelection(e.target.value)
                                  }
                                >
                                  <option value="">Select Device</option>
                                  {getDevices().map((device) => (
                                    <option
                                      key={device.deviceId}
                                      value={device.deviceId}
                                    >
                                      {device.deviceName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          {/* Firmware Schedule and startDate-Time Pickers */}
                          <div className="row mb-3 mt-4">
                            <div className="col-lg-6 mt-2">
                              <div className="switch-box">
                                <strong>Firmware Schedule</strong>
                                <label className="ice-switch">
                                  <input type="checkbox" />
                                  <span
                                    className="slider"
                                    onClick={handleEnableSchedule}
                                  ></span>
                                </label>
                              </div>
                            </div>
                            {enableSchedule && (
                              <div className="col-lg-6">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <div className="row">
                                    {/* Start Date & Time */}
                                    <div className="col-lg-6">
                                      <h4>Date</h4>
                                      <DatePicker
                                        //label="Select Start Date"
                                        value={deviceFields.startDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </div>
                                    <div className="col-lg-6">
                                      <h4>Time</h4>
                                      <TimePicker
                                        //label="Start Time"
                                        value={deviceFields.startTime}
                                        onChange={handleTimeChange}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </LocalizationProvider>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline"
                            onClick={handleScheduleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="text-btn primary-btn"
                            onClick={() => {
                              console.log(
                                "Save button clicked, deviceFields:",
                                deviceFields
                              );
                              if (protocol == "SNMP") {
                                snmpFWSchedule(deviceFields);
                              } else {
                                httpFWSchedule(deviceFields);
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="firmware"
                role="tabpanel"
                aria-labelledby="firmware-tab"
              >
                <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button
                      className="text-btn primary-btn"
                      onClick={handleAddFirmwareClick}
                    >
                      Add Firmware
                    </button>
                    <button
                      className="icon-btn"
                      title="Refresh"
                      onClick={() => firmwareDetails()}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>

                {/* ===============Add FIRMWARE---------- */}
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}

                {isModalOpen && (
                  <div className="customModal">
                    <div className="c-modal-dialog modal-dialog-centered small">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add Firmware</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleCancel}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="add-ssid-body">
                            <form>
                              <div className="row d-flex">
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="protocolType"
                                    className="col-form-label"
                                  >
                                    Protocol Type
                                  </label>
                                  <select
                                    className="form-select"
                                    name="protocolType"
                                    value={firmwareFields.protocolType}
                                    onChange={handleProtocolChange}
                                  >
                                    <option value="">
                                      Select Protocol Type
                                    </option>
                                    <option value="HTTP">HTTP</option>
                                    <option value="SNMP">SNMP</option>
                                  </select>
                                </div>

                                {/* HTTP-specific fields */}
                                {firmwareFields.protocolType === "HTTP" && (
                                  <>
                                    {/* Device Type for HTTP */}
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="deviceType"
                                        className="col-form-label"
                                      >
                                        Device Type
                                      </label>
                                      <select
                                        className="form-select"
                                        name="deviceType"
                                        value={firmwareFields.deviceType}
                                        onChange={handleModelChange}
                                      >
                                        <option value="">
                                          Select Device Type
                                        </option>
                                        <option value="AP">AP</option>
                                        <option value="Switch">Switch</option>
                                        <option value="Router">Router</option>
                                      </select>
                                    </div>

                                    {/* Model Name for HTTP */}
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="modelName"
                                        className="col-form-label"
                                      >
                                        Model Name
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelName"
                                        value={firmwareFields.modelName}
                                        onChange={handleModelChange}
                                      >
                                        <option value="">Select Model</option>
                                        {models.map((model, index) => (
                                          <option
                                            key={index}
                                            value={model.value}
                                          >
                                            {model.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </>
                                )}

                                {/* Common fields for both SNMP and HTTP */}
                                {(firmwareFields.protocolType === "SNMP" ||
                                  firmwareFields.protocolType === "HTTP") && (
                                  <>
                                    {/* Model Family */}
                                    {/* <div className="col-lg-6">
                                      <label
                                        htmlFor="modelFamily"
                                        className="col-form-label"
                                      >
                                        Model Family
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelFamily"
                                        value={firmwareFields.modelFamily}
                                        onChange={handlesnmpFamily}
                                      >
                                        <option value="">Select Family</option>
                                        {snmpFamilies.map((family, index) => (
                                          <option key={index} value={family}>
                                            {family}
                                          </option>
                                        ))}
                                      </select>
                                    </div> */}

                                    {/* Version Name (only for HTTP) */}
                                    {firmwareFields.protocolType === "HTTP" && (
                                      <div className="col-lg-6">
                                        <label
                                          htmlFor="version"
                                          className="col-form-label"
                                        >
                                          Version Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="version"
                                          value={firmwareFields.version}
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* SNMP-specific Model Name */}
                                {firmwareFields.protocolType === "SNMP" && (
                                  <>
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="modelName"
                                        className="col-form-label"
                                      >
                                        Device Type
                                      </label>
                                      <select
                                        className="form-select"
                                        name="deviceType"
                                        value={firmwareFields.deviceType}
                                        onChange={handleDeviceType}
                                      >
                                        <option value="">Select Model</option>
                                        {/* Map over the snmpDeviceType array to create options */}
                                        {snmpDeviceType.length > 0 ? (
                                          snmpDeviceType.map(
                                            (deviceType, index) => (
                                              <option
                                                key={index}
                                                value={deviceType}
                                              >
                                                {deviceType}
                                              </option>
                                            )
                                          )
                                        ) : (
                                          <option value="">
                                            No device types available
                                          </option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="modelName"
                                        className="col-form-label"
                                      >
                                        Model Name
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelName"
                                        value={firmwareFields.modelName}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select Model</option>
                                        <option value="ion4l3s_d">
                                          ion4l3s_d
                                        </option>
                                        <option value="ion4l3j">ion4l3j</option>
                                        <option value="ion4l3s_d">
                                          ion4l3s_d
                                        </option>
                                        <option value="ion4l3j">ion4l3j</option>
                                        <option value="ion4l3_ext">
                                          ion4l3_ext{" "}
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="version"
                                        className="col-form-label"
                                      >
                                        Version Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="version"
                                        value={firmwareFields.version}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </>
                                )}

                                {/* Choose Firmware File */}
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Choose Firmware File
                                  </label>
                                  <input
                                    className="form-control"
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="text-btn primary-btn"
                            title="Upload"
                            onClick={addFirmware}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Version</th>
                          <th>Model</th>
                          <th>Device Type</th>
                          <th>Release Date</th>
                        </tr>
                        <tr className="search-border">
                          {/* <td></td> */}
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="firmwareName"
                              onKeyUp={handleSearch}
                            />
                            <i
                              className={`fa ${
                                sortOrder.firmwareName === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() => handleSortChange("firmwareName")}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="firmwareVersion"
                              onKeyUp={(e) => handleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOrder.firmwareVersion === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortChange("firmwareVersion")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="modelName"
                              onKeyUp={(e) => handleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOrder.modelName === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() => handleSortChange("modelName")}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceType"
                              onKeyUp={(e) => handleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortOrder.deviceType === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() => handleSortChange("deviceType")}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="firmwareReleaseDate"
                              onKeyUp={(e) => handleSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortOrder.firmwareReleaseDate === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortChange("firmwareReleaseDate")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      {loading ? (
                        <Loader />
                      ) : (
                        <tbody>
                          {currentDetails.map((detail, index) => (
                            <tr key={index}>
                              <td>{detail.firmwareName || "N/A"}</td>
                              <td>{detail.firmwareVersion || "N/A"}</td>
                              <td>{detail.modelName || "N/A"}</td>
                              <td>{detail.deviceType || "N/A"}</td>
                              <td>{detail.firmwareReleaseDate || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className="pagination-container">
                {totalFilteredFirmwares > 0 ? (
                  <>
                  <div className="pagination-info">
                   
                      <p>
                        Showing {indexOfFirstFirmwares + 1}
                        {"-"}
                        {Math.min(
                          currentPage * firmwaresPerPage,
                          totalFilteredFirmwares
                        )}{" "}
                        of {totalFilteredFirmwares} records
                      </p>
                    
                  </div>
                  <div className="pagination">
                  <button
                      onClick={handleFirstPage}
                      disabled={currentPage === 1}
                    >
                      First Page
                    </button>
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                    >
                      Last Page
                    </button>
                  </div>
                  </>
                  ) : (
                    <div>
                    <p>No Records Available</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scheduled"
                role="tabpanel"
                aria-labelledby="scheduled-tab"
              >
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>Serial Number</th>
                          <th>Device Name</th>
                          <th>Device Type</th>
                          <th>Current Firmware</th>
                          <th>New Firmware</th>
                          <th>update Status</th>
                        </tr>
                        <tr className="search-border">
                          {/* <td></td> */}
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="serialNumber"
                              onKeyUp={handleScheduleSearch}
                            />
                            <i
                              className={`fa ${
                                sortScheduleOrder.serialNumber === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("serialNumber")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              onKeyUp={(e) => handleScheduleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortScheduleOrder.deviceName === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("deviceName")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceType"
                              onKeyUp={(e) => handleScheduleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortScheduleOrder.deviceType === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("deviceType")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="currentFirmware"
                              onKeyUp={(e) => handleScheduleSearch(e)}
                            />{" "}
                            <i
                              className={`fa ${
                                sortScheduleOrder.currentFirmware === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("currentFirmware")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="newFirmware"
                              onKeyUp={(e) => handleScheduleSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortScheduleOrder.newFirmware === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("newFirmware")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="updateStatus"
                              onKeyUp={(e) => handleScheduleSearch(e)}
                            />
                            <i
                              className={`fa ${
                                sortScheduleOrder.updateStatus === "asc"
                                  ? "fa fa-sort-amount-desc"
                                  : "fa fa-sort-amount-asc"
                              }`}
                              onClick={() =>
                                handleSortScheduleChange("updateStatus")
                              }
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {currentScheduleList.length > 0 ? (
                          currentScheduleList.map((list, index) => (
                            <tr key={index}>
                              <td>{list.serialNumber || "N/A"}</td>
                              <td>{list.deviceName || "N/A"}</td>
                              <td>{list.deviceType || "N/A"}</td>
                              <td>{list.currentFirmware || "N/A"}</td>
                              <td>{list.newFirmware || "N/A"}</td>
                              <td>{list.updateStatus || "N/A"}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pagination-container">
                {totalFilteredSchedules > 0 ? (
                  <>
                  <div className="pagination-info">
                   
                      <p>
                        Showing {indexOfFirstSchedule + 1}
                        {"-"}
                        {Math.min(
                          currentSchedulePage * schedulesPerPage,
                          totalFilteredSchedules
                        )}{" "}
                        of {totalFilteredSchedules} records
                      </p>
                   
                  </div>
                  <div className="pagination">
                  <button
                      onClick={handleScheduleFirstPage}
                      disabled={currentSchedulePage === 1}
                    >
                      First Page
                    </button>
                    <button
                      onClick={handleSchedulePrevPage}
                      disabled={currentSchedulePage === 1}
                    >
                      Previous
                    </button>
                    {getPage().map((page) => (
                      <button
                        key={page}
                        onClick={() => handleSchedulePageClick(page)}
                        className={currentSchedulePage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleScheduleNextPage}
                      disabled={currentSchedulePage === totalSchedulePages}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleScheduleLastPage}
                      disabled={currentSchedulePage === totalSchedulePages}
                    >
                      Last Page
                    </button>
                  </div>
                  </>
                   ) : (
                    <div>
                    <p>No Records Available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Chat />
      </div>
      {/* <div
        className={`modal fade`}
        id="successModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={successIcon} alt="Success" />
              <p>Firmware updated successfully</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => overviewDetails()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        className={`modal fade`}
        id="successAddModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={successIcon} alt="Success" />
              <p>Firmware added successfully</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => firmwareDetails()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {showFailurePopup && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <h3>Firmware Upgrade failed.</h3>
                <div className="modal-footer">
                  <button
                    className="text-btn primary-btn"
                    onClick={() => setShowFailurePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFirmwareFailurePopup && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <h3>Firmware Upload failed.</h3>
                <div className="modal-footer">
                  <button
                    className="text-btn primary-btn"
                    onClick={() => setShowFirmwareFailurePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Firmware upgraded successfully</p>
        <Button
          onClick={() => {
            setShowSuccessModal(false);
            overviewDetails();
          }}
        >
          OK
        </Button>
      </Modal>
      <Modal
        show={showScheduleSuccessModal}
        onHide={() => setShowScheduleSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Firmware upgrade scheduled successfully</p>
        <Button
          onClick={() => {
            setShowScheduleSuccessModal(false);
            overviewDetails();
          }}
        >
          OK
        </Button>
      </Modal>
      <Modal
        show={showSuccessAddModal}
        onHide={() => setShowSuccessAddModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Firmware uploaded successfully</p>
        <Button
          onClick={() => {
            setShowSuccessAddModal(false);
            firmwareDetails();
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default OTAManagement;
