import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate, useLocation } from "react-router-dom";

function OverviewDevices() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState({
    onlineCount: 0,
    offlineCount: 0,
    totalDevices: 0,
    waitingForConnectionCount: 0,
  });
  const [healthStatus, setHealthStatus] = useState("All");
  const statusMap = {
    ONLINE: "Online",
    OFFLINE: "Offline",
    AWAITING: "Awaiting",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const health = searchParams.get("health") || "All";
      setHealthStatus(health);

      const api = new allApi();
      const response = await api.getRequest("api/devices");
      if (response) {
        let filteredDevices = response;
        if (health !== "All") {
          filteredDevices = response.filter(
            (device) => device.health === health
          );
        }
        setDeviceData({
          onlineCount: filteredDevices.filter(
            (device) => device.health === "Online"
          ).length,
          offlineCount: filteredDevices.filter(
            (device) => device.health === "Offline"
          ).length,
          waitingForConnectionCount: filteredDevices.filter(
            (device) => device.health === "Awaiting"
          ).length,
          totalDevices: filteredDevices.length,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const onlinePercentage =
    (deviceData.onlineCount / deviceData.totalDevices) * 100;
  const offlinePercentage =
    (deviceData.offlineCount / deviceData.totalDevices) * 100;
  const waitingForConnectionCount =
    (deviceData.waitingForConnectionCount / deviceData.totalDevices) * 100;

  const data = [
    { name: "Online", value: deviceData.onlineCount, type: "ONLINE" },
    { name: "Offline", value: deviceData.offlineCount, type: "OFFLINE" },
    {
      name: "Awaiting",
      value: deviceData.waitingForConnectionCount,
      type: "AWAITING",
    },
  ];
  const COLORS = ["#26BF78", "#FF6B6B", "#eb8919"];

  const fetchDeviceByStatus = async (statuses) => {
    try {
      setLoading(true);
      const api = new allApi();
      const statusQueries = statuses
        .map((status) => `status=${status}`)
        .join("&");
      const response = await api.getRequest(
        `api/devices/status?${statusQueries}`
      );
      if (response) {
        const deviceStatus = response;
        navigate("/device-listing", { state: { deviceStatus } });
      } else {
        console.error("Invalid data format", response);
      }
    } catch (error) {
      console.error("Error fetching device", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDevice = (status) => {
    switch (status) {
      case "ONLINE":
        return "Online";
      case "OFFLINE":
        return "Offline";
      case "AWAITING":
        return "Awaiting";
      default:
        return status;
    }
  };

  const handleHealthStatusClick = (statusType) => {
    fetchDeviceByStatus([statusType]);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="deviceCountChart">
          <div className="device-chart">
            <div>
              {" "}
              {data && data.length > 0 ? (
                <div className="c-chart">
                  <PieChart width={200} height={243}>
                    <Pie
                      data={data}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="45%"
                      innerRadius={53}
                      outerRadius={70}
                      fill="#8884d8"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
              ) : (
                <div>No data to display</div>
              )}
            </div>
            <div className="c-legend">
              <ul>
                {data.map((entry, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleHealthStatusClick(entry.type);
                    }}
                  >
                    <span
                      className="legend-color"
                      style={{
                        backgroundColor: COLORS[index % COLORS.length],
                      }}
                    ></span>
                    {entry.name} : <strong>{entry.value}</strong>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OverviewDevices;
