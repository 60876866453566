import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const StackedHorizontalChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis type="number" hide />
        <YAxis dataKey="time" type="category" />
        <Tooltip />
        <Legend />
        <Bar dataKey="online" stackId="a" fill="#00D76F" />
        <Bar dataKey="offline" stackId="a" fill="#fc3858" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedHorizontalChart;
