import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VlanNetworkSetup from "./vlanNetworkSetup";
import PortConfiguration from "./portConfiguration";
import DowntimeSchedules from "./downtimeSchedules";
import RadiusConfiguration from "./radiusConfiguration";
import CableTest from "./cableTest";
import ShareDiagnosticInformation from "./shareDiagnosticInformation";
import ResetCounters from "./resetCounters";
import IPSettings from "./ipSettings";
import Chat from "../../common/chat";

function WiredConfiguration() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("vlanSetup");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vlans, setVlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scheduleModal, setScheduleModal] = useState(false);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabTitles = {
    vlanSetup: "VLAN/Network Setup",
    portConfig: "Group Port Configuration",
    poeDowntime: "PoE Downtime Schedules",
    radiusConfig: "RADIUS Configuration",
    cableTest: "Cable Test",
    //diagnoInfo: "Share Diagnostic Information",
    resetCounters: "Reset Counters",
    ipSettings: "IP Settings",
  };

  const currentTitle = tabTitles[activeTab] || "VLAN/Network Setup";

  const openScheduleModal = () => {
    if (activeTab === "poeDowntime") {
      setScheduleModal(true);
    }
  };

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>{currentTitle}</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              onClick={() => {
                navigate("/wired");
              }}
            >
              Quick View
            </button>
            {activeTab === "vlanSetup" && (
              <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                title="Add"
                onClick={() => {
                  navigate("/ConfigureNetwork");
                }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i> Create Vlan
              </button>
              </div>
            )}
            {activeTab === "poeDowntime" && (
              <button
                className="icon-btn"
                title="Add"
                onClick={openScheduleModal}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
        <div className="inc-card-body">
          <div className="sideTab-container wired-sideTab">
            <div className="inc-side-tab">
              <ul className="nav-side" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "vlanSetup" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("vlanSetup")}
                    role="tab"
                    aria-controls="pills-vlan"
                    aria-selected={activeTab === "vlanSetup"}
                  >
                    VLAN/Network Setup{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "portConfig" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("portConfig")}
                    role="tab"
                    aria-controls="pills-portconfig"
                    aria-selected={activeTab === "portConfig"}
                  >
                    Group Port Configuration{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li> */}
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "poeDowntime" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("poeDowntime")}
                    role="tab"
                    aria-controls="pills-poeDowntime"
                    aria-selected={activeTab === "poeDowntime"}
                  >
                    PoE Downtime Schedules{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "ipSettings" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("ipSettings")}
                    role="tab"
                    aria-controls="pills-ipSettings"
                    aria-selected={activeTab === "ipSettings"}
                  >
                    IP Settings{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "cableTest" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("cableTest")}
                    role="tab"
                    aria-controls="pills-cableTest"
                    aria-selected={activeTab === "cableTest"}
                  >
                    Cable Test{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "radiusConfig" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("radiusConfig")}
                    role="tab"
                    aria-controls="pills-radiusConfig"
                    aria-selected={activeTab === "radiusConfig"}
                  >
                    Radius Configuration{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "diagnoInfo" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("diagnoInfo")}
                    role="tab"
                    aria-controls="pills-diagnoInfo"
                    aria-selected={activeTab === "diagnoInfo"}
                  >
                    Diagnostic Mode{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li> */}
                {/* <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "resetCounters" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("resetCounters")}
                    role="tab"
                    aria-controls="pills-resetCounters"
                    aria-selected={activeTab === "resetCounters"}
                  >
                    Reset Counters{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li> */}
              </ul>
            </div>
            <div className="inc-side-tabcontainer">
              <div className="tab-content" id="pills-tabContent">
                {activeTab === "vlanSetup" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-vlan"
                    role="tabpanel"
                    aria-labelledby="pills-vlan-tab"
                  >
                    {/* =============VLAN/Network Setup================ */}

                    <VlanNetworkSetup />
                  </div>
                )}
                {activeTab === "portConfig" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-portconfig"
                    role="tabpanel"
                    aria-labelledby="pills-portconfig-tab"
                  >
                    {/* =============Group Port Configuration================ */}
                    <PortConfiguration />
                  </div>
                )}
                {activeTab === "poeDowntime" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-poeDowntime"
                    role="tabpanel"
                    aria-labelledby="pills-poeDowntime-tab"
                  >
                    {/* =============PoE Downtime Schedules================ */}

                    <DowntimeSchedules
                      scheduleModal={scheduleModal}
                      setScheduleModal={setScheduleModal}
                    />
                  </div>
                )}
                {activeTab === "cableTest" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-cableTest"
                    role="tabpanel"
                    aria-labelledby="pills-cableTest-tab"
                  >
                    {/* =============Cable Test================ */}

                    <CableTest />
                  </div>
                )}
                {activeTab === "radiusConfig" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-radiusConfig"
                    role="tabpanel"
                    aria-labelledby="pills-radiusConfig-tab"
                  >
                    {/* =============RADIUS Configuration================ */}

                    <RadiusConfiguration />
                  </div>
                )}
                {/* {activeTab === "diagnoInfo" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-diagnoInfo"
                    role="tabpanel"
                    aria-labelledby="pills-diagnoInfo-tab"
                  >
                    { =============Share Diagnostic Information================ }

                    <ShareDiagnosticInformation />
                  </div>
                )} */}
                {activeTab === "resetCounters" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-resetCounters"
                    role="tabpanel"
                    aria-labelledby="pills-resetCounters-tab"
                  >
                    {/* =============Reset Counters================ */}

                    <ResetCounters />
                  </div>
                )}
                {activeTab === "ipSettings" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ipSettings"
                    role="tabpanel"
                    aria-labelledby="pills-ipSettings-tab"
                  >
                    {/* =============IP Settings================ */}

                    <IPSettings />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chat/>
    </div>
  );
}

export default WiredConfiguration;
