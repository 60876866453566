import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate, useLocation } from "react-router-dom";
import cpuIcon from "../../../../assets/images/cpu.png";
import memoryIcon from "../../../../assets/images/memory.png";
import diskIcon from "../../../../assets/images/disk.png";
// import dbIcon from "../../../assets/images/db.png";
import thumUp from "../../../../assets/images/thumb-up.png";
function OverviewSystemHealth() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [containerLoading, setContainerLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [selectedContainerKey, setSelectedContainerKey] = useState("");
  const [selectedContainerData, setSelectedContainerData] = useState(null);
  const [selectedContainerId, setSelectedContainerId] = useState("");
  const [containerData, setContainerData] = useState(null);
  const [containerList, setContainerList] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [license, setLicense] = useState("");

  useEffect(() => {
    updatePieChartData();
    fetchLicense();
  }, [selectedContainerId]);

  const fetchLicense = () => {
    const storedLicense = localStorage.getItem("license");
    if (storedLicense) {
      setLicense(storedLicense);
    }
  };

  useEffect(() => {
    const fetchContainerList = async () => {
      try {
        const response = await fetch("snmp/container_list/");
        const data = await response.json();
        const formattedData = data.data.map((container) => ({
          ...container,
          container_name: formatContainerName(container.container_name),
        }));
        setContainerList(formattedData);
        if (formattedData.length > 0) {
          setSelectedContainerId(formattedData[0].container_id);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching container list:", error);
        setLoading(false);
      }
    };

    fetchContainerList();
  }, []);

  useEffect(() => {
    const fetchContainerData = async () => {
      if (selectedContainerId) {
        setContainerLoading(true);
        try {
          const response = await fetch(
            `snmp/container_info/?container_id=${selectedContainerId}`
          );
          const data = await response.json();
          const containerInfo = Object.values(data.data)[0];
          setContainerData(containerInfo);
          updatePieChartData(containerInfo);
          setContainerLoading(false);
        } catch (error) {
          console.error("Error fetching container data:", error);
        }
      }
    };

    fetchContainerData();
  }, [selectedContainerId]);

  const handleDropdownChange = (event) => {
    setSelectedContainerId(event.target.value);
  };

  const formatContainerName = (name) => {
    return name
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const updatePieChartData = (container) => {
    // Calculate average usage
    const totalUsage =
      container?.cpu_usage +
      container?.memory_usage +
      container?.storage_usge +
      container?.db_connection;
    const averageUsage = totalUsage / 4;

    const pieData = [
      { name: "Everything looks good", value: averageUsage < 80 ? 100 : 0 },
      { name: "Attention required", value: averageUsage >= 80 ? 100 : 0 },
    ];

    // Normalize values so they sum to 100
    const total = pieData.reduce((sum, entry) => sum + entry.value, 0);
    const normalizedPieData = pieData.map((entry) => ({
      ...entry,
      value: (entry.value / total) * 100,
    }));

    setPieChartData(normalizedPieData);
  };

  return (
    <div>
      <div className="inc-card-title">
        <h3>System Health</h3>
        {license !== "Business" && (
          <div className="grafna-link">
            <a
              style={{ cursor: "pointer", color: "#2a72e0" }}
              onClick={() => navigate("/system-info")}
            >
              More Info
            </a>

            {showPopup && (
              <div className="popup">
                <p>
                  <strong>GRAFANA DASHBOARD</strong>
                </p>
                <p>Credentials:</p>
                <p>Username: incx</p>
                <p>Password: incx@123</p>
              </div>
            )}
          </div>
        )}
      </div>
      {containerLoading ? (
        <Loader />
      ) : (
        <div className="inc-card-body">
          <select
            className="form-select"
            aria-label="Default select example"
            value={selectedContainerId}
            onChange={handleDropdownChange}
          >
            {containerList.map((container) => (
              <option
                key={container.container_id}
                value={container.container_id}
              >
                {container.container_name}
              </option>
            ))}
          </select>

          <div className="dr-status">
            <div>
              <strong>DR Status</strong>
              <span>Everything Looks Great!</span>
            </div>
            <div>
              <img src={thumUp} alt="" />
            </div>
          </div>
          {containerData && (
            <div className="mb-4 mt-3">
              <div className="inc-sys-health">
                <div>
                  <img src={cpuIcon} alt="CPU Icon" />
                </div>
                <div>
                  <div className="sys-info">
                    <span>CPU Usage</span>
                    <span>{containerData.cpu_usage.toFixed(2)}%</span>
                  </div>
                  <div className="inc-progress cpu">
                    <span
                      style={{ width: `${containerData.cpu_usage}%` }}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="inc-sys-health">
                <div>
                  <img src={memoryIcon} alt="Memory Icon" />
                </div>
                <div>
                  <div className="sys-info">
                    <span>Memory Usage</span>
                    <span>{containerData.memory_usage.toFixed(2)}%</span>
                  </div>
                  <div className="inc-progress memory">
                    <span
                      style={{
                        width: `${containerData.memory_usage}%`,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="inc-sys-health">
                <div>
                  <img src={diskIcon} alt="Disk Icon" />
                </div>
                <div>
                  <div className="sys-info">
                    <span>Disk Utilization</span>
                    <span>
                      {!isNaN(containerData.storage_usge)
                        ? `${containerData.storage_usge.toFixed(2)}%`
                        : "N/A"}
                    </span>
                  </div>
                  <div className="inc-progress disk">
                    <span
                      style={{
                        width: `${containerData.storage_usge}%`,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              {/* <div className="inc-sys-health">
                    <div>
                      <img src={dbIcon} alt="DB Icon" />
                    </div>
                    <div>
                      <div className="sys-info">
                        <span>DB Connection</span>
                        <span>{containerData.db_connection}</span>
                      </div>
                      <div className="inc-progress db">
                        <span
                          style={{ width: `${containerData.db_connection}%` }}
                        ></span>
                      </div>
                    </div>
                  </div> */}
            </div>
          )}
          <div className="node-sec">
            <div className="node-chart">
              <strong>Container Status</strong>
            </div>
            <div className="deviceCountChart health-chart">
              <ResponsiveContainer width="100%" height={200}>
                <PieChart width={200} height={200}>
                  <Pie
                    data={pieChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="40%"
                    innerRadius={55}
                    outerRadius={70}
                    fill="#8884d8"
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.name === "Everything looks good"
                            ? "#26BF78"
                            : "#FF6B6B"
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="node-legends">
              <ul>
                <li>
                  <span
                    className="d1"
                    style={{ backgroundColor: "#26BF78" }}
                  ></span>
                  Everything looks good
                </li>
                <li>
                  <span
                    className="d2"
                    style={{ backgroundColor: "#FF0000" }}
                  ></span>
                  Attention required
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OverviewSystemHealth;
